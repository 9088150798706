import React, { FC } from 'react';
import { CoachDrawerProps } from './coach-drawer.types';
import { Details } from '../details';
import { CreateCoachForm } from '../create-form';
import { EditCoachForm } from '../edit-form';

export const CoachDrawer: FC<CoachDrawerProps> = props => {
  const { coach, onEdit, onHideDrawer, onRefresh, type } = props;

  if (type === 'create') {
    return (
      <CreateCoachForm onHideDrawer={onHideDrawer} onRefresh={onRefresh} />
    );
  }

  if (type === 'edit') {
    return (
      <EditCoachForm
        coach={coach}
        onHideDrawer={onHideDrawer}
        onRefresh={onRefresh}
      />
    );
  }

  if (type === 'details') {
    return (
      <Details
        coach={coach}
        onEdit={onEdit}
        onHideDrawer={onHideDrawer}
        onRefresh={onRefresh}
      />
    );
  }
};
