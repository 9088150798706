import axiosInstance from '../../../utils/helpers/http';

export const coachSignIn = async () => {
  try {
    return await axiosInstance.get(
      `${process.env.REACT_APP_TRULA_API_BASE_URL}/coaches`,
    );
  } catch (err: any) {
    return err;
  }
};

export const coacheeSignIn = async () => {
  try {
    return await axiosInstance.get(
      `${process.env.REACT_APP_TRULA_API_BASE_URL}/students`,
    );
  } catch (err: any) {
    return err;
  }
};

export const adminSignIn = async () => {
  try {
    return await axiosInstance.get(
      `${process.env.REACT_APP_TRULA_API_BASE_URL}/admin`,
    );
  } catch (err: any) {
    return err;
  }
};
