import React, { FC, useRef, useState } from 'react';
import {
  Conditional,
  Flex,
  ImageUpload,
  Panel,
  Typography,
} from '../../../../../../common';
import { EditFormProps } from './edit-form.types';
import Tabs from '../../../../../general/components/tabs/tabs';
import { CoachSessions } from '../coach-sessions';
import { CoachCapacity } from '../coach-capacity';
import { EditCoachFormContent } from './edit-coach-form-content';

export const EditCoachForm: FC<EditFormProps> = props => {
  const { coach, onHideDrawer, onRefresh } = props;
  const [activeTab, setActiveTab] = useState('Details');
  const fileRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');

  return (
    <Panel
      Header={
        <React.Fragment>
          <Flex alignItems="center" gap="3x" marginBottom="4x">
            <div style={{ cursor: 'pointer' }} onClick={fileRef.current?.click}>
              <ImageUpload
                imageUrl={coach?.profile_img}
                onImageUpload={setImageUrl}
              />
            </div>

            <div>
              <Typography text={coach?.name} variant="title3" />
              <Typography
                marginTop=".5x"
                text="Active Coach"
                variant="caption"
              />
            </div>
          </Flex>

          <Tabs
            active={activeTab}
            tab1="Details"
            tab2="Sessions"
            tab3="Capacity"
            onTabChange={setActiveTab}
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Conditional condition={activeTab === 'Details'}>
        <EditCoachFormContent
          coach={coach}
          imageUrl={imageUrl}
          onHideDrawer={onHideDrawer}
          onRefresh={onRefresh}
        />
      </Conditional>

      <Conditional condition={activeTab === 'Sessions'}>
        <CoachSessions coach={coach} />
      </Conditional>

      <Conditional condition={activeTab === 'Capacity'}>
        <CoachCapacity
          coach={coach}
          onHideDrawer={onHideDrawer}
          onRefresh={onRefresh}
        />
      </Conditional>
    </Panel>
  );
};
