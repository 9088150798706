import { TypographyProps } from './typography.types';

export const variantMappings: Record<TypographyProps['variant'], string> = {
  body1: 'p',
  body2: 'p',
  button: 'span',
  caption: 'p',
  link: 'a',
  subtitle1: 'h2',
  subtitle2: 'h3',
  subtitle3: 'h4',
  title: 'h1',
  title2: 'h1',
  title3: 'h1',
};
