import axiosInstance from '../../../utils/helpers/http';
import { CreateSession } from '../interfaces/requests/CreateSession';

export const createSession = async (createSessionPayload: CreateSession) => {
  return axiosInstance.post(`session-timeslots`, {
    available_timeslot_id: createSessionPayload.timeslotId,
    coach_id: createSessionPayload.coachId,
    number_of_sessions: createSessionPayload.numberOfSessions,
    student_id: createSessionPayload.studentId,
  });
};
