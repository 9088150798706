import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GeneralState {
  overlay: boolean;
}

/**
 * Default state object with initial values.
 */
const initialState: GeneralState = {
  overlay: false,
};

export const generalSlice = createSlice({
  initialState,
  name: "general",
  reducers: {
    setOverlay(state, action: PayloadAction<boolean>) {
      state.overlay = action.payload;
    },
  },
});

// Exports all actions
export const { setOverlay } = generalSlice.actions;

export default generalSlice.reducer;
