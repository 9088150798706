import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgArrowUpload = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 16.667c-.459 0-.85-.163-1.177-.489a1.606 1.606 0 0 1-.49-1.177v-2.5H5.5V15h10v-2.5h1.666V15c0 .458-.163.85-.489 1.177-.326.326-.719.49-1.177.49h-10Zm4.166-3.333V6.542L7.5 8.71 6.333 7.501 10.5 3.334l4.166 4.167L13.5 8.709l-2.167-2.167v6.792H9.666Z"
        fill="#21303B"
      />
    </svg>
  );
};
export default SvgArrowUpload;
