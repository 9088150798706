import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import Avatar from '../../../general/components/avatar/avatar';
import { Conditional, Typography } from '../../../../common';
import { add30Minutes, formatDate } from '../../../../utils/helpers/date';

import styles from './user-info.module.scss';
import generalStyles from '../../../coach-matching/components/general/general.module.scss';

type Props = {
  cssClass?: string;
  name: string;
  timeslot?: number;
  userExternal?: any;
};

const UserInfo = ({ cssClass, name, timeslot, userExternal }: Props) => {
  const user = localStorageUser();

  return (
    <div className={`${styles.container} ${cssClass ? styles[cssClass] : ''}`}>
      {userExternal && (
        <Avatar
          image={userExternal ? userExternal.profile_img : undefined}
          name={userExternal.name}
          size="medium"
        />
      )}
      <div className={`${generalStyles.nameXl} ${styles.name}`}>{name}</div>

      <Conditional condition={!!timeslot}>
        <div className={styles.sessionTime}>
          <p>
            <Conditional condition={!!user}>
              <Typography text={formatDate(new Date(+timeslot), 'hh:mm a')} />
            </Conditional>
          </p>

          <p className={styles.timeSeparator}>to</p>

          <p>{formatDate(new Date(add30Minutes(+timeslot)), 'hh:mm a')}</p>
        </div>

        <div
          className={`${styles.sessionTime} ${generalStyles.marginTopMinus8}`}
        >
          <Conditional condition={!!user}>
            <Typography text={formatDate(new Date(+timeslot), 'dddd')} />
          </Conditional>
        </div>

        <div
          className={`${generalStyles.plain} ${generalStyles.bold} ${generalStyles.marginTopMinus8}`}
        >
          <Conditional condition={!!user}>
            <Typography
              text={formatDate(new Date(+timeslot), 'MMM DD, YYYY')}
            />
          </Conditional>
        </div>
      </Conditional>
    </div>
  );
};

export default UserInfo;
