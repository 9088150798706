import { Link } from 'react-router-dom';
import EmptyListing from '../../../../general/components/empty-listing/empty-listing';
import Loading from '../../../../general/components/loading/loading';
import { Student } from '../../../../register-coachee/interfaces/Student';
import StudentCard from '../../StudentCard/StudentCard';
import emptyStudents from '../../../../../assets/icons/EmptyListing/emptyStudents.svg';

import styles from './Students.module.scss';

type Props = {
  archived?: boolean;
  isConversationListing?: boolean;
  students: Student[] | undefined;
};

const Students = ({ archived, isConversationListing, students }: Props) => {
  return students ? (
    <div className={styles.pageContainer}>
      {students.length > 0 ? (
        students.map((student: Student, index: number) => {
          return (
            <div className={styles.container} key={index}>
              <Link
                to={
                  !isConversationListing
                    ? `/coach/students/${student.id}`
                    : archived
                    ? `/coach/students/${student.id}/conversations/archived`
                    : `/coach/students/${student.id}/conversations`
                }
              >
                <StudentCard
                  isConversationList={isConversationListing}
                  key={student.name + student.surname}
                  student={student}
                  onSelect={() => {}}
                />
              </Link>
            </div>
          );
        })
      ) : (
        <EmptyListing
          image={emptyStudents}
          subtitle="Students you match with will appear here"
          title="No matched students yet"
        />
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default Students;
