import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgThreeDots = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 20Zm0-6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 14Zm0-6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 8Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgThreeDots;
