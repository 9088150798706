import React, { FC, PropsWithChildren } from 'react';
import { PanelProps } from './panel.types';
import { Icon } from '../icon';
import { Conditional } from '../conditional';

import styles from './panel.module.scss';

export const Panel: FC<PropsWithChildren<PanelProps>> = props => {
  const { Footer, Header, children, onClose } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>{Header}</div>

        <Icon
          className={styles.closeIcon}
          cursor="pointer"
          height="24px"
          name="actions:x"
          variant="button"
          width="24px"
          onClick={onClose}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.content}>{children}</div>
      </div>

      <Conditional condition={!!Footer}>
        <div className={styles.footer}>{Footer}</div>
      </Conditional>
    </div>
  );
};
