import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// import { useNavigate } from "react-router-dom";
import Video, {
  isSupported,
  LocalTrackPublication,
  Participant,
  Room,
} from 'twilio-video';
import ParticipantComponent from './ParticipantComponent';
import leave from '../../../../assets/icons/common/leave.svg';
import mic_mute from '../../../../assets/icons/common/mic_mute.svg';
import mic_unmute from '../../../../assets/icons/common/mic_unmute.svg';
import video_crossed from '../../../../assets/icons/common/video_crossed.svg';
import video_active from '../../../../assets/icons/common/video_active.svg';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';

import styles from './RoomComponent.module.scss';

type Props = {
  handleEndSession: () => void;
  roomName: string;
  token: string;
};

const RoomComponent = ({ handleEndSession, roomName, token }: Props) => {
  /** Next line is causing side effects not allowing audio/video media tracks
   to be stopped and to be unpublished on end session * */
  // const navigate = useNavigate();  <-- this line
  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [roomJoinedSuccessfully, setRoomJoinedSuccessfully] = useState(false);
  const [localMedia, setLocalMedia] = useState({
    addLocalAudio: true,
    addLocalVideo: true,
  });
  const user = localStorageUser();
  const cognitoGroup = localStorage.getItem('cognitoGroup');

  useEffect(() => {
    const participantConnected = (participant: Participant) => {
      setParticipants(prevParticipants => {
        return [...prevParticipants, participant];
      });
    };
    const participantDisconnected = (participant: Participant) => {
      setParticipants(prevParticipants => {
        return prevParticipants.filter(p => {
          return p !== participant;
        });
      });
    };
    if (isSupported) {
      Video.connect(token, {
        audio: true,
        maxAudioBitrate: 16000,
        name: roomName,
        video: { frameRate: 24, height: 640, width: 480 },
      }).then(room => {
        setRoom(room);
        setRoomJoinedSuccessfully(true);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);
        room.on('disconnected', room => {
          detachParticipantTracks(room.localParticipant);
        });
        notifyLocalUserWhenParticipantEntersRoom(room);

        /* Listen to the "beforeunload" event on window to leave the Room
          when the tab/browser is being closed. */
        window.addEventListener('beforeunload', () => {
          return room.disconnect();
        });

        /* iOS Safari does not emit the "beforeunload" event on window.
          Use "pagehide" instead. */
        window.addEventListener('pagehide', () => {
          return room.disconnect();
        });

        //* Ends video session on back via browser button
        window.addEventListener('popstate', event => {
          event.preventDefault();
          if (!!cognitoGroup && cognitoGroup === 'coachee') {
            window.location.replace(`/coachee/dashboard`);
          } else {
            window.location.replace(`/coach/dashboard`);
          }
        });

        return () => {
          room.off('participantConnected', participantConnected);
          room.off('participantDisconnected', participantDisconnected);
        };
      });

      return () => {
        setRoom(currentRoom => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === 'connected'
          ) {
            currentRoom.localParticipant.tracks?.forEach(
              (trackPublication: LocalTrackPublication) => {
                if (trackPublication.track.kind !== 'data') {
                  trackPublication.track.stop();
                  trackPublication.track.detach();
                }
              },
            );
            currentRoom.disconnect();
            return null;
          }
          return currentRoom;
        });
      };
    }
  }, [roomName]);

  const notifyLocalUserWhenParticipantEntersRoom = (room: Room) => {
    room.once('participantConnected', participant => {
      toast.success(`${participant.identity} has joined to session!`);
    });
  };

  const detachParticipantTracks = (participant: Participant) => {
    participant.tracks.forEach((trackPublication: any) => {
      if (trackPublication.track.kind !== 'data') {
        trackPublication.track.stop();
        const detachedElement = trackPublication.track.detach();
        detachedElement.forEach((el: HTMLElement) => {
          el.remove();
        });
      }
    });
  };

  const removeLocalParticipantVideo = () => {
    room?.localParticipant.videoTracks.forEach(publication => {
      publication.track.disable();

      publication.track.on('disabled', () => {
        // do something with local ui here
      });
    });
    setLocalMedia(prevState => {
      return { ...prevState, addLocalVideo: false };
    });
  };

  const addLocalParticiantVideo = () => {
    room?.localParticipant.videoTracks.forEach(publication => {
      publication.track.enable();

      publication.track.on('enabled', () => {
        // do something with local ui here
      });
    });
    setLocalMedia(prevState => {
      return { ...prevState, addLocalVideo: true };
    });
  };

  const removeLocalParticipantAudio = () => {
    room?.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });

    setLocalMedia(prevState => {
      return { ...prevState, addLocalAudio: false };
    });
  };

  const addLocalParticipantAudio = () => {
    room?.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable();
    });
    setLocalMedia(prevState => {
      return { ...prevState, addLocalAudio: true };
    });
  };

  //* Render controls
  const toggleLocalVideo = localMedia.addLocalVideo ? (
    <div
      className={styles.iconContainer}
      onClick={() => {
        return removeLocalParticipantVideo();
      }}
    >
      <img
        alt="Video On"
        className={`${styles.videoIcon}`}
        src={video_active}
      />
    </div>
  ) : (
    <div
      className={styles.iconContainer}
      onClick={() => {
        return addLocalParticiantVideo();
      }}
    >
      <img
        alt="Video Off"
        className={`${styles.videoIcon}`}
        src={video_crossed}
      />
    </div>
  );

  const toggleLocalAudio = localMedia.addLocalAudio ? (
    <div className={styles.iconContainer} onClick={removeLocalParticipantAudio}>
      <img alt="Unmute" className={`${styles.videoIcon}`} src={mic_unmute} />
    </div>
  ) : (
    <div className={styles.iconContainer} onClick={addLocalParticipantAudio}>
      <img alt="Mute" className={`${styles.videoIcon}`} src={mic_mute} />
    </div>
  );

  const controls = (
    <Fragment>
      <div className={styles.controlsContainer}>
        <div className={styles.info}>
          <p className={styles.identity}>{`${!!user && user.name} ${
            !!user && user.surname
          }`}</p>
        </div>
        <div className={styles.controls}>
          <div className={styles.leftControls}>
            {toggleLocalAudio}
            {toggleLocalVideo}
          </div>
          <div className={styles.rightControls}>
            <div className={styles.iconContainer}>
              <img
                alt="Leave room"
                className={`${styles.videoIcon}`}
                src={leave}
                onClick={handleEndSession}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const remoteParticipants = participants.map(
    (participant: Participant, index) => {
      return <ParticipantComponent key={index} participant={participant} />;
    },
  );

  return (
    <Fragment>
      <div className={styles.room}>
        <div className={styles.localMedia}>
          {room && roomJoinedSuccessfully ? (
            <Fragment>
              <ParticipantComponent
                key={room.localParticipant.sid}
                participant={room.localParticipant}
              />
            </Fragment>
          ) : (
            ''
          )}
        </div>
        {room?.participants.size !== 0 ? (
          <div className={styles.remoteMedia}>{remoteParticipants}</div>
        ) : (
          <div className={styles.waitingNote}>
            Please wait for other participant to join...
          </div>
        )}
        {controls}
      </div>
    </Fragment>
  );
};

export default RoomComponent;
