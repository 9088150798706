import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgSms = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.937 14.645H17a2.88 2.88 0 0 0 2.876-2.876V3.155a.96.96 0 0 0 0-.034v-.244A2.88 2.88 0 0 0 17 0H2.878A2.88 2.88 0 0 0 0 2.877v8.892a2.88 2.88 0 0 0 2.877 2.876h7.953V16.8a.851.851 0 0 0 1.365.679l3.742-2.833ZM1.57 4.49v7.279c0 .72.586 1.307 1.307 1.307h9.017c.117 0 .213.095.213.213V15.8l3.276-2.467c.221-.167.49-.257.768-.257H17a1.31 1.31 0 0 0 1.307-1.307V4.49l-7.46 4.263a1.839 1.839 0 0 1-1.816.001L1.57 4.49Zm.013-1.8 8.226 4.7a.26.26 0 0 0 .259 0l8.226-4.7A1.31 1.31 0 0 0 17 1.57H2.877a1.31 1.31 0 0 0-1.294 1.12Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgSms;
