import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../register-coachee/components/ui/Button/Button';
import SelectableCard from '../../../register-coachee/components/ui/SelectableCard/SelectableCard';
import { exportData } from '../../utils/constants';
import { generateReport } from '../../api/reports';
import { Flex, Typography } from '../../../../common';

import styles from './export.module.scss';

const Export = () => {
  const [exportSelection, setExportSelection] = useState<string | null>(null);

  // Handle a selection of a card
  const handleSelect = (value: string, isSelected: boolean) => {
    if (value === exportSelection && !isSelected) {
      setExportSelection(null);
    }
    if (isSelected && exportSelection !== value) {
      setExportSelection(value);
    } else if (exportSelection === value && isSelected) {
      setExportSelection(value);
    }
  };

  const handleExport = async () => {
    try {
      await generateReport(exportSelection as string);
      toast.success(`Downloaded ${exportSelection}`);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };
  return (
    <Flex
      alignItems="baseline"
      className={styles.exportContent}
      direction="column"
    >
      <Typography
        align="left"
        paddingBottom="2x"
        paddingTop="3x"
        text="Choose what to export"
        variant="subtitle1"
      />

      {exportData.map(option => {
        return (
          <SelectableCard
            key={option.value}
            label={option.label}
            selected={option.value === exportSelection}
            value={option.value}
            onSelect={handleSelect}
          />
        );
      })}
      <div className={`${styles.actionButton} ${styles.actionButton}`}>
        <Button
          isDisabled={exportSelection === null}
          label="Export to CSV"
          onClickHandler={() => {
            handleExport();
          }}
        />
      </div>
    </Flex>
  );
};

export default Export;
