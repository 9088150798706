import AuthCode from 'react-auth-code-input';
import { useAppDispatch } from '../../../../store/hooks';
import { setIsButtonDisabled } from '../../../register-coachee/store/registerStudentSlice';
import { Flex } from '../../../../common';

import styles from './VerifyPhoneNumber.module.scss';

type Props = {
  onResendConfirmationCode: () => void;
  onVerifyPhoneNumber: (code: string) => void;
};

const VerifyPhoneNumber = ({
  onResendConfirmationCode,
  onVerifyPhoneNumber,
}: Props) => {
  const dispatch = useAppDispatch();
  const handleOnChange = (code: string) => {
    if (code.length !== 6) {
      dispatch(setIsButtonDisabled(true));
    } else {
      dispatch(setIsButtonDisabled(false));
    }
    onVerifyPhoneNumber(code);
  };

  const handleOnClick = () => {
    onResendConfirmationCode();
  };

  return (
    <div className={styles.container} data-testid="verify-phone">
      <Flex className={styles.verifyContainer} direction="column" width="100%">
        <AuthCode
          allowedCharacters="numeric"
          autoFocus
          inputClassName={styles.codeInput}
          onChange={handleOnChange}
        />

        <button className={styles.button} onClick={handleOnClick}>
          Resend code
        </button>
      </Flex>
    </div>
  );
};

export default VerifyPhoneNumber;
