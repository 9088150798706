import moment from 'moment';
import axiosInstance from '../../../utils/helpers/http';

export const generateReport = async (type: string) => {
  try {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `${type} - ${moment().format('MM-DD-YY')}`;
    return await axiosInstance
      .get(`${process.env.REACT_APP_TRULA_API_BASE_URL}/reports/${type}`, {
        responseType: 'blob',
      })
      .then(res => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv;charset=utf-8' }),
        );
        link.click();
      });
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
