import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgCog = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.25 22-.4-3.2a3.771 3.771 0 0 1-.612-.3 8.194 8.194 0 0 1-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95a2.39 2.39 0 0 1-.025-.338v-.675c0-.108.009-.22.025-.337L1.95 9.375l2.75-4.75 2.975 1.25c.184-.133.375-.258.575-.375.2-.117.4-.217.6-.3l.4-3.2h5.5l.4 3.2c.217.083.421.183.613.3.191.117.379.242.562.375l2.975-1.25 2.75 4.75-2.575 1.95c.017.117.025.229.025.337v.675c0 .109-.017.221-.05.338l2.575 1.95-2.75 4.75-2.95-1.25a6.826 6.826 0 0 1-.575.375c-.2.117-.4.217-.6.3l-.4 3.2h-5.5Zm2.8-6.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 15.55 12c0-.967-.341-1.792-1.025-2.475A3.372 3.372 0 0 0 12.05 8.5c-.983 0-1.813.342-2.488 1.025A3.394 3.394 0 0 0 8.55 12c0 .967.338 1.792 1.012 2.475.676.683 1.505 1.025 2.488 1.025Zm0-2c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 10.55 12c0-.417.146-.77.438-1.062a1.444 1.444 0 0 1 1.062-.438c.417 0 .771.146 1.063.438.292.291.437.645.437 1.062 0 .417-.146.77-.437 1.062a1.447 1.447 0 0 1-1.063.438ZM11 20h1.975l.35-2.65a5.6 5.6 0 0 0 1.438-.588c.441-.258.845-.57 1.212-.937l2.475 1.025.975-1.7-2.15-1.625c.084-.233.142-.48.175-.738a6.142 6.142 0 0 0 0-1.575 3.529 3.529 0 0 0-.175-.737l2.15-1.625-.975-1.7-2.475 1.05a5.573 5.573 0 0 0-1.212-.963 5.624 5.624 0 0 0-1.438-.587L13 4h-1.975l-.35 2.65a5.607 5.607 0 0 0-1.437.587 5.984 5.984 0 0 0-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6c-.083.25-.141.5-.175.75a6.06 6.06 0 0 0-.05.8c0 .267.017.525.05.775.034.25.092.5.175.75l-2.15 1.625.975 1.7 2.475-1.05a5.537 5.537 0 0 0 2.65 1.55L11 20Z"
        fill={color}
        fillOpacity={0.6}
      />
    </svg>
  );
};
export default SvgCog;
