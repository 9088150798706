export const topics = {
  building_growth_and_confidence: 'Building growth and confidence',
  building_relationships: 'Building relationships',
  im_not_sure: "I'm not sure",
  improving_motivation: 'Improving motivation',
  improving_well_being: 'Improving well-being',
  managing_stress: 'Managing stress',
  navigation_life_events: 'Navigating life events',
  productivity_and_time_management: 'Productivity and time management',
} as const;

export const interests = {
  adventure: 'Adventure',
  arts: 'Arts',
  books: 'Books',
  education: 'Education',
  entertainment: 'Entertainment',
  fitness: 'Fitness',
  gaming: 'Gaming',
  health: 'Health',
  outdoors: 'Outdoors',
  podcasts: 'Podcasts',
  sport: 'Sport',
  stem: 'STEM',
} as const;

export const preferences = {
  LGBTQ_friendly: 'LGBTQIA+ friendly',
  female: 'Female',
  first_generation_student: 'First Generation student',
  graduate_student: 'Graduate student',
  international_student: 'International student',
  male: 'Male',
  military_veteran: 'Military/Veteran',
  non_traditional_student: 'Non-Traditional student',
  not_from_my_campus: 'Not from my campus',
  religious: 'Religious',
} as const;

export const preferredTime = {
  afternoon: 'Afternoon',
  evening: 'Evening',
  morning: 'Morning',
} as const;

export const preferredCommunication = {
  phone_call: 'Phone Call',
  sms: 'SMS',
  video_call: 'Video Call',
} as const;

export const coachingLanguages = {
  english: 'English',
  spanish: 'Spanish',
} as const;

export const studentStatusNameMapping = {
  active: 'Active',
  active_not_verified: 'Not verified',
  archived: 'Archived',
  blocked: 'Blocked',
  change_coach: 'Change Coach',
  get_coaching: 'Get Coaching',
  register_choose_your_coach: 'Choose your Coach',
  register_coaching_acknowledgment: 'Coaching Acknowledgment',
  register_more_about_you: 'More about you',
  register_parental_consent: 'Parental Consent',
  register_pending_parental_consent: 'Waiting for Parental Consent',
  register_pending_school_entry: 'Waiting for School',
  register_verify_phone_number: 'Verify Phone Number',
  waitlist: 'Waitlist',
} as const;
