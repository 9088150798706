import { Fragment, useEffect, useState } from 'react';
import closeIcon from '../../../../assets/icons/common/close.png';
import ArrowForward from '../../../../assets/icons/common/arrow_forward_ios.svg';
import UserCarouselCard from '../user-carousel-card/user-carousel-card';
import { CoachMatch } from '../../interfaces/CoachMatch';

import styles from './user-carousel.module.scss';

type Props = {
  coaches: CoachMatch[];
  currentCoach: CoachMatch;
  handleClose: () => void;
  onChange: (data: CoachMatch, isSelected: boolean) => void;
};

const UserCarousel = ({
  coaches,
  currentCoach,
  handleClose,
  onChange,
}: Props) => {
  const [currentlyShownCoach, setCurrentlyShownCoach] = useState(currentCoach);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChangeCurrentlyShownCoach = (
    coach: CoachMatch,
    action: string,
  ) => {
    const index = coaches.indexOf(coach);

    switch (action) {
      case 'previous':
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        index === 0
          ? setCurrentlyShownCoach(coaches[coaches.length - 1])
          : setCurrentlyShownCoach(coaches[index - 1]);
        break;
      case 'next':
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        index === coaches.length - 1
          ? setCurrentlyShownCoach(coaches[0])
          : setCurrentlyShownCoach(coaches[index + 1]);
        break;
    }
  };

  useEffect(() => {
    onChange(currentlyShownCoach, true);
  }, [currentlyShownCoach]);

  //* Renders

  const switchCoachRender = (
    <Fragment>
      <span
        className={`${styles.carouselButton} ${styles.previous}`}
        onClick={() => {
          return handleChangeCurrentlyShownCoach(
            currentlyShownCoach,
            'previous',
          );
        }}
      >
        <img
          alt="Previous"
          className={`${styles.image} ${styles.previousImage}`}
          data-testid="previous"
          src={ArrowForward}
        />
      </span>
      <span
        className={`${styles.carouselButton} ${styles.next}`}
        onClick={() => {
          return handleChangeCurrentlyShownCoach(currentlyShownCoach, 'next');
        }}
      >
        <img
          alt="Next"
          className={styles.image}
          data-testid="next"
          src={ArrowForward}
        />
      </span>
    </Fragment>
  );

  const closeButtonRender = (
    <img
      alt="Close button"
      className={styles.closeIcon}
      src={closeIcon}
      onClick={handleClose}
    />
  );

  const dotsRender = (
    <div className={styles.dotsContainer}>
      {coaches.length > 1 ? (
        coaches.map((dot, index) => {
          return (
            <span
              className={`${
                index === coaches.indexOf(currentlyShownCoach)
                  ? `${styles.dot} ${styles.selected}`
                  : `${styles.dot}`
              }`}
              key={dot.name}
              onClick={() => {
                return setCurrentlyShownCoach(dot);
              }}
            />
          );
        })
      ) : (
        <span
          className={`${styles.dot} ${styles.selected}`}
          onClick={() => {
            return setCurrentlyShownCoach(coaches[0]);
          }}
        />
      )}
    </div>
  );

  return (
    <Fragment>
      <div className={styles.container}>
        {closeButtonRender}
        {coaches.length > 1 && switchCoachRender}
        <UserCarouselCard coach={currentlyShownCoach} />
      </div>
      {dotsRender}
    </Fragment>
  );
};

export default UserCarousel;
