import {
  customValidations,
  regExpressions,
} from "../utils/constants/RegisterStudent";

export const UseValidation = (data: {}, currentStepKey: string) => {
  const key: string = Object.keys(data)[0];
  const value: string = data[key as keyof typeof data];
  let error: string = "";
  const currentValidations: string[] =
    customValidations[currentStepKey as keyof typeof customValidations][key];

  if (currentValidations && Object.keys(currentValidations).length > 0) {
    currentValidations.forEach((validationType) => {
      switch (validationType) {
        case "required":
          if (value === "") {
            error = "This field is required";
          }
          break;
        case "array":
          if (value.length === 0) {
            error = "Please choose at least one option";
          }
          break;
        case "phoneNo":
          if (value && !value.match(regExpressions.phoneNo)) {
            error = "Please enter a valid phone number";
          }
          break;
        case "email":
          if (value && !value.match(regExpressions.email)) {
            error = "Please enter a valid email";
          }
          break;
        case "date":
          if (value && !value.match(regExpressions.date)) {
            error = "Please enter a valid date";
          }
          break;
        default:
          break;
      }
    });
  }

  return { [key]: error };
};
