import { useEffect } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { handleScreenDataChange } from '../../store/registerStudentSlice';
import { ExtendableListing } from '../../../../utils/interfaces/RegisterStudent';
import SelectableCard from '../ui/SelectableCard/SelectableCard';

import styles from './PreferredTime.module.scss';

type Props = {
  data: { [key: string]: boolean };
  isRegister?: boolean;
  listing: ExtendableListing;
  onChangeHandler?: (data: any) => void;
};

const PreferredTime = ({
  data,
  isRegister,
  listing,
  onChangeHandler,
}: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let preferredTimeSelection: { [key: string]: boolean } = { ...data };
  const handleSelect = (value: string, isSelected: boolean) => {
    if (isSelected) {
      preferredTimeSelection = {
        ...preferredTimeSelection,
        [value]: true,
      };
    } else {
      delete preferredTimeSelection[value];
    }
    if (isRegister) {
      dispatch(
        handleScreenDataChange({ preferredTime: preferredTimeSelection }),
      );
    } else {
      onChangeHandler!({ preferred_time: preferredTimeSelection });
    }
  };

  return (
    <div className={styles.container} data-testid="preferredTime-container">
      {listing.predefined.map(time => {
        return (
          <SelectableCard
            key={time.value}
            label={time.label}
            selected={data.hasOwnProperty(time.value) || false}
            value={time.value}
            onSelect={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default PreferredTime;
