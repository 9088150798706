import { useEffect } from 'react';
import { Coach } from '../../../../utils/interfaces/CoachMatching';
import CongratulationsText from './congratulations-text/congratulations-text';
// @ts-ignore
import UserInfo from '../../../coachee/components/user-info/user-info';

import styles from './congratulations.module.scss';

type Props = {
  coach: Coach;
  session_date: number;
};
const Congratulations = ({ coach, session_date }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="congratulations-title">
      <UserInfo
        name={coach.name}
        timeslot={session_date}
        userExternal={coach}
      />
      <CongratulationsText />
    </div>
  );
};

export default Congratulations;
