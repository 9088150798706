import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgEvent = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 18.064c-.7 0-1.292-.242-1.775-.726-.483-.483-.725-1.075-.725-1.775s.242-1.291.725-1.774c.483-.484 1.075-.726 1.775-.726s1.292.242 1.775.726c.483.483.725 1.075.725 1.774 0 .7-.242 1.292-.725 1.775-.483.484-1.075.726-1.775.726Zm-9.5 4c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20.064v-14c0-.55.196-1.021.587-1.413A1.927 1.927 0 0 1 5 4.063h1v-2h2v2h8v-2h2v2h1c.55 0 1.021.196 1.413.588.391.392.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.414a1.928 1.928 0 0 1-1.413.587H5Zm0-2h14v-10H5v10Zm0-12h14v-2H5v2Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgEvent;
