import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Call, Device } from '@twilio/voice-sdk';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useTwilioGeneratedToken } from '../../general/api/useTwilioGeneratedToken';
import { decodeJwt } from '../../../utils/helpers/helpers';
import leave from '../../../assets/icons/common/leave.svg';
import EmptyModal from '../../general/components/empty-modal/empty-modal';
import Button from '../../register-coachee/components/ui/Button/Button';
import mic_mute from '../../../assets/icons/common/mic_mute.svg';
import mic_unmute from '../../../assets/icons/common/mic_unmute.svg';

import styles from './VoiceProvider.module.scss';

const VoiceProvider = () => {
  const { id } = useParams();
  const { state }: any = useLocation();
  const { studentName, type } = state || {};
  const navigate = useNavigate();

  const communicationType = type;
  const [status, setStatus] = useState('');
  const [device, setDevice] = useState<Device | null>(null);
  const [call, setCall] = useState<Call | null>(null);
  const [duration, setDuration] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  // const [isSpeakerActive, setIsSpeakerActive] = useState(false);
  const [endSession, setEndSession] = useState(false);
  const [studentNumber, setStudentNumber] = useState<string | undefined>();

  const JWTdata = useTwilioGeneratedToken(
    communicationType || null,
    id as string,
  );

  useEffect(() => {
    try {
      let token = '';
      if (!JWTdata.isLoading) {
        token = JWTdata.token.token;
        const decoded = decodeJwt(token!);
        setStudentNumber(decoded.grants.voice.outgoing.params.Phone);
        getAudioDevices();
        intitializeDevice(token);
        makeOutgoingCall();
      }
    } catch (error: any) {
      console.error(error);
      toast.error('Call ended! Please try again.');
    }
  }, [!JWTdata.isLoading]);

  // Instantiate a new Twilio.Device
  const intitializeDevice = async (token: string) => {
    if (Device.isSupported) {
      setStatus('Initializing device...');
      const device = new Device(token, {
        logLevel: 1,
      });
      setDevice(device);
      await addDeviceListeners(device);
      return () => {
        device.disconnectAll();
        device.destroy();
      };
    }
    toast.error("Your browser doesn't support voice functionality!");
  };

  // Listen for Device states
  const addDeviceListeners = async (device: Device) => {
    device.on('ready', () => {
      // eslint-disable-next-line no-console
      console.log('Twilio.Device Ready!');
    });

    device.on('error', error => {
      // eslint-disable-next-line no-console
      console.log(`Twilio.Device Error: ${error.message}`);
    });

    device.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log('Successfully established call ! ');
    });

    device.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log('Call ended.');
    });

    device.on('error', error => {
      setStatus(error.message);
    });
  };

  const getAudioDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
  };

  useEffect(() => {
    if (!!device && !!studentNumber) {
      makeOutgoingCall();
    }
    //* Ends call session on reload and on back via browser button
    //* Prevents multiple calls from being established
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', event => {
      event.preventDefault();
      window.location.replace(`/coachee/dashboard`);
    });
  }, [device, studentNumber]);

  //* Start time duration
  let start: any = null;
  const startDuration = () => {
    const startTimestamp = moment().startOf('day');
    start = setInterval(() => {
      startTimestamp.add(1, 'second');
      setDuration(startTimestamp.format('mm:ss'));
    }, 1000);
  };

  const toggleMuteCall = (isMuted: boolean) => {
    setIsMuted(isMuted);
    call?.mute(isMuted);
  };

  // const toggleSpeakerActive = (isSpeakerActive: boolean) => {
  //   setIsSpeakerActive(isSpeakerActive);
  //   //TODO logic for turn on/off speaker
  // };

  //* End time duration
  const endDuration = () => {
    clearInterval(start);
    start = null;
    setDuration('');
  };

  // Listen for Call states
  const addCallListeners = async (call: Call) => {
    call.on('ringing', () => {
      setStatus('Waiting to join...');
    });

    call.on('accept', () => {
      setStatus('');
      // TODO Add correct duration logic and setDuration(duration)
      startDuration();
    });
    call.on('reconnecting', () => {
      setStatus('Call reconnecting...');
    });
    call.on('reconnected', () => {
      setStatus('Call reconnected');
    });
    call.on('disconnect', () => {
      setStatus('Call ended.');
      endDuration();
      setDuration('');
    });
    call.on('cancel', () => {
      setStatus('Call ended.');
      endDuration();
      setDuration('');
    });
    call.on('error', error => {
      setStatus(error.message);
      endDuration();
      setDuration('');
    });
    call.on('reject', () => {
      setStatus('Call rejected.');
      endDuration();
      setDuration('');
    });
  };

  const handleEndSession = () => {
    if (device) {
      call?.disconnect();
      device?.disconnectAll();
      device?.destroy();
    }
    navigate('/coach/dashboard');
    window.location.reload();
  };

  const makeOutgoingCall = async () => {
    if (studentNumber) {
      const params = {
        SessionId: id!,
        To: studentNumber,
      };
      if (!!device && !device.isBusy) {
        // Make an outgoing call
        const call = await device.connect({
          params,
          rtcConstraints: {
            audio: true,
          },
        });
        setCall(call);
        addCallListeners(call);
        return () => {
          call.disconnect();
        };
      }
    }
  };

  const nameRender = <div className={styles.name}>{studentName}</div>;

  const statusRender =
    status && status !== 'open' ? (
      <div className={styles.status}>{status}</div>
    ) : (
      <div className={styles.duration}>{duration}</div>
    );

  const info = (
    <div className={styles.info}>
      {/* {avatarRender} */}
      {nameRender}
      {statusRender}
    </div>
  );

  const toggleAudio = isMuted ? (
    <div
      className={styles.iconContainer}
      onClick={() => {
        return toggleMuteCall(false);
      }}
    >
      <img alt="Mute" className={styles.controlIcon} src={mic_mute} />
    </div>
  ) : (
    <div
      className={styles.iconContainer}
      onClick={() => {
        return toggleMuteCall(true);
      }}
    >
      <img alt="Unmute" className={styles.controlIcon} src={mic_unmute} />
    </div>
  );

  // let toggleSpeaker = isSpeakerActive ? (
  //   <div
  //     className={styles.iconContainer}
  //     onClick={() => toggleSpeakerActive(false)}
  //   >
  //     <img src={speaker_on} alt="Speaker off" />
  //   </div>
  // ) : (
  //   <div
  //     className={styles.iconContainer}
  //     onClick={() => toggleSpeakerActive(true)}
  //   >
  //     <img src={speaker_off} alt="Speaker on" />
  //   </div>
  // );

  const controls = (
    <Fragment>
      <div className={styles.controlsContainer}>
        {/* {toggleSpeaker} */}
        {toggleAudio}
        <div className={styles.iconContainer}>
          <img
            alt="Leave call"
            className={styles.controlIcon}
            src={leave}
            onClick={() => {
              return setEndSession(true);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  const modal = (
    <EmptyModal
      cssClass="height50"
      handleClose={() => {
        return setEndSession(false);
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>
          Are you sure you want to end this session?
        </div>
        <div className={styles.actions}>
          <Button
            isDisabled={false}
            label="Yes, I am sure"
            onClickHandler={() => {
              return handleEndSession();
            }}
          />
          <div
            className={styles.discard}
            onClick={() => {
              setEndSession(false);
            }}
          >
            Discard
          </div>
        </div>
      </div>
    </EmptyModal>
  );

  return (
    <Fragment>
      <div className={styles.mediaContainer}>
        <Fragment>
          {info}
          {controls}
          {endSession && modal}
        </Fragment>
      </div>
    </Fragment>
  );
};

export default VoiceProvider;
