import { useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSWRConfig } from 'swr';
import { useDispatch } from 'react-redux';
import { useFetchStudent } from '../../features/coach/api/useFetchStudent';
import StudentNotes from '../../features/coach/components/StudentNotes/StudentNotes';
import StudentOverview from '../../features/coach/components/StudentOverview/StudentOverview';
import StudentSessions from '../../features/coach/components/StudentSessions/StudentSessions';
import { StudentOverviewConstants } from '../../features/coach/utils/constants/StudentOverviewConstants';
import EmptyModal from '../../features/general/components/empty-modal/empty-modal';
import Tabs from '../../features/general/components/tabs/tabs';
import UserLayout from '../../features/general/components/user-layout/user-layout';
import Button from '../../features/register-coachee/components/ui/Button/Button';
import { useFetchStudentNotes } from '../../features/coach/api/useFetchStudentNotes';
import { createStudentNote } from '../../features/coach/api/createStudentNote';
import { cancelSession } from '../../features/general/api/cancelSession';
import { setOverlay } from '../../features/general/store/generalSlice';
import { addModalToPortal } from '../../features/general/utils/helpers/addModalToPortal';

import styles from './StudentProfile.module.scss';
import generalStyles from '../../features/general/styles/General.module.scss';

const StudentProfile = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(
    StudentOverviewConstants.overview,
  );
  const [note, setNote] = useState<string>('');
  const { id } = useParams();
  const data = useFetchStudent(id as string);
  const studentNotesData = useFetchStudentNotes(id as string);

  const handleCreateNote = async () => {
    try {
      await createStudentNote(id as string, note);
      mutate(`students/${id as string}/notes`);
      toast.success('Note successfully created');
      setShowNoteModal(false);
      dispatch(setOverlay(false));
    } catch {
      toast.error('Could not save note');
    }
  };

  const noteModal = (
    <EmptyModal
      cssClass="mediumModal"
      handleClose={() => {
        setShowNoteModal(false);
        dispatch(setOverlay(false));
      }}
      subtitle="Add a note about the student"
      title="Add a note"
    >
      <textarea
        className={generalStyles.textarea}
        maxLength={500}
        rows={12}
        onChange={e => {
          setNote(e.target.value);
        }}
      />
      <div className={styles.actionButton}>
        <Button
          className="topShift"
          isDisabled={note === ''}
          label="Save"
          onClickHandler={() => {
            return handleCreateNote();
          }}
        />
      </div>
    </EmptyModal>
  );

  const handleCancelSession = async (sessionId: string) => {
    try {
      await cancelSession(sessionId as string);
      mutate(`coaches/student/${id}`);
      toast.success('Session canceled sucessfully');
    } catch {
      toast.success('Could not cancel session');
    }
  };

  const handleNotesModal = () => {
    setShowNoteModal(true);
    dispatch(setOverlay(true));
  };

  return (
    <UserLayout centeredTitle title="Profile">
      <Tabs
        active={selectedTab}
        tab1={StudentOverviewConstants.overview}
        tab2={StudentOverviewConstants.sessions}
        tab3={StudentOverviewConstants.notes}
        onTabChange={(data: string) => {
          return setSelectedTab(data);
        }}
      />
      <div className={styles.container}>
        {selectedTab === StudentOverviewConstants.overview ? (
          <StudentOverview data={data} />
        ) : selectedTab === StudentOverviewConstants.sessions ? (
          <StudentSessions
            data={data}
            handleCancelSession={handleCancelSession}
          />
        ) : selectedTab === StudentOverviewConstants.notes ? (
          <StudentNotes
            handleShowModal={() => {
              return handleNotesModal();
            }}
            studentNotesData={studentNotesData}
          />
        ) : (
          ''
        )}
      </div>
      {showNoteModal && addModalToPortal(noteModal)}
    </UserLayout>
  );
};

export default StudentProfile;
