import React, { Fragment, useEffect, useRef, useState } from 'react';
import closeIcon from '../../../../../assets/icons/common/close.png';
import Input from '../Input/Input';
import {
  ExtendableListing,
  Listing,
} from '../../../../../utils/interfaces/RegisterStudent';
import CustomEntry from '../CustomEntry/CustomEntry';
import AddItemButton from '../AddItemButton/AddItemButton';
import { buttons } from '../../../utils/constants/RegisterStudent';
import Button from '../Button/Button';
import { useAppDispatch } from '../../../../../store/hooks';
import {
  addCustomCoachPreference,
  addCustomInterest,
  addCustomTopic,
  removeCustomCoachPreference,
  removeCustomInterest,
  removeCustomTopic,
} from '../../../store/registerStudentSlice';

import styles from './Modal.module.scss';

type Props = {
  currentStepKey?: string;
  handleClose: () => void;
  handleSave?: (data: object) => void;
  isRegister?: boolean;
  listing?: ExtendableListing;
  placeholder: string;
  removeCustomEntry?: (value: any) => void;
  subtitle: string;
  title: string;
};

const Modal = ({
  currentStepKey,
  handleClose,
  handleSave,
  isRegister,
  listing,
  placeholder,
  removeCustomEntry,
  subtitle,
  title,
}: Props) => {
  const modalInput = useRef<null | HTMLInputElement>(null);
  const [modalErrors, setModalErrors] = useState('');

  const dispatch = useAppDispatch();
  const [customEntry, setCustomEntry] = useState<{
    label: string;
    value: string;
  }>({ label: '', value: '' });
  const [customEntries, setCustomEntries] = useState(listing?.custom || []);

  const addCustomEntry = async () => {
    if (customEntry.value === '') {
      setModalErrors('This field is required');
      return;
    }
    if (validateForDuplicateEntries(customEntry.value)) {
      return;
    }

    setModalErrors('');
    setCustomEntry({ label: '', value: '' });
    customEntries!.find(o => {
      return o.value === customEntry.value;
    });
    setCustomEntries(prevState => {
      return [...prevState!, customEntry];
    });
    modalInput!.current!.value = '';
  };

  const validateForDuplicateEntries = (value: string) => {
    if (
      customEntries!.find(o => {
        return o.value === value;
      })
    ) {
      setModalErrors('Entry already exists');
      return true;
    }
    return false;
  };

  const handleCustomEntryRemove = (label: string) => {
    if (isRegister) {
      switch (currentStepKey) {
        case 'topics':
          dispatch(removeCustomTopic(label));
          break;
        case 'interests':
          dispatch(removeCustomInterest(label));

          break;
        case 'coachPreferences':
          dispatch(removeCustomCoachPreference(label));
          break;
        default:
          break;
      }
    } else {
      removeCustomEntry!({ [currentStepKey as string]: label });
    }
  };

  const handleSaveCustomEntries = () => {
    if (validateForDuplicateEntries(customEntry.value)) {
      return;
    }

    let customEntriesFiltered: Listing[] = [];

    if (customEntries?.length === 0 && customEntry.label !== '') {
      customEntriesFiltered = [customEntry];
    } else if (customEntries?.length === 0 && customEntry.label === '') {
      customEntriesFiltered = [];
    } else {
      customEntriesFiltered =
        customEntry.label !== '' &&
        !customEntries!.find(o => {
          return o.value === customEntry.value;
        })
          ? [...customEntries!, customEntry]
          : [...(customEntries as Listing[])];
    }

    if (isRegister) {
      switch (currentStepKey) {
        case 'topics': {
          dispatch(addCustomTopic(customEntriesFiltered));
          break;
        }
        case 'interests': {
          dispatch(addCustomInterest(customEntriesFiltered));
          break;
        }
        case 'coachPreferences': {
          dispatch(addCustomCoachPreference(customEntriesFiltered));
          break;
        }
        default:
          break;
      }
    } else {
      handleSave!({ [currentStepKey as string]: customEntriesFiltered });
    }

    handleClose();
  };

  // Detect escape keydown for hiding the modal
  useEffect(() => {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleClose();
      }
    });
  }, []);

  return (
    <div className={styles.container} data-testid="modal-client-id">
      <img
        alt="Close button"
        className={styles.closeIcon}
        src={closeIcon}
        onClick={handleClose}
      />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.customEntriesListing}>
          {customEntries && customEntries.length !== 0 && (
            <Fragment>
              {customEntries.map((entry: Listing, index: number) => {
                return (
                  <CustomEntry
                    key={index + entry.label}
                    label={entry.label}
                    removeSelection={() => {
                      setCustomEntries(
                        customEntries.filter(object => {
                          return object.value !== entry.value;
                        }),
                      );
                      handleCustomEntryRemove(entry.label);
                    }}
                  />
                );
              })}
            </Fragment>
          )}
        </div>
        <Input
          errorMessage={modalErrors}
          isInvalid={!!modalErrors}
          name="topic"
          placeholder={placeholder}
          ref={modalInput}
          type="text"
          onChangeHandler={data => {
            setCustomEntry({
              label: data[Object.keys(data)[0]] as string,
              value: data[Object.keys(data)[0]] as string,
            });
          }}
        />
        <AddItemButton
          title="Add another"
          onClickHandler={() => {
            return addCustomEntry();
          }}
        />

        <div className={styles.buttonContainer}>
          <Button
            className="saveButton"
            data-testid="modal-saveButtonx"
            isDisabled={!(customEntries.length > 0 || customEntry.value !== '')}
            label={buttons.save}
            onClickHandler={() => {
              return handleSaveCustomEntries();
            }}
          />
        </div>
      </div>
      <Fragment />
    </div>
  );
};

export default Modal;
