import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import UserInfo from '../../../coachee/components/user-info/user-info';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import { setOverlay } from '../../../general/store/generalSlice';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { StudentOverviewResponse } from '../../interfaces/StudentOverviewResponse';
import ProfileOverviewSessionCard from '../ProfileOverviewSessionCard/ProfileOverviewSessionCard';

import styles from './StudentSessions.module.scss';

type Props = {
  data: StudentOverviewResponse;
  handleCancelSession: (id: string) => void;
};

const StudentSessions = ({ data, handleCancelSession }: Props) => {
  const dispatch = useAppDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    if (sessionId !== null) {
      setShowCancelModal(true);
      dispatch(setOverlay(true));
    }
  }, [sessionId]);

  const cancelModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        setShowCancelModal(false);
        dispatch(setOverlay(false));
        setSessionId(null);
      }}
      title="Cancel session"
    >
      <div className={styles.content}>
        <Button
          isDisabled={false}
          label="Yes, I am sure"
          onClickHandler={() => {
            handleCancelSession(sessionId as string);
            setShowCancelModal(false);
            dispatch(setOverlay(false));
            setSessionId(null);
          }}
        />
        <div
          className={styles.discard}
          onClick={() => {
            setShowCancelModal(false);
            dispatch(setOverlay(false));
            setSessionId(null);
          }}
        >
          Discard
        </div>
      </div>
    </EmptyModal>
  );
  return (
    <div className={styles.container}>
      <UserInfo
        name={`${data.studentData?.name || ''} ${
          data.studentData?.surname || ''
        }`}
      />

      {showCancelModal && addModalToPortal(cancelModal)}
      <div className={styles.listing}>
        {data.studentData &&
          data.studentData.session_timeslots?.map(element => {
            return (
              <ProfileOverviewSessionCard
                handleCancelSession={id => {
                  setSessionId(id as string);
                }}
                key={element.id}
                session={element}
              />
            );
          })}
      </div>
    </div>
  );
};

export default StudentSessions;
