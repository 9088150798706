import { getSpacing } from '../common/common.helpers';
import { BoxProps, Spacing } from '../types';
import { GridProps } from '../common/grid/grid.types';

export type SpacingArgs = Pick<
  Partial<GridProps>,
  | 'padding'
  | 'verticalPadding'
  | 'horizontalPadding'
  | 'paddingLeft'
  | 'paddingBottom'
  | 'paddingRight'
  | 'paddingTop'
  | 'margin'
  | 'verticalMargin'
  | 'horizontalMargin'
  | 'marginLeft'
  | 'marginBottom'
  | 'marginRight'
  | 'marginTop'
  | 'gap'
  | 'rowGap'
  | 'columnGap'
>;

const allowedSpacingProps: { [key: string]: boolean } = {
  columnGap: true,
  gap: true,
  horizontalMargin: true,
  horizontalPadding: true,
  margin: true,
  marginBottom: true,
  marginLeft: true,
  marginRight: true,
  marginTop: true,
  padding: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingRight: true,
  paddingTop: true,
  rowGap: true,
  verticalMargin: true,
  verticalPadding: true,
};

export const extractSpacingProps = (
  args: SpacingArgs,
): Partial<Record<keyof SpacingArgs, string>> => {
  const spacingProps: Partial<Record<keyof SpacingArgs, string>> = {};

  for (const prop in args) {
    // @ts-ignore
    if (args[prop] !== undefined && allowedSpacingProps[prop]) {
      // @ts-ignore
      const value = args[prop];
      const cssValue = getSpacing(value as Spacing);

      switch (prop) {
        case 'verticalPadding':
          spacingProps.paddingTop = cssValue;
          spacingProps.paddingBottom = cssValue;
          break;
        case 'horizontalPadding':
          spacingProps.paddingLeft = cssValue;
          spacingProps.paddingRight = cssValue;
          break;
        case 'verticalMargin':
          spacingProps.marginTop = cssValue;
          spacingProps.marginBottom = cssValue;
          break;
        case 'horizontalMargin':
          spacingProps.marginLeft = cssValue;
          spacingProps.marginRight = cssValue;
          break;
        default:
          // @ts-ignore
          spacingProps[prop as keyof BoxProps] = cssValue;
      }
    }
  }

  return spacingProps;
};
