import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useSessionOverviewFetch = (sessionId: string) => {
  const { data, error } = useSWR(
    `session-timeslots/${sessionId}`,
    interceptorFetcher,
    { revalidateOnFocus: false }
  );
  return {
    isError: error,
    isLoading: !error && !data,
    session: data,
  };
};
