import ForgotPassword from '../../components/ForgotPassword/ForgotPassword/ForgotPassword';
import SetNewPassword from '../../components/ForgotPassword/SetNewPassword/SetNewPassword';
import { ForgotPasswordSteps } from '../../interfaces/forgot-password';

export const stepKeys: { [key: string]: string } = {
  forgottenPassword: 'forgottenPassword',
  setNewPassword: 'setNewPassword',
};

export const stepTitles: { [key: string]: string } = {
  forgottenPassword: 'Forgotten password?',
  setNewPassword: 'Set new password',
};

export const stepSubtitles: { [key: string]: string } = {
  forgottenPassword:
    'Please provide your email to start reset password process.',
  setNewPassword:
    'Please check your SMS inbox and add the 6 digit code you received.',
};

export const stepValidationErrors: { [key: string]: string } = {
  forgottenPassword: 'Please enter your email',
  setNewPassword: 'Please enter your new password',
};

export const buttons: { [key: string]: string } = {
  back: '< Back',
  forgottenPassword: 'Send reset code',
  setNewPassword: 'Save password',
};

export const forgotPasswordSteps: ForgotPasswordSteps = {
  forgottenPassword: {
    button: buttons.forgottenPassword,
    component: ForgotPassword,
    customValidation: true,
    key: stepKeys.forgottenPassword,
    nextStepKey: stepKeys.setNewPassword,
    subtitle: stepSubtitles[stepKeys.forgottenPassword],
    title: stepTitles[stepKeys.forgottenPassword],
  },
  setNewPassword: {
    button: buttons.setNewPassword,
    component: SetNewPassword,
    customValidation: true,
    key: stepKeys.setNewPassword,
    subtitle: stepSubtitles[stepKeys.setNewPassword],
    title: stepTitles[stepKeys.setNewPassword],
  },
};

export const regExpressions: { [key: string]: RegExp } = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
