import { useEffect, useState } from 'react';
import UserLayout from '../../../general/components/user-layout/user-layout';
import { Dashboard } from '../../utils/constants/DashboardConstants';
import BottomMenu from './BottomMenu/BottomMenu';
import SessionListing from './SessionListing/SessionListing';
import Students from './Students/Students';
import StudentConversationListing from './Conversations/StudentConversationListing';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setDashboardState } from '../../store/coachSlice';
import { fetchStudents } from '../../api/useFetchCoachStudents';
import { Student } from '../../../register-coachee/interfaces/Student';

import styles from './CoachDashboard.module.scss';

const CoachDashboard = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(state => {
    return state.coach.dashboardState;
  });
  const handleTabSelection = (tab: string) => {
    dispatch(setDashboardState(tab));
  };

  const [students, setStudents] = useState<Student[] | undefined>();

  const handleStudents = async () => {
    const res = await fetchStudents();
    setStudents(res);
  };

  useEffect(() => {
    if (
      selectedTab === Dashboard.tabs.students ||
      selectedTab === Dashboard.tabs.chat
    ) {
      handleStudents();
    }
  }, [selectedTab]);

  return (
    <UserLayout
      hideBackElement
      title={Dashboard.titles[selectedTab as keyof typeof Dashboard.titles]}
    >
      <div>
        <div className={styles.currentDisplay}>
          {selectedTab === Dashboard.tabs.calendar ? (
            <SessionListing />
          ) : selectedTab === Dashboard.tabs.students ? (
            <Students students={students} />
          ) : (
            <StudentConversationListing />
          )}
        </div>
        <BottomMenu onTabSelect={handleTabSelection} />
      </div>
    </UserLayout>
  );
};

export default CoachDashboard;
