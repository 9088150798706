import { useEffect } from 'react';
import { Listing } from '../../../../utils/interfaces/RegisterStudent';
import SelectableCard from '../ui/SelectableCard/SelectableCard';

import styles from './CoachPersonalityDescriptors.module.scss';

type Props = {
  data: { [key: string]: boolean };
  listing: {
    predefined: Listing[];
  };
  onChangeHandler?: (data: any) => void;
};

const CoachPersonalityDescriptors = ({
  data,
  listing,
  onChangeHandler,
}: Props) => {
  let personalityDescriptorSelection: { [key: string]: boolean } = { ...data };

  const handleSelect = (value: string, isSelected: boolean) => {
    if (isSelected) {
      personalityDescriptorSelection = {
        ...personalityDescriptorSelection,
        [value]: true,
      };
    } else {
      delete personalityDescriptorSelection[value];
    }

    onChangeHandler!({
      personality_descriptors: personalityDescriptorSelection,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={styles.container}
      data-testid="personalityDescriptor-container"
    >
      {listing.predefined.map((descriptor: Listing) => {
        return (
          <SelectableCard
            key={descriptor.value}
            label={descriptor.label}
            selected={data[descriptor.value]}
            value={descriptor.value}
            onSelect={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default CoachPersonalityDescriptors;
