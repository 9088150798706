import React, { FC } from 'react';
import { StudentDrawerProps } from './student-drawer.types';
import { Details } from '../details';

export const StudentDrawer: FC<StudentDrawerProps> = props => {
  const { onHideDrawer, onRefresh, student, type } = props;

  if (type === 'details') {
    return (
      <Details
        student={student}
        onHideDrawer={onHideDrawer}
        onRefresh={onRefresh}
      />
    );
  }
};
