import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Auth } from 'aws-amplify';
import UserLayout from '../../../general/components/user-layout/user-layout';
import Edit from '../../../../assets/icons/common/edit.svg';
import Button from '../../../register-coachee/components/ui/Button/Button';
import InterestCard from '../../../coach-matching/components/interest-card/interest-card';
import Input from '../../../register-coachee/components/ui/Input/Input';
import MaskedInput from '../../../register-coachee/components/ui/MaskedInput/MaskedInput';
import SelectInput from '../../../register-coachee/components/ui/SelectInput/SelectInput';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Interests from '../../../register-coachee/components/interests/Interests';
import Topics from '../../../register-coachee/components/topics/Topics';
import PreferredTime from '../../../register-coachee/components/preferred-time/PreferredTime';
import PreferredCommunication from '../../../register-coachee/components/preferred-communication/PreferredCommunication';
import CoachPreferences from '../../../register-coachee/components/coach-preferences/CoachPreferences';
import AddItemButton from '../../../register-coachee/components/ui/AddItemButton/AddItemButton';
import Modal from '../../../register-coachee/components/ui/Modal/Modal';
import VerifyPhoneNumber from '../../../authentication/components/VerifyPhoneNumber/VerifyPhoneNumber';
import { Listing } from '../../../../utils/interfaces/RegisterStudent';
import { regExpressions } from '../../../register-coachee/utils/constants/RegisterStudent';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { deleteStudent } from '../../../register-coachee/api/deleteStudent';
import useAuth from '../../../authentication/hooks/UseAuth';
import { updateStudent } from '../../../register-coachee/api/updateStudent';
import {
  addCustomInterest,
  addCustomTopic,
  setIsButtonDisabled,
} from '../../../register-coachee/store/registerStudentSlice';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';
import { setOverlay } from '../../../general/store/generalSlice';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import { Student } from '../../../authentication/interfaces';
import { ImageUpload } from '../../../../common';

import generalStyles from '../../../coach-matching/components/general/general.module.scss';
import styles from './profile.module.scss';

const Profile = () => {
  const {
    cognitoUpdateUserPhoneAttribute,
    cognitoUserSignOut,
    cognitoVerifyUserAttribute,
    cognitoVerifyUserAttributeSubmit,
  } = useAuth();
  const user = localStorageUser<Student>();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmPhoneModalOpen, setIsConfirmPhoneModalOpen] = useState(false);
  const [modalSelection, setModalSelection] = useState('');
  const [scrollToTop, setScrollToTop] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState<Student>(localStorageUser<Student>());
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useAppDispatch();

  const isVerifyButtonDisabled = useAppSelector(state => {
    return state.registerStudent.isButtonDisabled;
  });

  useEffect(() => {
    if (!isEditing) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [scrollToTop, isEditing]);

  useEffect(() => {
    setScrollToTop(!scrollToTop);
  }, [isEditing]);

  const [errors, setErrors] = useState({
    coaching_language: '',
    email: '',
    name: '',
    phone_number: '',
    surname: '',
  });

  const [deleteAccount, setDeleteAccount] = useState(false);

  const topicsListing = useAppSelector(state => {
    return state.registerStudent.topics;
  });

  const interestsListing = useAppSelector(state => {
    return state.registerStudent.interests;
  });

  const preferredTimeListing = useAppSelector(state => {
    return state.registerStudent.preferredTime;
  });

  const preferredCommunicationListing = useAppSelector(state => {
    return state.registerStudent.preferredCommunication;
  });

  const coachPreferencesListing = useAppSelector(state => {
    return state.registerStudent.coachPreferences;
  });

  const coachingLanguages = useAppSelector(state => {
    return state.registerStudent.listings.coachingLanguages;
  });

  // const [initialData, setInitialData] = useState();

  const modalConfig = {
    interests: {
      placeholder: 'A custom interest',
      subtitle: 'Add all the you did not find in the list.',
      title: 'Add interests',
    },
    topics: {
      placeholder: 'A custom topic',
      subtitle: 'Add all the you did not find in the list.',
      title: 'Add topics',
    },
  };

  const editButton = (
    <div
      className={styles.editButton}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      Edit profile
      <img alt="Edit" className={styles.edit} src={Edit} />
    </div>
  );

  const validateInput = (childData: { [key: string]: string }) => {
    switch (Object.keys(childData)[0]) {
      case 'name':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.name = 'Please enter your name';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.name = '';
            return update;
          });
        }
        break;
      case 'surname':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.surname = 'Please enter your lastname';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.surname = '';
            return update;
          });
        }
        break;
      case 'email':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = 'Please enter your email';
            return update;
          });
        } else if (!Object.values(childData)[0].match(regExpressions.email)) {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = 'Please enter a valid email';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = '';
            return update;
          });
        }
        break;
      case 'phone_number':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.phone_number = 'Please enter your phone number';
            return update;
          });
        } else if (!Object.values(childData)[0].match(regExpressions.phoneNo)) {
          setErrors(prevState => {
            const update = { ...prevState };
            update.phone_number = 'Please enter a valid phone number';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.phone_number = '';
            return update;
          });
        }
        break;
      case 'coaching_language':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.coaching_language = 'Please enter a language for coaching';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.coaching_language = '';
            return update;
          });
        }
        break;
      default:
        break;
    }
  };

  const updateCognitoPhoneNumber = async (data: any) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      await cognitoUpdateUserPhoneAttribute(cognitoUser, data.phone_number);
      dispatch(setIsButtonDisabled(true));
      setIsConfirmPhoneModalOpen(false);
      dispatch(setOverlay(false));
      const updateData = { ...data };
      updateData.status = STATUSES.ACTIVE_NOT_VERIFIED;
      updateStudentDb(updateData);
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const updateStudentDb = async (data: any) => {
    try {
      const response = await updateStudent(data);
      if (response !== undefined) {
        localStorage.setItem('user', JSON.stringify(response?.data));
        setData(response.data);
        setIsEditing(false);
      }
    } catch (error: any) {
      setData(user);
      toast.error('There was a problem, please try again');
      setIsEditing(false);
    }
  };

  // Methods
  const handleDataChange = (childData: any) => {
    const index = Object.keys(childData)[0];
    const values = Object.keys(childData[index]);
    setData((prevState: any) => {
      const update = { ...prevState };
      update[index] = values;
      return update;
    });
  };

  const handleInputChange = async (inputData: any) => {
    if (Object.keys(inputData)[0] === 'phone_number') {
      inputData.phone_number = inputData.phone_number.replace(/-/g, '');
    }
    validateInput(inputData);
    const index = Object.keys(inputData)[0];
    setData((prevState: any) => {
      const update = { ...prevState };
      // eslint-disable-next-line prefer-destructuring
      update[index] = Object.values(inputData)[0];
      return update;
    });
  };

  const deleteStudentAccount = async () => {
    try {
      await deleteStudent();
      await cognitoUserSignOut();
      navigate('/login');
    } catch (error: any) {
      console.error({ error });
      return toast.error(error.response.data.message[0]);
    }
  };

  const updateData = { ...data };
  const handleUpdateUser = async () => {
    // TODO: refactor to be in line with BE!
    updateData.preferred_communication_channel =
      updateData.preferred_communication_channel[0];

    //* Update phone_number attribute in Cognito
    const cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    updateData.profile_img = imageUrl || data.profile_img || '';

    if (updateData.phone_number !== cognitoUser.attributes.phone_number) {
      setIsConfirmPhoneModalOpen(true);
      dispatch(setOverlay(true));
    } else if (
      updateData.phone_number === cognitoUser.attributes.phone_number &&
      cognitoUser.attributes.phone_number_verified === false
    ) {
      setIsConfirmPhoneModalOpen(true);
      dispatch(setOverlay(true));
    } else {
      await updateStudentDb(updateData);
      toast.success('Profile updated successfully');
    }
    setIsEditing(false);
  };

  const verifyNewPhoneNumber = async () => {
    try {
      await cognitoVerifyUserAttributeSubmit('phone_number', otpCode!);
      const updateData = { ...data };
      updateData.status = STATUSES.ACTIVE;
      updateStudentDb(updateData);
      toast.success('Profile updated successfully');
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleResendConfirmationCode = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    try {
      await cognitoVerifyUserAttribute(cognitoUser);
      toast.success('Verification code has been sent!');
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (
      (!!data &&
        (data.topics?.length === 0 ||
          data.interests?.length === 0 ||
          data.preferred_time?.length === 0 ||
          data.preferred_communication_channel?.length === 0)) ||
      errors.name ||
      errors.surname ||
      errors.coaching_language ||
      errors.email ||
      errors.phone_number
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [data, errors]);

  const confirmPhoneModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        setIsConfirmPhoneModalOpen(false);
        dispatch(setOverlay(false));
        setIsEditing(true);
      }}
      title="Confirm your phone number"
    >
      <div className={styles.modalContent}>
        <p>
          Please check again if the following phone number is correct:{' '}
          <span className={styles.confirmNumberLabel}>
            {updateData.phone_number}
          </span>
        </p>
        <div className={styles.buttonContainer}>
          <Button
            isDisabled={false}
            label="Yes, it's correct"
            onClickHandler={() => {
              updateCognitoPhoneNumber(updateData);
            }}
          />
        </div>
        <div
          className={styles.changeNumberButton}
          onClick={() => {
            setIsConfirmPhoneModalOpen(false);
            dispatch(setOverlay(false));
            setIsEditing(true);
          }}
        >
          No, change number
        </div>
      </div>
    </EmptyModal>
  );

  const avatar = isEditing ? (
    <ImageUpload
      imageUrl={data?.profile_img}
      size="xl"
      onImageUpload={setImageUrl}
    />
  ) : (
    <img
      alt="Avatar"
      className={generalStyles.avatarXl}
      src={
        data?.profile_img ||
        'https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png'
      }
    />
  );

  const fullName = data && (
    <div className={styles.nameContainer}>{`${data.name || ''} ${
      data.surname || ''
    }`}</div>
  );

  const filtredCoachPreferences = coachPreferencesListing.predefined.filter(
    preference => {
      return data.preferences.includes(preference.value);
    },
  );

  const profileInfo = (
    <div className={styles.plain}>
      {filtredCoachPreferences.map((preference, index) => {
        return `${preference.label}${
          index < filtredCoachPreferences.length - 1 ? ', ' : ''
        }`;
      })}
    </div>
  );

  const actionButtons = (
    <div className={styles.actionButtonsContainer}>
      <Button
        isDisabled={isDisabled}
        label="Save changes"
        onClickHandler={() => {
          return handleUpdateUser();
        }}
      />
      <div
        className={styles.discard}
        onClick={() => {
          setData(user);
          setIsEditing(false);
        }}
      >
        Discard changes
      </div>
    </div>
  );

  const aboutMeLabel = <div className={styles.sectionTitle}>ABOUT ME</div>;

  const birthday = data && (
    <div>
      <div className={styles.greyTitle}>Birthday</div>
      <div className={styles.plain}>
        {moment(data.date_of_birth).format('MM/DD/YYYY')}
      </div>
    </div>
  );

  const email = data && (
    <div>
      <div className={styles.greyTitle}>Email</div>
      <div className={styles.plain}>{data.email}</div>
    </div>
  );

  const phone = data && (
    <div>
      <div className={styles.greyTitle}>Phone</div>
      <div className={styles.plain}>{data.phone_number}</div>
    </div>
  );

  const capitalizedLanguage =
    data &&
    data.coaching_language.charAt(0).toUpperCase() +
      data.coaching_language.slice(1);

  const language = (
    <div>
      <div className={styles.greyTitle}>Language for coaching</div>
      <div className={styles.plain}>{capitalizedLanguage}</div>
    </div>
  );

  // Filtered data for display

  const filteredTopics = topicsListing.predefined.filter(topic => {
    return data.topics.includes(topic.value);
  });
  const filteredInterests = interestsListing.predefined.filter(interest => {
    return data.interests.includes(interest.value);
  });
  const filteredPreferredTime = preferredTimeListing.predefined.filter(time => {
    return data.preferred_time.includes(time.value);
  });
  const filteredPreferredCommunication =
    preferredCommunicationListing.predefined.find(element => {
      return element.value === data.preferred_communication_channel;
    });

  // Renders

  const aboutMeContainer = (
    <div className={styles.aboutMeContainer}>
      {aboutMeLabel}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={data ? data.name : ''}
          errorMessage={errors.name}
          isInvalid={!!errors.name}
          label="First Name"
          name="name"
          placeholder="Your name"
          type="text"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        ''
      )}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={data ? data.surname : ''}
          errorMessage={errors.surname}
          isInvalid={!!errors.surname}
          label="Last Name"
          name="surname"
          placeholder="Your lastname"
          type="text"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        ''
      )}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={data ? data.email : ''}
          errorMessage={errors.email}
          isInvalid={!!errors.email}
          isReadOnly
          label="E-mail"
          name="email"
          placeholder="email@domain.com"
          readOnly
          type="email"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        email
      )}
      {!isEditing ? birthday : ''}
      {isEditing ? (
        window.location.host === 'localhost:3000' ||
        window.location.host === 'test.trula.com' ? (
          <Input
            className="fullWidth"
            defaultValue={data ? data.phone_number : ''}
            errorMessage={errors.phone_number}
            isInvalid={!!errors.phone_number}
            label="Phone number"
            name="phone_number"
            placeholder="XXX-XXX-XXXX"
            tabIndex={7}
            type="text"
            onChangeHandler={data => {
              return handleInputChange(data);
            }}
          />
        ) : (
          <MaskedInput
            className={styles.phone_number}
            defaultValue={data ? data.phone_number : ''}
            error={errors.phone_number}
            format="+1-###-###-####"
            label="Phone number"
            mask="_"
            name="phone_number"
            placeholder="XXX-XXX-XXXX"
            tabIndex={6}
            onChange={handleInputChange}
          />
        )
      ) : (
        phone
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={data.coaching_language || ''}
          error={errors.coaching_language}
          label="Language for coaching"
          name="coaching_language"
          options={coachingLanguages}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        language
      )}
    </div>
  );

  const removeCustomEntry = (customEntry: any) => {
    const index = Object.keys(customEntry)[0];
    switch (index) {
      case 'topics':
        setData((prevState: any) => {
          const update = { ...prevState };
          update.topics = update.topics.filter((el: any) => {
            return el !== Object.values(customEntry)[0];
          });
          return update;
        });
        topicsListing.custom = topicsListing.custom!.filter(element => {
          return element.value !== Object.values(customEntry)[0];
        });
        break;
      case 'interests':
        setData((prevState: any) => {
          const update = { ...prevState };
          update.interests = update.interests.filter((el: any) => {
            return el !== Object.values(customEntry)[0];
          });
          return update;
        });
        interestsListing.custom = interestsListing.custom!.filter(element => {
          return element.value !== Object.values(customEntry)[0];
        });
        break;
      case 'preferences':
        setData((prevState: any) => {
          const update = { ...prevState };
          update.preferences = update.preferences.filter((el: any) => {
            return el !== Object.values(customEntry)[0];
          });
          return update;
        });
        coachPreferencesListing.custom = coachPreferencesListing.custom!.filter(
          element => {
            return element.value !== Object.values(customEntry)[0];
          },
        );
        break;
      default:
        break;
    }
  };

  const topics = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>COACHING TOPICS</div>
      {data.topics.length === 0 ? (
        <div className={styles.error}>Please choose at least one topic</div>
      ) : (
        ''
      )}
      <Topics
        data={data.topics.reduce(
          (acc: { [key: string]: boolean }, topic: any) => {
            acc[topic] = true;
            return acc;
          },
          {},
        )}
        isRegister={false}
        listing={topicsListing}
        removeCustomEntry={removeCustomEntry}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
      <AddItemButton
        title="Add other topics"
        onClickHandler={() => {
          setModalSelection('topics');
          setIsModalOpen(true);
        }}
      />
    </div>
  ) : (
    <div className={styles.card}>
      <div className={styles.cardTitle}>COACHING TOPICS</div>
      <div className={styles.plain}>
        {filteredTopics.map((topic, index) => {
          return `${topic.label}${
            index < filteredTopics.length - 1 ? ', ' : ''
          }`;
        })}

        {topicsListing.custom!.length > 0 && <br />}
        {topicsListing.custom!.length > 0 &&
          topicsListing.custom?.map((customTopic, index) => {
            return `${customTopic.label}${
              index < topicsListing.custom!.length - 1 ? ', ' : ''
            }`;
          })}
      </div>
    </div>
  );

  const interests = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>INTERESTS</div>
      {data.interests.length === 0 ? (
        <div className={styles.error}>Please choose at least one interest</div>
      ) : (
        ''
      )}
      <Interests
        data={data.interests.reduce(
          (acc: { [key: string]: boolean }, interest: any) => {
            acc[interest] = true;
            return acc;
          },
          {},
        )}
        listing={interestsListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />

      <AddItemButton
        title="Add other"
        variant="round"
        onClickHandler={() => {
          setModalSelection('interests');
          setIsModalOpen(true);
        }}
      />
    </div>
  ) : (
    <div className={styles.card}>
      <div className={styles.cardTitle}>INTERESTS</div>
      <div className={styles.interestsBar}>
        {filteredInterests.map(interest => {
          return (
            <InterestCard
              interest={{
                label: interest.label,
                value: interest.value,
              }}
              key={interest.label + interest.value}
            />
          );
        })}
        {interestsListing.custom!.length > 0 &&
          interestsListing.custom?.map(customInterest => {
            return (
              <InterestCard
                interest={{
                  label: customInterest.label,
                  value: customInterest.value,
                }}
                key={customInterest.label + customInterest.value}
              />
            );
          })}
      </div>
    </div>
  );

  const preferredTime = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>PREFERRED TIME</div>
      {data.preferred_time.length === 0 ? (
        <div className={styles.error}>
          Please choose a time you preffer for coaching
        </div>
      ) : (
        ''
      )}
      <PreferredTime
        data={data.preferred_time.reduce(
          (acc: { [key: string]: boolean }, time: any) => {
            acc[time] = true;
            return acc;
          },
          {},
        )}
        listing={preferredTimeListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
    </div>
  ) : (
    <div className={styles.card}>
      <div className={styles.cardTitle}>PREFERRED TIME</div>
      <div className={styles.plain}>
        {filteredPreferredTime.map((time, index) => {
          return `${time.label}${
            index < filteredPreferredTime.length - 1 ? ', ' : ''
          }`;
        })}
      </div>
    </div>
  );

  const deleteAccountModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setDeleteAccount(false);
      }}
    >
      <div className={styles.deleteModalContainer}>
        <div className={styles.deleteTitle}>
          Do you really want to delete your account?
        </div>
        <div className={styles.actions}>
          <Button
            isDisabled={false}
            label="Yes, please delete"
            onClickHandler={() => {
              deleteStudentAccount();
              setDeleteAccount(false);
              dispatch(setOverlay(false));
            }}
          />
          <div
            className={styles.discard}
            onClick={() => {
              dispatch(setOverlay(false));
              setDeleteAccount(false);
            }}
          >
            Discard
          </div>
        </div>
      </div>
    </EmptyModal>
  );

  const communication = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>PREFERRED COMMUNICATION</div>
      {data.preferred_communication_channel &&
      data.preferred_communication_channel.length === 0 ? (
        <div className={styles.error}>
          Please choose a way of communication for coaching
        </div>
      ) : (
        ''
      )}
      <PreferredCommunication
        data={{ [data.preferred_communication_channel]: true }}
        listing={preferredCommunicationListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
    </div>
  ) : (
    <div className={styles.card}>
      <div className={styles.cardTitle}>WAY OF COMMUNICATION</div>
      <div className={styles.plain}>
        {filteredPreferredCommunication?.label}
      </div>
    </div>
  );

  const preferences = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>COACH PREFERRENCE</div>

      <CoachPreferences
        data={data.preferences.reduce(
          (acc: { [key: string]: boolean }, preference: any) => {
            acc[preference] = true;
            return acc;
          },
          {},
        )}
        isRegister={false}
        listing={coachPreferencesListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
    </div>
  ) : (
    <div className={styles.card}>
      <div className={styles.cardTitle}>COACH PREFERRENCE</div>
      <div className={styles.plain}>
        {filtredCoachPreferences.map((preference, index) => {
          return `${preference.label}${
            index < filtredCoachPreferences.length - 1 ? ', ' : ''
          }`;
        })}

        {coachPreferencesListing.custom!.length > 0 && <br />}
        {coachPreferencesListing.custom!.length > 0 &&
          coachPreferencesListing.custom?.map((customPreference, index) => {
            return `${customPreference.label}${
              index < coachPreferencesListing.custom!.length - 1 ? ', ' : ''
            }`;
          })}
      </div>
    </div>
  );

  const cardsContainer = (
    <div className={styles.cardsContainer}>
      {topics}
      {interests}
      {preferredTime}
      {communication}
      {preferences}
    </div>
  );

  const deleteAccountButton = (
    <div
      className={styles.deleteAccountButton}
      onClick={() => {
        dispatch(setOverlay(true));
        setDeleteAccount(true);
      }}
    >
      Delete your Trula account
    </div>
  );

  const saveCustomEntries = (customEntriesData: any) => {
    const index = Object.keys(customEntriesData)[0];
    let values = Object.values(customEntriesData[index]) as Listing[];

    values = values.filter(value => {
      return !data.topics.includes(value.label);
    });

    switch (index) {
      case 'topics':
        dispatch(addCustomTopic(values));
        setData((prevState: any) => {
          const update = { ...prevState };
          update.topics.push(
            ...values.map(a => {
              return a.value;
            }),
          );
          return update;
        });
        break;
      case 'interests':
        dispatch(addCustomInterest(values));
        setData((prevState: any) => {
          const update = { ...prevState };
          update.interests.push(
            ...values.map(a => {
              return a.value;
            }),
          );
          return update;
        });
        break;
      case 'preferences':
        coachPreferencesListing.custom!.push(...(values as any));
        setData((prevState: any) => {
          const update = { ...prevState };
          update.preferences.push(
            ...values.map(a => {
              return a.value;
            }),
          );
          return update;
        });
        break;
      default:
        break;
    }
  };

  return (
    <UserLayout
      centeredTitle
      customBackAction={() => {
        return navigate('/coachee/dashboard');
      }}
      hideBackElement={user && user.status === STATUSES.ACTIVE_NOT_VERIFIED}
      title={
        user && user.status !== STATUSES.ACTIVE_NOT_VERIFIED
          ? 'Profile'
          : 'Verify your phone number'
      }
    >
      <div className={`${styles.container} `}>
        <div className={styles.avatarSection}>
          {user && user.status !== STATUSES.ACTIVE_NOT_VERIFIED && avatar}
          {/* Show readonly data if the user is not editing the profile - otherwise show the editable components */}
          {user &&
            user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
            !isEditing &&
            fullName}
          {user &&
            user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
            !isEditing &&
            profileInfo}
          {user &&
            user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
            !isEditing &&
            editButton}
        </div>

        {user &&
          user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
          aboutMeContainer}
        {user && user.status !== STATUSES.ACTIVE_NOT_VERIFIED && cardsContainer}
        {user &&
          user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
          isEditing &&
          actionButtons}
        {user &&
          user.status !== STATUSES.ACTIVE_NOT_VERIFIED &&
          !isEditing &&
          deleteAccountButton}
      </div>

      {isModalOpen && (
        <Modal
          currentStepKey={modalSelection}
          handleClose={() => {
            return setIsModalOpen(false);
          }}
          handleSave={saveCustomEntries}
          listing={
            modalSelection === 'topics' ? topicsListing : interestsListing
          }
          placeholder={
            modalConfig[modalSelection as keyof typeof modalConfig].placeholder
          }
          removeCustomEntry={removeCustomEntry}
          subtitle={
            modalConfig[modalSelection as keyof typeof modalConfig].subtitle
          }
          title={modalConfig[modalSelection as keyof typeof modalConfig].title}
        />
      )}
      {deleteAccount && addModalToPortal(deleteAccountModal)}

      {user && user.status === STATUSES.ACTIVE_NOT_VERIFIED && (
        <Fragment>
          <p className={`${styles.subtitle} ${styles.textCentered}`}>
            Please check your SMS inbox and add the 6 digit code you received.
          </p>
          <VerifyPhoneNumber
            onResendConfirmationCode={() => {
              return handleResendConfirmationCode();
            }}
            onVerifyPhoneNumber={(data: string) => {
              return setOtpCode(data);
            }}
          />
          <div className={styles.nextButton}>
            <Button
              className="verifyPhoneNumberButton"
              isDisabled={isVerifyButtonDisabled}
              label="Verify"
              onClickHandler={() => {
                return verifyNewPhoneNumber();
              }}
            />
          </div>
        </Fragment>
      )}
      {!!isConfirmPhoneModalOpen && addModalToPortal(confirmPhoneModal)}
    </UserLayout>
  );
};

export default Profile;
