import { ChangeEvent, useEffect } from 'react';
import { IScreenProps } from '../../../../utils/interfaces/RegisterStudent';
import {
  coachingAcknowledgment,
  coachingAcknowledgmentAgreement,
} from '../../utils/constants/RegisterStudent';
import Checkbox from '../ui/Checkbox/Checkbox';
import { useAppDispatch } from '../../../../store/hooks';
import {
  handleInputDataChange,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';

import styles from './CoachingAcknowledgment.module.scss';

const CoachingAcknowledgment = ({ data }: IScreenProps) => {
  const dispatch = useAppDispatch();

  const handleCheck = (value: string, e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleInputDataChange({
        coachingAcknowledgment: {
          [value]: [!!e.target.checked],
        },
      }),
    );
  };

  useEffect(() => {
    if (data.coachingAcknowledgment === 'true') {
      dispatch(setIsButtonDisabled(false));
    } else {
      dispatch(setIsButtonDisabled(true));
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="container-client-id">
      <p
        dangerouslySetInnerHTML={{
          __html: coachingAcknowledgmentAgreement.agreement,
        }}
      />
      {coachingAcknowledgment.map(acknowledgment => {
        return (
          <Checkbox
            checked={data[acknowledgment.value as string] === 'true'}
            key={acknowledgment.value}
            label={acknowledgment.label}
            required={acknowledgment.value === 'coachingAcknowledgment'}
            value={acknowledgment.value}
            onCheck={handleCheck}
          />
        );
      })}
    </div>
  );
};

export default CoachingAcknowledgment;
