import { Theme } from '../../interfaces/Theme';
import edit from '../../../../assets/icons/common/edit.svg';
import InterestCard from '../../../coach-matching/components/interest-card/interest-card';

import styles from './CoachSessionOverview.module.scss';
import cardStyles from '../../../general/components/card/card.module.scss';

type Props = {
  onEdit: () => void;
  themes: Theme[];
};

const SessionThemes = ({ onEdit, themes }: Props) => {
  return (
    <div className={styles.themesContainer}>
      <div className={styles.themesHeader}>
        <div className={styles.themesTitles}>
          <div className={cardStyles.title}>COACHING THEMES</div>
        </div>
        <div
          className={styles.action}
          onClick={() => {
            return onEdit();
          }}
        >
          <img alt="Edit" src={edit} width={14} />
        </div>
      </div>
      <div className={styles.themesListing}>
        {themes && themes.length > 0 ? (
          themes.map(theme => {
            return (
              <InterestCard
                className="dark"
                key={theme.id}
                value={theme.theme}
              />
            );
          })
        ) : (
          <div className={styles.label}>No coaching themes chosen</div>
        )}
      </div>
    </div>
  );
};

export default SessionThemes;
