import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgPerson = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13.063c-1.1 0-2.042-.391-2.825-1.175C8.392 11.106 8 10.165 8 9.064v-3.5c0-.416.146-.77.438-1.062A1.444 1.444 0 0 1 9.5 4.063c.25 0 .488.059.713.175.225.117.404.284.537.5.133-.216.313-.383.538-.5a1.53 1.53 0 0 1 1.425 0c.225.117.404.284.537.5.133-.216.313-.383.538-.5.225-.116.462-.175.712-.175.417 0 .77.146 1.062.438.292.292.438.646.438 1.062v3.5c0 1.1-.392 2.042-1.175 2.825-.783.784-1.725 1.175-2.825 1.175Zm0-2c.55 0 1.021-.196 1.413-.588.391-.39.587-.862.587-1.412v-2.5h-4v2.5c0 .55.196 1.021.588 1.412.391.393.862.588 1.412.588Zm-8 10v-2.8c0-.566.146-1.087.438-1.563A2.914 2.914 0 0 1 5.6 15.615a14.86 14.86 0 0 1 3.15-1.164 13.776 13.776 0 0 1 3.25-.386c1.1 0 2.183.129 3.25.386a14.86 14.86 0 0 1 3.15 1.164c.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563v2.8H4Zm2-2h12v-.8a.943.943 0 0 0-.137-.5.977.977 0 0 0-.363-.35c-.9-.45-1.808-.787-2.725-1.012a11.615 11.615 0 0 0-5.55 0c-.917.225-1.825.563-2.725 1.012a.97.97 0 0 0-.5.85v.8Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgPerson;
