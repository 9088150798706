import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgPeople = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 20.064v-2.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 0 1 2.6 14.613a14.86 14.86 0 0 1 3.15-1.162A13.776 13.776 0 0 1 9 13.063c1.1 0 2.183.13 3.25.387a14.86 14.86 0 0 1 3.15 1.164c.483.25.87.612 1.162 1.086.292.476.438.997.438 1.564v2.8H1Zm18 0v-3c0-.734-.204-1.438-.612-2.114-.409-.674-.988-1.253-1.738-1.736.85.1 1.65.27 2.4.511.75.242 1.45.539 2.1.889.6.333 1.058.704 1.375 1.111.317.41.475.855.475 1.339v3h-4Zm-10-8c-1.1 0-2.042-.392-2.825-1.175C5.392 10.104 5 9.163 5 8.063s.392-2.041 1.175-2.825C6.958 4.455 7.9 4.063 9 4.063s2.042.392 2.825 1.175C12.608 6.022 13 6.963 13 8.063s-.392 2.042-1.175 2.825c-.783.784-1.725 1.175-2.825 1.175Zm6 0c-.183 0-.417-.021-.7-.063a6.181 6.181 0 0 1-.7-.137 5.938 5.938 0 0 0 1.037-1.776c.242-.65.363-1.325.363-2.025s-.121-1.375-.363-2.025A5.937 5.937 0 0 0 13.6 4.263c.233-.083.467-.137.7-.163.233-.024.467-.037.7-.037 1.1 0 2.042.392 2.825 1.175C18.608 6.022 19 6.963 19 8.063s-.392 2.042-1.175 2.825c-.783.784-1.725 1.175-2.825 1.175Zm-12 6h12v-.8a.943.943 0 0 0-.137-.5.977.977 0 0 0-.363-.35c-.9-.45-1.808-.788-2.725-1.014a11.615 11.615 0 0 0-5.55 0c-.917.226-1.825.563-2.725 1.013a.97.97 0 0 0-.5.85v.8Zm6-8c.55 0 1.021-.197 1.413-.589.391-.39.587-.862.587-1.412 0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 9 6.063c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 7 8.063c0 .55.196 1.021.588 1.412.391.392.862.588 1.412.588Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgPeople;
