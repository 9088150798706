import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import {
  handleInputDataChange,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';
import Input from '../ui/Input/Input';

const SchoolEntry = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setIsButtonDisabled(true));
  }, []);

  const handleSchoolInput = (data: any) => {
    let invalid = true;
    dispatch(
      handleInputDataChange({
        schoolEntry: data,
      }),
    );
    if (Object.values(data)[0] === '') {
      setError('This field is required');
      invalid = true;
    } else {
      setError('');
      invalid = false;
    }
    dispatch(setIsButtonDisabled(invalid));
  };
  return (
    <Input
      errorMessage={error}
      isInvalid={!!error}
      label="School"
      name="customSchool"
      placeholder="School name"
      type="text"
      onChangeHandler={handleSchoolInput}
    />
  );
};

export default SchoolEntry;
