import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';
import { Listing } from '../../../utils/interfaces/RegisterStudent';
import { CoachMatch } from '../interfaces/CoachMatch';

export const useCoachMatching = (
  interests: Listing[],
  preferences: Listing[],
  personalityDescriptors: Listing[],
) => {
  const { data, error } = useSWR(`students/coaches`, interceptorFetcher, {
    revalidateOnFocus: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  if (data) {
    data?.forEach((element: CoachMatch) => {
      const filteredInterests = interests.filter(interest => {
        return element.interests.includes(interest.value);
      });
      const filteredPreferences = preferences.filter(preference => {
        return element.preferences?.includes(preference.value);
      });
      const filteredPersonalityDescriptors = personalityDescriptors.filter(
        preference => {
          return element.personality_descriptors?.includes(preference.value);
        },
      );
      element.filteredInterests = filteredInterests;
      element.filteredPreferences = filteredPreferences;
      element.filteredPersonalityDescriptors = filteredPersonalityDescriptors;
    });
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(!error && !data);
    }, 1500);
  }, [data, error]);

  return {
    coaches: data,
    // * indicator to show if no coaches are found
    isEmpty: data && data.length === 0,

    isError: error,

    isLoading,
  };
};
