import { Spacing } from '../types';

export const spacingScale: Record<Spacing, string> = {
  '.25x': 'var(--spacing-0_25x)',
  '.5x': 'var(--spacing-0_5x)',
  '2x': 'var(--spacing-2x)',
  '3x': 'var(--spacing-3x)',
  '4x': 'var(--spacing-4x)',
  '5x': 'var(--spacing-5x)',
  '6x': 'var(--spacing-6x)',
  '7x': 'var(--spacing-7x)',
  '8x': 'var(--spacing-8x)',
  x: 'var(--spacing-x)',
};

export const getSpacing = (spacing: Spacing): string => {
  return spacingScale[spacing] ?? spacingScale['2x'];
};
