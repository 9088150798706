import React, { FC, Ref } from 'react';
import cx from 'classnames';
import { InputProps } from './input.types';
import { Conditional, Typography } from '../../../../../common';

import styles from './input.module.scss';

// simple input component
// it will be expanded to replace current Input component
// and usages of html input tag
export const InputV2: FC<InputProps> = React.forwardRef((props, ref) => {
  const { className, errorMessage, invalid, label, readOnly, ...rest } = props;

  return (
    <div className={styles.container}>
      <Conditional condition={!!label}>
        <Typography text={label} />
      </Conditional>

      <input
        className={cx(
          styles.input,
          { [styles.readOnly]: readOnly, [styles.invalid]: invalid },
          className,
        )}
        readOnly={readOnly}
        ref={ref as Ref<HTMLInputElement>}
        {...rest}
      />

      <Conditional condition={!!errorMessage}>
        <Typography color="accent" text={errorMessage} variant="body2" />
      </Conditional>
    </div>
  );
});
