// Copied from https://github.com/tobyzerner/placement.js (version: 1.0.0-beta.5) instead of using module as "." in package name caused errors with tests.
// Leaving all content in single file to simplify potential future updates.
// Contents have had minor TypeScript and linting updates.

export type Options = {
  bound?: boolean;
  cap?: boolean;
  flip?: boolean;
  placement?: Placement;
};

const PROPS = {
  x: {
    End: 'Right',
    Size: 'Width',
    Start: 'Left',
    end: 'right',
    size: 'width',
    start: 'left',
  },
  y: {
    End: 'Bottom',
    Size: 'Height',
    Start: 'Top',
    end: 'bottom',
    size: 'height',
    start: 'top',
  },
} as const;

type Placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type Axis = 'x' | 'y';

function scrollParent(node: HTMLElement) {
  while (node === node.parentNode && node instanceof Element) {
    const { overflow } = getComputedStyle(node);
    if (['auto', 'scroll'].includes(overflow)) {
      return node;
    }
  }
}

export function placement(anchor: HTMLElement, overlay: HTMLElement, options: Options) {
  // Reset
  const overlayStyle = overlay.style;
  Object.assign(overlayStyle, {
    maxHeight: '',
    maxWidth: '',
    position: 'absolute',
  });

  type Side = 'top' | 'right' | 'bottom' | 'left';
  type Align = 'start' | 'end';
  // eslint-disable-next-line prefer-const
  let [side = 'bottom', align = 'center'] = (options.placement?.split('-') || []) as [] | [Side] | [Side, Align];
  const axisSide = ['top', 'bottom'].includes(side) ? 'y' : 'x';
  let oppositeSide = side === PROPS[axisSide].start ? PROPS[axisSide].end : PROPS[axisSide].start;
  const axisAlign = axisSide === 'x' ? 'y' : 'x';
  const anchorRect = anchor.getBoundingClientRect();
  const boundRect =
    scrollParent(overlay)?.getBoundingClientRect() || new DOMRect(0, 0, window.innerWidth, window.innerHeight);
  const offsetParent = overlay.offsetParent || document.body;
  const offsetParentRect =
    offsetParent === document.body
      ? // eslint-disable-next-line no-restricted-globals
        new DOMRect(-scrollX, -scrollY, window.innerWidth, window.innerHeight)
      : offsetParent.getBoundingClientRect();
  const offsetParentComputed = getComputedStyle(offsetParent);
  const overlayComputed = getComputedStyle(overlay);

  // Flip
  if (options.flip || typeof options.flip === 'undefined') {
    // Calculate the available room on either side of the anchor element. If
    // the size of the popup is more than is available on the given side, then
    // we will flip it to the side with more room.
    const room = (side: Side) => {
      return Math.abs(anchorRect[side] - boundRect[side]);
    };
    const roomThisSide = room(side);
    const overlaySize = overlay[`offset${PROPS[axisSide].Size}`];

    if (overlaySize > roomThisSide && room(oppositeSide) > roomThisSide) {
      [side, oppositeSide] = [oppositeSide, side];
    }
  }

  // Data attribute
  overlay.dataset.placement = `${side}-${align}`;

  // Cap
  if (options.cap || typeof options.cap === 'undefined') {
    const cap = (axis: Axis, room: number) => {
      const intrinsicMaxSize = overlayComputed[`max${PROPS[axis].Size}`];
      room -=
        parseInt(overlayComputed[`margin${PROPS[axis].Start}`]) + parseInt(overlayComputed[`margin${PROPS[axis].End}`]);
      if (intrinsicMaxSize === 'none' || room < parseInt(intrinsicMaxSize)) {
        overlay.style[`max${PROPS[axis].Size}`] = `${room}px`;
      }
    };

    cap(axisSide, Math.abs(boundRect[side] - anchorRect[side]));
    cap(axisAlign, boundRect[PROPS[axisAlign].size]);
  }

  // Side
  Object.assign(overlayStyle, {
    [oppositeSide]: `${
      (side === PROPS[axisSide].start
        ? offsetParentRect[PROPS[axisSide].end] - anchorRect[PROPS[axisSide].start]
        : anchorRect[PROPS[axisSide].end] - offsetParentRect[PROPS[axisSide].start]) -
      parseInt(offsetParentComputed[`border${PROPS[axisSide].Start}Width`])
    }px`,
    [side]: 'auto',
  });

  // Align
  const fromAlign = align === 'end' ? 'end' : 'start';
  const oppositeAlign = align === 'end' ? 'start' : 'end';
  const anchorAlign = anchorRect[axisAlign] - offsetParentRect[axisAlign];
  const anchorSize = anchorRect[PROPS[axisAlign].size];
  const overlaySize = overlay[`offset${PROPS[axisAlign].Size}`];

  let alignPos =
    align === 'end'
      ? offsetParentRect[PROPS[axisAlign].size] - anchorAlign - anchorSize
      : anchorAlign + (align !== 'start' ? anchorSize / 2 - overlaySize / 2 : 0);

  if (options.bound || typeof options.bound === 'undefined') {
    const factor = align === 'end' ? -1 : 1;
    alignPos = Math.max(
      factor * (boundRect[PROPS[axisAlign][fromAlign]] - offsetParentRect[PROPS[axisAlign][fromAlign]]),
      Math.min(
        alignPos,
        factor * (boundRect[PROPS[axisAlign][oppositeAlign]] - offsetParentRect[PROPS[axisAlign][fromAlign]]) -
          overlaySize,
      ),
    );
  }

  Object.assign(overlayStyle, {
    [PROPS[axisAlign][oppositeAlign]]: 'auto',
    [PROPS[axisAlign][fromAlign]]: `${
      alignPos - parseInt(offsetParentComputed[`border${PROPS[axisAlign].Start}Width`])
    }px`,
  });
}
