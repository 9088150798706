import React from 'react';
import { flexRender, RowData } from '@tanstack/react-table';
import cx from 'classnames';
import { hyphenateColumnId } from '../../helpers';
import {
  dataAttrCellIsClickable,
  dataAttrCellPrefix,
} from '../table-row/table-row.constants';
import { TableRowCellsProps } from './table-row-cells.types';
import { Conditional } from '../../../conditional';
import { Typography } from '../../../typography';
import { Icon } from '../../../icon';
import { CellReorderWrapper } from '../cell-reorder-wrapper';

import styles from './table-row-cells.module.scss';

export const TableRowCells = <TData extends RowData>(
  props: TableRowCellsProps<TData>,
) => {
  const { getCellProps, reordering, row } = props;

  return (
    <React.Fragment>
      {row.getVisibleCells().map((cell, index) => {
        const customCellProps = getCellProps?.(cell) || {};
        const { isClickable, onClick, ...restCustomCellProps } =
          customCellProps;

        const hyphenatedColumnId = hyphenateColumnId(cell.column.id);
        const dataAttrCellId = `${dataAttrCellPrefix}-${hyphenatedColumnId}`;
        const cellDataAttrs = {
          [dataAttrCellId]: '',
          [dataAttrCellIsClickable]: onClick || isClickable ? '' : undefined,
        };
        const cellProps = {
          onClick,
          ...restCustomCellProps,
          ...cellDataAttrs,
        };

        const CellIndex = (
          <Typography align="center" text={String(row.index + 1)} />
        );

        return (
          <td key={cell.id} {...cellProps} className={cx('td', styles.td)}>
            <CellReorderWrapper
              FirstColumnContent={
                <div className={styles.rowWrapper}>
                  <Conditional
                    condition={!!reordering?.isActive}
                    Fallback={CellIndex}
                  >
                    <Icon name="actions:three_dots" />
                  </Conditional>
                </div>
              }
              FirstColumnFallback={CellIndex}
              index={index}
              reordering={reordering}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </CellReorderWrapper>
          </td>
        );
      })}
    </React.Fragment>
  );
};
