import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgMute = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.662 11.032c0 .778-.13 1.527-.367 2.225l-1.123-1.123c.074-.363.11-.732.11-1.102v-1.38a.69.69 0 1 1 1.38 0v1.38Zm-6.897 5.517a5.486 5.486 0 0 0 3.051-.92l.99.992a6.86 6.86 0 0 1-3.351 1.273v2.794h4.138a.69.69 0 0 1 0 1.38H3.937a.69.69 0 0 1 0-1.38h4.138v-2.794a6.897 6.897 0 0 1-6.207-6.862v-1.38a.69.69 0 1 1 1.38 0v1.38a5.518 5.518 0 0 0 5.517 5.517Zm4.138-12.415v6.73l-1.38-1.379v-5.35a2.759 2.759 0 0 0-5.513-.163L4.845 2.806a4.14 4.14 0 0 1 8.058 1.328Z"
        fill={color}
      />
      <path
        d="m10.815 14.628-1.032-1.032a2.758 2.758 0 0 1-3.777-2.565v-1.21l-1.38-1.38v2.59a4.138 4.138 0 0 0 6.189 3.597ZM0 1.864l16.553 16.553.977-.977L.977.887 0 1.864Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgMute;
