import axiosInstance from "../../../utils/helpers/http";

export const deleteStudent = async () => {
  try {
    return await axiosInstance.delete(`/students`);
  } catch (err) {
    // refactor it
    console.log("No user found");
    
  }
};
