import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Typography } from '../typography';
import { getLocalTimezone } from '../../utils/helpers/date';
import { CalendarWrapperProps } from './calendar-wrapper.types';

import styles from './calendar-wrapper.module.scss';

export const CalendarWrapper: FC<
  PropsWithChildren<CalendarWrapperProps>
> = props => {
  const { noToolbar } = props;

  return (
    <div className={cx(styles.container, { [styles.noToolbar]: noToolbar })}>
      <Typography
        marginBottom="x"
        text={getLocalTimezone()}
        textStyle="bold"
        variant="caption"
      />

      {props.children}
    </div>
  );
};
