import NumberFormat from 'react-number-format';
import { Fragment, ReactNode } from 'react';

import styles from './MaskedInput.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  format: string;
  label: string;
  mask: string;
  name: string;
  onChange: (data: any) => void;
  placeholder?: string;
  tabIndex?: number;
};

const MaskedInput = ({
  children,
  className,
  defaultValue,
  disabled,
  error,
  format,
  label,
  mask,
  name,
  onChange,
  placeholder,
  tabIndex,
}: Props) => {
  return (
    <Fragment>
      <div className={styles.container}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>

        <NumberFormat
          className={`${styles.maskedInput} ${className} ${
            error ? styles.invalid : ''
          }`}
          defaultValue={defaultValue || ''}
          disabled={disabled}
          format={format}
          mask={mask}
          name={name}
          placeholder={placeholder}
          tabIndex={tabIndex}
          onValueChange={value => {
            return onChange({ [name!]: value.formattedValue });
          }}
        />
        {children}
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </Fragment>
  );
};

export default MaskedInput;
