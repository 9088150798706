import styles from './tabs.module.scss';

type Props = {
  active: string;
  onTabChange: (tab: string) => void;
  tab1: string;
  tab2?: string;
  tab3?: string;
};

const Tabs = ({ active, onTabChange, tab1, tab2, tab3 }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          className={`${styles.tabLabel}  ${
            active === tab1 ? styles.active : ''
          }`}
          onClick={() => {
            return onTabChange(tab1);
          }}
        >
          {tab1}
        </div>

        {tab2 ? (
          <div
            className={`${styles.tabLabel}  ${
              active === tab2 ? styles.active : ''
            }`}
            onClick={() => {
              return onTabChange(tab2);
            }}
          >
            {tab2}
          </div>
        ) : (
          ''
        )}

        {tab3 ? (
          <div
            className={`${styles.tabLabel}  ${
              active === tab3 ? styles.active : ''
            }`}
            onClick={() => {
              return onTabChange(tab3);
            }}
          >
            {tab3}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Tabs;
