import axiosInstance from "../../../utils/helpers/http";

export const createSessionNote = async (
  sessionTimeslotId: string,
  note: string
) => {
  return axiosInstance.post(
    `session-timeslots/${sessionTimeslotId}/notes`,
    {
      note,
    }
  );
};
