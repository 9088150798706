import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import EmptyListing from '../../../general/components/empty-listing/empty-listing';
import UserLayout from '../../../general/components/user-layout/user-layout';
import { fetchArchivedConversations } from '../../api/fetchArchivedConversations';
import { fetchStudentConversations } from '../../api/useFetchConversationsByStudent';
import { ConversationListingItem } from '../../interfaces/ConversationListingItem';
import ConversationsListingCard from '../ConversationsListingCard/ConversationsListingCard';

import emptyConversation from '../../../../assets/icons/EmptyListing/emptyNotes.svg';
import Loading from '../../../general/components/loading/loading';

import styles from './StudentConversationsOverview.module.scss';

const StudentConversationsOverview = () => {
  const { studentId } = useParams();
  const [conversations, setConversations] = useState<any>();
  // const [student, setStudent] = useState<Student | undefined>();
  const [studentName, setStudentName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlSegment = window.location.href.split('/').pop();

  const handleArchivedConversations = async () => {
    const result = await fetchArchivedConversations(studentId as string);
    setStudentName(`${result.data.name} ${result.data.surname}`);
    setConversations(result.data.conversations);
  };

  const handleOngoingConversations = async () => {
    const result = await fetchStudentConversations(studentId as string);
    setStudentName(`${result.data.name} ${result.data.surname}`);
    setConversations(result.data.conversations);
  };

  const handleOngoingConversation = () => {
    handleOngoingConversations();
  };

  useEffect(() => {
    if (conversations && conversations.length) {
      setIsLoading(false);
    }
  }, [conversations]);

  useEffect(() => {
    !!urlSegment && urlSegment === 'archived'
      ? handleArchivedConversations()
      : handleOngoingConversation();
  }, [urlSegment]);

  return (
    <UserLayout
      isConversationTab={urlSegment !== 'archived'}
      title={studentName}
    >
      <div className={styles.listing}>
        {!isLoading ? (
          conversations
            .sort((a: ConversationListingItem, b: ConversationListingItem) => {
              return (
                (new Date(b.created_at as string) as any) -
                (new Date(a.created_at as string) as any)
              );
            })
            .map((element: ConversationListingItem, index: number) => {
              return (
                <Link
                  key={index}
                  to={
                    urlSegment !== 'archived'
                      ? `/coach/students/${studentId}/${studentName}/conversations/${element.conversation_id}`
                      : `/coach/students/${studentName}/conversations/${element.conversation_id}/archived`
                  }
                >
                  <ConversationsListingCard conversation={element} />
                </Link>
              );
            })
        ) : <Loading /> ? (
          !isLoading && conversations.length === 0
        ) : (
          <EmptyListing
            image={emptyConversation}
            subtitle="Your conversation will appear here"
            title="No conversations found"
          />
        )}
      </div>
    </UserLayout>
  );
};

export default StudentConversationsOverview;
