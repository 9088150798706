import React, { FC } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { MenuItemProps } from './menu-item.props';
import { MenuItemPresets } from './menu-item.presets';
import { Typography } from '../../../typography';

import styles from './menu-item.module.scss';

export const MenuItem: FC<MenuItemProps> = props => {
  const {
    children,
    className,
    href,
    icon,
    onClick,
    onMouseDown,
    selected,
    text,
    textProps,
    tx,
  } = props;

  const content = (
    <div
      className={cx(
        styles.container,
        {
          [styles.selected]: selected,
          [styles.textItem]: !!text || !!tx,
          [styles.multiColumn]: !!icon,
          [styles.link]: !!href,
        },
        className,
      )}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {text || tx ? <Typography text={text} {...textProps} /> : children}
    </div>
  );

  return <div>{href ? <Link to={href}>{content}</Link> : content}</div>;
};

MenuItem.defaultProps = MenuItemPresets;
