import { FC } from 'react';
import cx from 'classnames';
import { variantMappings } from './typography.contants';
import { extractSpacingProps } from '../../helpers/spacing';
import { TypographyProps } from './typography.types';

import styles from './typography.module.scss';

export const Typography: FC<TypographyProps> = props => {
  const {
    align,
    className,
    color,
    decoration,
    lineHeight,
    onClick,
    style,
    text,
    textStyle,
    transform,
    variant,
    ...others
  } = props;

  const Element = (variantMappings[variant] ||
    'p') as keyof JSX.IntrinsicElements;

  return (
    <Element
      className={cx(
        styles.base,
        styles[color],
        styles[variant],
        {
          [styles.bold]: textStyle === 'bold',
          [styles.clickable]: !!onClick,
        },
        className,
      )}
      style={{
        ...style,
        lineHeight,
        textAlign: align,
        textDecoration: decoration,
        textTransform: transform,
        ...extractSpacingProps(others),
      }}
      onClick={onClick}
    >
      {text}
    </Element>
  );
};
