import axiosInstance from "../../../utils/helpers/http";

export const fetchIcal = async (sessionId: string) => {
  try {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Coaching session event";
    return await axiosInstance
      .get(
        `${process.env.REACT_APP_TRULA_API_BASE_URL}/session-timeslots/${sessionId}/ical`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: "text/calendar;charset=utf-8" })
        );
        link.click();
      });
  } catch (error) {}
};
