import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgFunnel = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.666 10.834v5c0 .236-.08.434-.24.593a.807.807 0 0 1-.594.24H9.166a.804.804 0 0 1-.594-.24.804.804 0 0 1-.24-.593v-5L3.5 4.667c-.208-.277-.24-.57-.093-.875.145-.305.399-.458.76-.458h11.666c.362 0 .615.153.761.458.146.306.114.598-.094.875l-4.833 6.167Zm-1.667-.583L14.124 5h-8.25L10 10.25Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgFunnel;
