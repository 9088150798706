import React, { FC, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import useSWR from 'swr';
import { EventInput } from '@fullcalendar/core';
import { renderEventContent } from '../../../../../../helpers/full-calendar';
import { CalendarWrapper } from '../../../../../../common';
import { StudentSessionsProps } from './student-sessions.types';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';
import { CoachSessionTimeslotCard } from '../../../../../coach/interfaces/CoachSessionTimeslotCard';
import {
  addMinutes,
  convertTimeInMillisecondsToLocalDate,
} from '../../../../../../utils/helpers/date';

export const StudentSessions: FC<StudentSessionsProps> = props => {
  const { student } = props;

  const { data } = useSWR(
    `admin/student/session-timeslots?studentId=${student?.id}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const events = useMemo(() => {
    if (data) {
      return data?.map((session: CoachSessionTimeslotCard) => {
        const localDate = convertTimeInMillisecondsToLocalDate(
          session.session_date,
        );

        const inThePast = +session.session_date < Date.now();
        const color = inThePast ? '#ec6359' : '#73be76';

        return {
          backgroundColor: color,
          borderColor: color,
          display: 'auto',
          end: addMinutes(localDate, 30),
          extendedProps: session,
          id: session.id,
          start: localDate,
          title: student.coachName,
        } as EventInput;
      });
    }

    return [];
  }, [data]);

  return (
    <CalendarWrapper>
      <FullCalendar
        allDaySlot={false}
        businessHours={false}
        eventContent={renderEventContent}
        eventDurationEditable={false}
        events={events}
        headerToolbar={{
          center: 'title',
          left: 'prev,next',
          right: 'timeGridWeek,listWeek',
        }}
        height="calc(100vh - 250px)"
        initialView="listWeek"
        noEventsText="No sessions yet"
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
        scrollTime={null}
        scrollTimeReset={false}
        selectable
        selectOverlap
        unselectAuto={false}
        views={{
          listWeek: { buttonText: 'List', displayEventTime: true },
          timeGridWeek: {
            buttonText: 'Calendar',
            displayEventTime: false,
          },
        }}
      />
    </CalendarWrapper>
  );
};
