import React, { FC } from 'react';
import { DividerProps } from './divider.types';
import { DividerPresets } from './divider.presets';
import { getThemeColor } from '../../utils/theme';

export const Divider: FC<DividerProps> = props => {
  const { color, height, width } = props;

  return (
    <div
      style={{
        backgroundColor: getThemeColor(color),
        height,
        width,
      }}
    />
  );
};

Divider.defaultProps = DividerPresets;
