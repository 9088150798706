import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { CoachCapacityProps } from './coach-capacity.types';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { capacityFormSchema } from './capacity-form.schema';
import { Button, Flex, Form } from '../../../../../../common';
import axiosInstance from '../../../../../../utils/helpers/http';

export const CoachCapacity: FC<CoachCapacityProps> = props => {
  const { coach, onHideDrawer, onRefresh } = props;

  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      id: coach.id,
      new_students_capacity: coach.new_students_capacity,
      weekly_capacity: coach.weekly_capacity,
    },
    resolver: yupResolver(capacityFormSchema),
  });

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.post('coaches/weekly-capacity', data);
      toast.success('Capacity saved successfully');
      onRefresh?.();
      onHideDrawer?.();
    } catch {
      toast.error('An error occurred while saving capacity');
    }
  };

  return (
    <Form
      Footer={
        <Button iconPosition="right" text="Save Capacity" type="submit" />
      }
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Flex direction="column" gap="2x">
        <InputV2
          invalid={!!formState.errors?.new_students_capacity}
          label="New students capacity"
          placeholder="New students capacity"
          type="number"
          {...register('new_students_capacity', {
            valueAsNumber: true,
          })}
          errorMessage={formState.errors?.new_students_capacity?.message}
        />

        <InputV2
          invalid={!!formState.errors?.weekly_capacity}
          label="Weekly capacity"
          placeholder="Weekly capacity"
          type="number"
          {...register('weekly_capacity', {
            valueAsNumber: true,
          })}
          errorMessage={formState.errors?.weekly_capacity?.message}
        />
      </Flex>
    </Form>
  );
};
