import useSWR from 'swr';
import axiosInstance from '../../../utils/helpers/http';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useFetchStudent = (coacheeId: string | null) => {
  const { data, error } = useSWR(
    coacheeId ? `coaches/student/${coacheeId}` : null,
    interceptorFetcher,
    { revalidateOnFocus: false },
  );

  return {
    isError: error,
    isLoading: !error && !data,
    studentData: data,
  };
};

export const fetchStudent = async (studentId: string) => {
  const result = await axiosInstance.get(`coaches/student/${studentId}`);
  try {
    return result.data;
  } catch (err) {
    console.error(err);
  }
};
