import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgBell = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.666 9.833h1.667V5.667H6.666v4.166Zm.834 2.5c.236 0 .434-.08.594-.24.16-.16.239-.357.239-.593a.808.808 0 0 0-.24-.594.808.808 0 0 0-.593-.24.805.805 0 0 0-.594.24.807.807 0 0 0-.24.594c0 .236.08.434.24.593.16.16.358.24.594.24Zm-6.667 2.5v-1.666H2.5V7.333c0-1.152.347-2.177 1.041-3.073A4.807 4.807 0 0 1 6.25 2.5v-.583c0-.348.121-.643.365-.885.242-.244.537-.365.885-.365.347 0 .642.121.885.365.243.242.365.537.365.885V2.5a4.807 4.807 0 0 1 2.708 1.76A4.881 4.881 0 0 1 12.5 7.333v5.834h1.666v1.666H.833Zm6.667 2.5c-.459 0-.85-.163-1.177-.489a1.605 1.605 0 0 1-.49-1.177h3.333c0 .458-.163.85-.489 1.177-.326.326-.719.49-1.177.49Zm-3.334-4.166h6.667V7.333a3.21 3.21 0 0 0-.98-2.354A3.21 3.21 0 0 0 7.5 4a3.21 3.21 0 0 0-2.354.98 3.21 3.21 0 0 0-.98 2.353v5.834Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgBell;
