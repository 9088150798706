import { EventInput } from '@fullcalendar/core';
import { TimeslotUpdate } from '../../../interfaces/UpdateTimeslot';
import { Timeslot } from '../../../../general/interfaces/Timeslot';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const dayNameToNumber = (dayName: string): number => {
  return days.indexOf(dayName);
};

export const numberToDayName = (day: number): string => {
  return days[day];
};

export const convertEventsToTimeslotsInUtc = (
  events: EventInput[],
): TimeslotUpdate[] => {
  return events.map(event => {
    return {
      end_time: (event.end as Date).getTime(),
      start_time: (event.start as Date).getTime(),
    } as TimeslotUpdate;
  });
};

export const findOverlapBackgroundEvent = (
  event: EventInput,
  timeslots: any[],
) => {
  return timeslots?.find((slot: Timeslot) => {
    return (event.start as Date).getTime() === +slot.start_time;
  });
};
