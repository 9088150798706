import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

/* Aliasing the functions by adding types and
  exporting hook variables that have the right 
  ts types, which are going to be used through
  our application instead of importing the base
  functions from react redux.
  ex:
  const theme = useAppSelector((state) => state.global.theme);
  const dispatch = useAppDispatch();
  */
export const useAppDispatch = () => {
  return useDispatch<AppDispatch>();
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
