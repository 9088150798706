import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgGroup = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.143 10.615a2.615 2.615 0 0 0 2.726 2.613l.288.066c.765.172 1.848.416 2.281.985.432.567.734 1.344.756 2.378a.192.192 0 0 1-.194.195h-2.628a.402.402 0 0 0 0 .804h3.208a.402.402 0 0 0 .402-.373c.106-1.493-.262-2.65-.904-3.492-.383-.504-1.11-.816-1.82-1.033a2.615 2.615 0 1 0-4.114-2.143Zm.805 0a1.81 1.81 0 1 0 3.62 0 1.81 1.81 0 0 0-3.62 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.758 12.226a1.61 1.61 0 1 1 0-3.221 1.61 1.61 0 0 1 0 3.22Zm-2.815-1.61a2.815 2.815 0 0 0 2.906 2.813c.082.02.171.04.263.06.385.087.835.188 1.241.337.412.15.746.337.926.574.403.53.692 1.26.715 2.252h-2.622a.602.602 0 1 0 0 1.204h3.208a.602.602 0 0 0 .601-.56c.11-1.534-.269-2.74-.944-3.626-.356-.468-.963-.773-1.568-.987a2.815 2.815 0 1 0-4.726-2.068Zm4.507 1.993a2.615 2.615 0 1 0-1.582.619l.289.066c.765.172 1.848.416 2.281.985.432.567.734 1.344.756 2.378a.192.192 0 0 1-.194.195h-2.628a.402.402 0 0 0 0 .804h3.208a.402.402 0 0 0 .402-.373c.106-1.493-.262-2.65-.904-3.492-.341-.448-.955-.745-1.586-.957a8.375 8.375 0 0 0-.235-.076c.067-.046.131-.096.193-.149Zm-1.692-.183a1.81 1.81 0 1 1 0-3.621 1.81 1.81 0 0 1 0 3.62Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.784 12.116A4.457 4.457 0 0 0 12.208 4a4.457 4.457 0 0 0-2.546 8.116c-1.203.369-2.432.9-3.083 1.754-1.093 1.436-1.721 3.406-1.54 5.95a.686.686 0 0 0 .684.637h13c.36 0 .658-.278.684-.637.181-2.544-.447-4.514-1.54-5.95-.65-.854-1.88-1.385-3.083-1.754Zm-2.561.798h.015c.054 0 .109 0 .163-.003.15.036.314.073.487.112 1.303.293 3.15.71 3.888 1.678.736.967 1.25 2.29 1.288 4.053a.327.327 0 0 1-.33.332H6.712a.327.327 0 0 1-.33-.332c.038-1.763.552-3.086 1.288-4.053.738-.969 2.585-1.385 3.888-1.678.174-.04.337-.076.487-.112.054.002.109.003.163.003h.015Zm0-1.371a3.086 3.086 0 0 1 0-6.172 3.086 3.086 0 0 1 0 6.172Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.157 10.615a2.615 2.615 0 0 1-2.726 2.613l-.288.066c-.765.172-1.848.416-2.281.985-.432.567-.734 1.344-.756 2.378a.192.192 0 0 0 .194.195h2.628a.402.402 0 0 1 0 .804H.72a.402.402 0 0 1-.402-.373c-.106-1.493.262-2.65.904-3.492.384-.504 1.11-.816 1.82-1.033a2.615 2.615 0 1 1 4.114-2.143Zm-.805 0a1.81 1.81 0 1 1-3.62 0 1.81 1.81 0 0 1 3.62 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.542 12.226a1.61 1.61 0 1 0 0-3.221 1.61 1.61 0 0 0 0 3.22Zm2.815-1.61a2.815 2.815 0 0 1-2.906 2.813c-.082.02-.171.04-.263.06-.385.087-.835.188-1.241.337-.412.15-.746.337-.926.574-.403.53-.692 1.26-.715 2.252h2.622a.602.602 0 0 1 0 1.204H.72a.602.602 0 0 1-.601-.56c-.11-1.534.269-2.74.944-3.626.356-.468.963-.773 1.568-.987a2.815 2.815 0 1 1 4.726-2.068ZM2.85 12.608a2.615 2.615 0 1 1 1.582.619l-.289.066c-.765.172-1.848.416-2.281.985-.432.567-.734 1.344-.756 2.378a.192.192 0 0 0 .194.195h2.628a.402.402 0 0 1 0 .804H.72a.402.402 0 0 1-.402-.373c-.106-1.493.262-2.65.904-3.492.341-.448.955-.745 1.586-.957a8.4 8.4 0 0 1 .235-.076 2.615 2.615 0 0 1-.193-.149Zm1.692-.183a1.81 1.81 0 1 0 0-3.621 1.81 1.81 0 0 0 0 3.62Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgGroup;
