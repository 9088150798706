import { RowData } from '@tanstack/react-table';
import React from 'react';
import {
  Button,
  Conditional,
  Divider,
  Icon,
  Table,
  Typography,
  WrapperMenu,
} from '../../../../common';
import { InputV2 } from '../../../register-coachee/components/ui/Input-v2';
import { EntitySectionProps } from './entity-section.types';
import { EntitySectionPresets } from './entity-section.presets';
import { Menu, MenuItem } from '../../../../common/menu';

import styles from './entity-section.module.scss';

export const EntitySection = <TData extends RowData>(
  props: EntitySectionProps<TData>,
) => {
  const {
    columns,
    data,
    getRowRibbonColor,
    isRowHighlighted,
    name,
    onCreate,
    onSearch,
    onShowDetails,
    onSort,
    pluralName,
    showCreateBtn,
    sortOptions,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography text={pluralName} variant="title2" />

        <InputV2
          className={styles.input}
          placeholder={`Search ${pluralName}`}
          onChange={event => {
            return onSearch?.(event.target.value);
          }}
        />

        <Conditional condition={showCreateBtn}>
          <Button
            icon={<Icon name="actions:plus" />}
            text={`Add ${name}`}
            onClick={onCreate}
          />
        </Conditional>
      </div>

      <div className={styles.filters}>
        <Typography
          color="secondary"
          text={`${data?.length || 0} ${
            data?.length === 1 ? name : pluralName
          }`}
          transform="lowercase"
          variant="subtitle3"
        />

        <Divider color="gray-100" height="40px" width="1px" />

        <Conditional
          condition={sortOptions?.length > 0}
          Fallback={
            <Button
              backgroundColor="white"
              icon={<Icon name="actions:funnel" />}
              iconPosition="right"
              text="Sort"
            />
          }
        >
          <WrapperMenu
            Content={
              <Button
                backgroundColor="white"
                icon={<Icon name="actions:funnel" />}
                iconPosition="right"
                text="Sort"
              />
            }
            Menu={
              <Menu headerText="Sort by">
                {sortOptions?.map(option => {
                  return (
                    <MenuItem
                      key={option.value}
                      text={option.label}
                      onClick={() => {
                        return onSort?.(option.value);
                      }}
                    />
                  );
                })}
              </Menu>
            }
            offsetX="-90px"
            offsetY="95px"
          />
        </Conditional>
      </div>

      <div className={styles.content}>
        <Table
          columns={columns}
          data={data || []}
          getRowRibbonColor={getRowRibbonColor}
          isRowHighlighted={isRowHighlighted}
          onRowClick={record => {
            onShowDetails(record);
          }}
        />
      </div>
    </div>
  );
};

EntitySection.defaultProps = EntitySectionPresets;
