import { useState } from "react";
import calendar from "../../../../../assets/icons/CoachDashboard/calendar.svg";
import group from "../../../../../assets/icons/CoachDashboard/group.svg";
import chat from "../../../../../assets/icons/CoachDashboard/chat.svg";
import { Dashboard } from "../../../utils/constants/DashboardConstants";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setDashboardState } from "../../../store/coachSlice";

import styles from "./BottomMenu.module.scss";

type Props = {
  onTabSelect: (tab: string) => void;
};

const BottomMenu = ({ onTabSelect }: Props) => {
  const selectedTab = useAppSelector((state) => {return state.coach.dashboardState});
  const dispatch = useAppDispatch();

  //* Renders
  const calendarIconRender = (
    <div
      className={` ${
        selectedTab === Dashboard.tabs.calendar
          ? ` ${styles.icon} ${styles.selected}`
          : `${styles.icon}`
      }`}
      onClick={() => {
        dispatch(setDashboardState(Dashboard.tabs.calendar));
        onTabSelect(Dashboard.tabs.calendar);
      }}
    >
      <img
        alt={Dashboard.tabs.calendar}
        className={styles.iconImage}
        src={calendar}
      />
    </div>
  );
  const groupsIconRender = (
    <div
      className={` ${
        selectedTab === Dashboard.tabs.students
          ? `${styles.icon} ${styles.selected}`
          : `${styles.icon}`
      }`}
      onClick={() => {
        dispatch(setDashboardState(Dashboard.tabs.students));
        onTabSelect(Dashboard.tabs.students);
      }}
    >
      <img alt={Dashboard.tabs.chat} className={styles.iconImage} src={group} />
    </div>
  );
  const chatIconRender = (
    <div
      className={` ${
        selectedTab === Dashboard.tabs.chat
          ? `${styles.icon} ${styles.selected} `
          : `${styles.icon}`
      }`}
      onClick={() => {
        dispatch(setDashboardState(Dashboard.tabs.chat));
        onTabSelect(Dashboard.tabs.chat);
      }}
    >
      <img alt={Dashboard.tabs.chat} className={styles.iconImage} src={chat} />
    </div>
  );

  return (
    <div className={styles.container}>
      {calendarIconRender}
      {groupsIconRender}
      {chatIconRender}
    </div>
  );
};

export default BottomMenu;
