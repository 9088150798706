import useSWR from 'swr';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useSessionsFetch = (timeFrame?: string) => {
  const { data, error } = useSWR(
    timeFrame,
    () =>
      {return interceptorFetcher(
        `coaches/session-timeslots?date=${Date.now()}&list=${timeFrame}`,
      )},
    { revalidateOnFocus: false },
  );

  return {
    isEmpty: data && data.length === 0,
    isError: error,
    isLoading: !error && !data,
    sessions: data,
  };
};
