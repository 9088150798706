import axiosInstance from "../../../utils/helpers/http";
import { Theme } from "../interfaces/Theme";

export const updateSessionThemes = async (
  themes: Theme[] | undefined,
  sessionId: string
) => {
  return axiosInstance.post(`session-timeslots/${sessionId}/themes`, {
    //* API expects a list of ids instead of objects
    themes: themes?.map((theme) => {return theme.id}),
  });
};
