import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { handlePrefferedCommunication } from '../../store/registerStudentSlice';
import { ExtendableListing } from '../../../../utils/interfaces/RegisterStudent';
import SelectableCard from '../ui/SelectableCard/SelectableCard';

import styles from './PreferredCommunication.module.scss';

type Props = {
  data: any;
  isRegister?: boolean;
  listing: ExtendableListing;
  onChangeHandler?: (data: any) => void;
};

const PreferredCommunication = ({
  data,
  isRegister,
  listing,
  onChangeHandler,
}: Props) => {
  const dispatch = useAppDispatch();
  const [preferredCommunicationSelection, setPreferredCommunicationSelection] =
    useState(Object.keys(data)[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle a selection of a card
  const handleSelect = (value: string, isSelected: boolean) => {
    if (value === preferredCommunicationSelection && !isSelected) {
      setPreferredCommunicationSelection('');
    }
    if (isSelected && preferredCommunicationSelection !== value) {
      setPreferredCommunicationSelection(value);
    } else if (preferredCommunicationSelection === value && isSelected) {
      setPreferredCommunicationSelection(value);
    }
  };

  // Send it to the wizard
  useEffect(() => {
    if (isRegister) {
      if (preferredCommunicationSelection === undefined) {
        dispatch(handlePrefferedCommunication({}));
      } else if (preferredCommunicationSelection !== '') {
        dispatch(
          handlePrefferedCommunication({
            [preferredCommunicationSelection]: true,
          }),
        );
      } else {
        dispatch(handlePrefferedCommunication({}));
      }
    } else if (preferredCommunicationSelection === '') {
      onChangeHandler!({
        preferredCommunication: {},
      });
    } else {
      onChangeHandler!({
        preferredCommunication: {
          [preferredCommunicationSelection]: true,
        },
      });
    }
  }, [preferredCommunicationSelection, dispatch]);

  return (
    <div
      className={styles.container}
      data-testid="preferredCommunication-container"
    >
      {isRegister
        ? listing.predefined.map(option => {
            return (
              option.value !== 'sms' && (
                <SelectableCard
                  key={option.value}
                  label={option.label}
                  selected={option.value === preferredCommunicationSelection}
                  value={option.value}
                  onSelect={handleSelect}
                />
              )
            );
          })
        : listing.predefined.map(option => {
            return (
              <SelectableCard
                key={option.value}
                label={option.label}
                selected={option.value === preferredCommunicationSelection}
                value={option.value}
                onSelect={handleSelect}
              />
            );
          })}
    </div>
  );
};

export default PreferredCommunication;
