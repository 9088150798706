import { useState } from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

type Props = {
  className?: string;
  id?: string;
  isDisabled?: boolean;
  label: string | undefined;
  onClickHandler?: () => void;
  type?: 'submit' | 'button' | 'reset';
};

const Button = ({
  className,
  id,
  isDisabled,
  label,
  onClickHandler,
  type,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <button
      className={
        // todo: This needs to be cleaned up, check every usage of this component
        cx(styles.button, styles[className], className, {
          [styles.disabled]: isLoading || isDisabled,
        })
      }
      data-testid="continue"
      disabled={isLoading || isDisabled}
      id={id}
      type={type}
      onClick={async () => {
        if (!onClickHandler) {
          return;
        }

        if (!isLoading) {
          setIsLoading(true);
          await onClickHandler();
          setIsLoading(false);
        }
      }}
    >
      {label}
    </button>
  );
};

export default Button;
