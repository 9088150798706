import { useCallback, useEffect, useState } from 'react';
import { DatesSetArg } from '@fullcalendar/core';
import { CoachingTimeslot } from '../interfaces/CoachingTimeslot';
import axiosInstance from '../../../utils/helpers/http';
import { firstDateOfWeek, lastDateOfWeek } from '../../../utils/helpers/date';

export type LoadingStatus = 'NOT_LOADED' | 'LOADING' | 'LOADED' | 'ERROR';

export const useCoachAvailableTimeslots = () => {
  const [from, setFrom] = useState<string>(null);
  const [to, setTo] = useState<string>(null);
  const [timeslots, setTimeslots] = useState([]);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState<LoadingStatus>('NOT_LOADED');

  const fetchTimeslots = useCallback(async (from: number, to: number) => {
    setStatus('LOADING');

    try {
      const data = await axiosInstance
        .get<CoachingTimeslot[]>(
          `coaches/available-timeslots?from=${
            from || firstDateOfWeek().getTime()
          }&to=${to || lastDateOfWeek().getTime()}`,
        )
        .then(res => {
          return res.data;
        });

      setTimeslots(data);
      setStatus('LOADED');

      return data;
    } catch {
      setStatus('ERROR');
      setIsError(true);
    }
  }, []);

  const loadTimeslotsFromPreviousWeekWithTimeslots = useCallback(async () => {
    setStatus('LOADING');

    try {
      const data = await axiosInstance
        .post<CoachingTimeslot[]>(
          `coaches/load-latest-timeslots?from=${
            from ? new Date(from).getTime() : firstDateOfWeek().getTime()
          }&to=${to ? new Date(to).getTime() : lastDateOfWeek().getTime()}`,
        )
        .then(res => {
          return res.data;
        });

      setTimeslots(data);
      setStatus('LOADED');

      return data;
    } catch {
      setStatus('ERROR');
      setIsError(true);
    }
  }, [from, to]);

  const handleDatesSet = ({ end, start }: DatesSetArg) => {
    setFrom(start.toISOString());
    setTo(end.toISOString());
  };

  useEffect(() => {
    if (!from || !to) {
      return;
    }

    fetchTimeslots(new Date(from).getTime(), new Date(to).getTime());
  }, [from, to]);

  return {
    from,
    isError,
    loadTimeslotsFromPreviousWeekWithTimeslots,
    onDatesSet: handleDatesSet,
    status,
    timeslots,
    to,
  };
};
