import React, { FC } from 'react';
import { Divider, Flex, Typography } from '../../../../../../common';
import { DetailsRow } from '../../../details-row';
import { DetailsFormProps } from './details-form.types';

export const DetailsForm: FC<DetailsFormProps> = props => {
  const { admin } = props;

  return (
    <div>
      <Typography
        marginBottom="x"
        text="About"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginTop="4x">
        <DetailsRow label="Email" value={admin.email} />
        <DetailsRow label="Phone" value={admin.phone_number} />
      </Flex>
    </div>
  );
};
