import { IconProps } from './icon.types';

export const IconPresets: Partial<IconProps> = {
  color: 't1',
  cursor: 'pointer',
  height: '24px',
  strokeColor: 't2',
  variant: 'icon',
  width: '24px',
};
