import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import {
  handleInputDataChange,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';
import { regExpressions } from '../../utils/constants/RegisterStudent';
import { IParentalConsent } from '../../../../utils/interfaces/RegisterStudent';
import Input from '../ui/Input/Input';
import MaskedInput from '../ui/MaskedInput/MaskedInput';

import styles from './ParentalConsent.module.scss';

const ParentalConsent = ({ data }: IParentalConsent) => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState({
    email: '',
    phoneNo: '',
  });

  useEffect(() => {
    dispatch(setIsButtonDisabled(true));
    window.scrollTo(0, 0);
  }, []);

  // TODO: rework it
  useEffect(() => {
    if (data.email === '' && data.phoneNo === '') {
      setErrors({ email: '', phoneNo: '' });
      return;
    }
    if (data.email !== '') {
      if (data.email.match(regExpressions.email)) {
        setErrors(prevState => {
          const update = { ...prevState };
          update.email = '';
          return update;
        });
      } else {
        setErrors(prevState => {
          const update = { ...prevState };
          update.email = 'Please enter a valid email';
          return update;
        });
      }
    } else {
      setErrors(prevState => {
        const update = { ...prevState };
        update.email = '';
        return update;
      });
    }

    if (data.phoneNo !== '') {
      if (data.phoneNo.match(regExpressions.phoneNo)) {
        setErrors(prevState => {
          const update = { ...prevState };
          update.phoneNo = '';
          return update;
        });
      } else {
        setErrors(prevState => {
          const update = { ...prevState };
          update.phoneNo = 'Please enter a valid phone number';
          return update;
        });
      }
    } else {
      setErrors(prevState => {
        const update = { ...prevState };
        update.phoneNo = '';
        return update;
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      errors.email === '' &&
      errors.phoneNo === '' &&
      (data.email !== '' || data.phoneNo !== '')
    ) {
      dispatch(setIsButtonDisabled(false));
    } else {
      dispatch(setIsButtonDisabled(true));
    }
  }, [errors]);

  const handleDataChange = (inputData: any) => {
    if (Object.keys(inputData)[0] === 'phoneNo') {
      inputData.phoneNo = inputData.phoneNo.replace(/-/g, '');
    }
    dispatch(
      handleInputDataChange({
        parentalConsent: inputData,
      }),
    );
  };
  return (
    <div className={styles.container} data-testid="parental-client-id">
      <Input
        defaultValue={data ? data.email : ''}
        disabled={data.phoneNo !== ''}
        errorMessage={errors.email}
        isInvalid={!!errors.email}
        label="Email"
        name="email"
        placeholder="email@domain.com"
        type="email"
        onChangeHandler={handleDataChange}
      />
      <div className={styles.separator}>OR</div>

      {window.location.host === 'localhost:3000' ||
      window.location.host === 'test.trula.com' ? (
        <Input
          defaultValue={data ? data.phoneNo : ''}
          errorMessage={errors.phoneNo}
          isInvalid={!!errors.phoneNo}
          label="Phone number"
          name="phoneNo"
          placeholder="XXX-XXX-XXXX"
          tabIndex={7}
          type="text"
          onChangeHandler={handleDataChange}
        />
      ) : (
        <MaskedInput
          className={styles.phoneNo}
          defaultValue={data ? data.phoneNo : ''}
          disabled={data.email !== ''}
          error={errors.phoneNo}
          format="+1-###-###-####"
          label="Phone number"
          mask="_"
          name="phoneNo"
          placeholder="XXX-XXX-XXXX"
          tabIndex={6}
          onChange={handleDataChange}
        />
      )}
    </div>
  );
};

export default ParentalConsent;
