export const exportData = [
  {
    label: "Coachees",
    value: "students",
  },
  {
    label: "Coaches",
    value: "coaches",
  },
  {
    label: "Sessions",
    value: "sessions",
  },
];
