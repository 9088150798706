export const APP_ROUTES = {
  BASE: "/",
  COACH_MATCHING: "coach-matching",
  DASHBOARD: "dashboard",
  DENIED: "denied",
  FORGOT_PASSWORD: "reset-password",
  LOGIN: "login",
  PROFILE: "profile",
  REGISTER: "register",
  RESCHEDULE: "reschedule",
  SESSION: "session",
};
