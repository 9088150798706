import {
  coachingLanguages,
  interests,
  preferences,
  preferredCommunication,
  preferredTime,
  topics,
} from './students.constants';
import { StudentStatus } from '../../../../types';

export const stringifyTopics = (topicsList: string[]) => {
  return topicsList
    ?.map(topic => {
      return topics[topic as keyof typeof topics] || topic;
    })
    .join(', ');
};

export const stringifyLanguages = (languagesList: string[]) => {
  return languagesList
    ?.map(item => {
      return coachingLanguages[item as keyof typeof coachingLanguages] || item;
    })
    .join(', ');
};

export const stringifyInterests = (list: string[]) => {
  return list
    ?.map(item => {
      return interests[item as keyof typeof interests] || item;
    })
    .join(', ');
};

export const stringifyPreferredTime = (list: string[]) => {
  return list
    ?.map(item => {
      return preferredTime[item as keyof typeof preferredTime] || item;
    })
    .join(', ');
};

export const stringifyPreferredCommunication = (list: string[]) => {
  return list
    ?.map(item => {
      return (
        preferredCommunication[item as keyof typeof preferredCommunication] ||
        item
      );
    })
    .join(', ');
};

export const stringifyCoachPreferences = (list: string[]) => {
  return list
    ?.map(item => {
      return preferences[item as keyof typeof preferences] || item;
    })
    .join(', ');
};

export const stringifyCoachingLanguage = (list: string[]) => {
  return list
    ?.map(item => {
      return coachingLanguages[item as keyof typeof coachingLanguages] || item;
    })
    .join(', ');
};

export const extractStatusColor = (status: StudentStatus) => {
  if (status === StudentStatus.blocked) {
    return 'red';
  }

  if (status === StudentStatus.archived) {
    return 'grey';
  }

  if (
    status === StudentStatus.register_pending_school_entry ||
    status === StudentStatus.waitlist ||
    status === StudentStatus.register_pending_parental_consent
  ) {
    return 'yellow';
  }

  return 'info';
};
