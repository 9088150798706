import { Fragment, ReactNode, useEffect } from 'react';
import SelectableCardIcon from '../ui/SelectableCardIcon/SelectableCardIcon';
import CustomEntry from '../ui/CustomEntry/CustomEntry';
import {
  ExtendableListing,
  Listing,
} from '../../../../utils/interfaces/RegisterStudent';
import { registerStudentSteps } from '../../utils/constants/RegisterStudent';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import * as Icons from '../../../../assets/icons/components/Interests/Interests';
import {
  handleScreenDataChange,
  removeCustomInterest,
} from '../../store/registerStudentSlice';

import styles from './Interests.module.scss';
import modalStyles from '../ui/Modal/Modal.module.scss';

type Props = {
  data: { [key: string]: boolean };
  isRegister?: boolean;
  listing: ExtendableListing;
  onChangeHandler?: (data: any) => void;
};

const Interests = ({ data, isRegister, listing, onChangeHandler }: Props) => {
  const dispatch = useAppDispatch();
  const interests = useAppSelector(state => {
    return state.registerStudent.interests;
  });
  let selectedInterests: { [key: string]: boolean } = { ...data };
  const handleSelect = (
    childern: ReactNode,
    value: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      selectedInterests = { ...selectedInterests, [value]: true };
    } else {
      delete selectedInterests[value];
    }
    if (isRegister) {
      dispatch(handleScreenDataChange({ interests: selectedInterests }));
    } else {
      onChangeHandler!({ interests: selectedInterests });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="interests-container">
      {interests.predefined.map(interest => {
        return (
          <SelectableCardIcon
            key={interest.value}
            selected={selectedInterests[interest.value]}
            value={interest.value}
            onSelect={handleSelect}
          >
            <div className={styles.icon} key={interest.value}>
              {Icons[interest.value as keyof typeof Icons]()}
            </div>
          </SelectableCardIcon>
        );
      })}

      {listing.custom!.length !== 0 && (
        <Fragment>
          <div className={modalStyles.customEntries}>
            {registerStudentSteps.interests.customLabel}
          </div>

          {listing.custom!.map((topic: Listing, index: number) => {
            return (
              <CustomEntry
                inWizard
                key={topic.label + index}
                label={topic.label}
                removeSelection={() => {
                  return dispatch(dispatch(removeCustomInterest(topic.value)));
                }}
              />
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default Interests;
