import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-named-as-default
import coachSlice from '../features/coach/store/coachSlice';
// @ts-ignore
// eslint-disable-next-line import/no-named-as-default
import coacheeSlice from '../features/coachee/store/coacheeSlice';
// eslint-disable-next-line import/no-named-as-default
import generalSlice from '../features/general/store/generalSlice';
// eslint-disable-next-line import/no-named-as-default
import registerStudentSlice from '../features/register-coachee/store/registerStudentSlice';

/**
 * Creates a store and includes all the slices as reducers.
 */
export const store = configureStore({
  reducer: {
    coach: coachSlice,
    coachee: coacheeSlice,
    general: generalSlice,
    registerStudent: registerStudentSlice,
    // middleware: (getDefaultMiddleware: any) => getDefaultMiddleware(),
  },
});

// Getting the types of existing store functions and exporting them for usage in the hooks.ts file
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
