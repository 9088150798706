import moment from "moment";
import { ConversationListingItem } from "../../interfaces/ConversationListingItem";
import { localStorageUser } from "../../../../utils/helpers/localStorageUser";

import styles from "./ConversationsListingCard.module.scss";

type Props = {
  conversation: ConversationListingItem;
};

const ConversationsListingCard = ({ conversation }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.timeslotContainer}>
          <span>
            {moment(conversation.created_at)
              .tz(localStorageUser().timezone)
              .format("MMMM DD, YYYY")}
          </span>
          <span>
            {moment(conversation.created_at)
              .tz(localStorageUser().timezone)
              .format("hh:mm a")}
          </span>
        </div>
        <div className={styles.separator} />
        <div className={styles.messageInfo}>
          <div className={styles.messageText}>
            {conversation.session_timeslot_id
              ? conversation.agenda
              : "Chat was created without a session"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsListingCard;
