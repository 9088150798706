import axiosInstance from '../../../utils/helpers/http';

export const rescheduleSession = async (
  timeslotId: string,
  sessionId: string,
) => {
  if (timeslotId && sessionId) {
    return axiosInstance.patch(`session-timeslots/${sessionId}/reschedule`, {
      available_timeslot_id: timeslotId,
    });
  }
  return null;
};
