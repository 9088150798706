import { useNavigate } from 'react-router-dom';
import cancel from '../../../../assets/icons/common/cancelSession.svg';
import reschedule from '../../../../assets/icons/common/reschedule.svg';
import { SessionTimeslot } from '../../../coachee/interfaces/session-timeslot';
import { formatDate } from '../../../../utils/helpers/date';

import styles from './ProfileOverviewSessionCard.module.scss';
// import reschedule from "../../../../../assets/icons/common/rescheduleSession.svg";

type Props = {
  handleCancelSession: (id: string) => void;
  session: SessionTimeslot;
};

const ProfileOverviewSessionCard = ({
  handleCancelSession,
  session,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.hour}>
          {formatDate(new Date(+session.session_date), 'hh:mm a')}
        </div>

        <div className={styles.date}>
          {formatDate(new Date(+session.session_date), 'dddd - MM/DD/YYYY')}
        </div>
      </div>

      <div className={styles.right}>
        <img
          alt="Cancel session"
          height={18}
          src={cancel}
          width={18}
          onClick={() => {
            return handleCancelSession(session.id as string);
          }}
        />
        <img
          alt="Reschedule session"
          height={18}
          src={reschedule}
          width={18}
          onClick={() => {
            return navigate(`/coach/session/${session.id}/reschedule`);
          }}
        />
      </div>
    </div>
  );
};

export default ProfileOverviewSessionCard;
