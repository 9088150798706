import { EventContentArg } from '@fullcalendar/core';
import { Rating } from 'react-simple-star-rating';
import { Conditional, Icon } from '../common';
import { IconName } from '../common/icon/icon.type';

import styles from '../features/coachee/components/upcoming-sessions/upcoming-sessions.module.scss';

const iconsMap: Record<string, IconName> = {
  phone_call: 'ui:phone-plus',
  sms: 'ui:sms',
  video_call: 'ui:camera',
};

export const renderEventContent = (eventInfo: EventContentArg) => {
  return (
    <div>
      <div className={styles.sessionCell}>
        <Conditional
          condition={
            !!iconsMap[eventInfo.event.extendedProps.communication_channel_type]
          }
        >
          <Icon
            color={eventInfo.view.type === 'listWeek' ? 't1' : 'white'}
            name={
              iconsMap[eventInfo.event.extendedProps.communication_channel_type]
            }
            size="16px"
          />
        </Conditional>

        <Conditional
          condition={eventInfo.view.type === 'listWeek'}
          Fallback={<span>{eventInfo.event.title}</span>}
        >
          <div className={styles.nameContainer}>
            <span>{eventInfo.event.title}</span>

            <Conditional condition={eventInfo.event.extendedProps.rating}>
              <Rating
                fillColor="#D66361"
                iconsCount={5}
                initialValue={eventInfo.event.extendedProps.rating}
                readonly
                size={18}
              />
            </Conditional>
          </div>
        </Conditional>
      </div>
    </div>
  );
};
