export const languages = [
  {
    label: 'English',
    value: 'english',
  },
  {
    label: 'Spanish',
    value: 'spanish',
  },
];

export const personalityDescriptors = {
  adventurous: 'Adventurous',
  caring: 'Caring',
  creative: 'Creative',
  dependable: 'Dependable',
  easy_going: 'Easy going',
  empathetic: 'Empathetic',
  energetic: 'Energetic',
  extroverted: 'Extroverted',
  family: 'Family',
  introverted: 'Introverted',
  listener: 'Listener',
  open_minded: 'Open Minded',
  optimistic: 'Optimistic',
  organized: 'Organized',
  outgoing: 'Outgoing',
  passionate: 'Passionate',
  quirky: 'Quirky',
  responsible: 'Responsible',
};

export const personalityDescriptorsArray = Object.keys(
  personalityDescriptors,
).map(item => {
  return {
    // @ts-ignore
    label: personalityDescriptors[item],
    value: item,
  };
});

const timezones = {
  'US/Arizona': 'Arizona - MST',
  'US/Central': 'Central Time - CT',
  'US/Eastern': 'Eastern Time - ET',
  'US/Hawaii': 'Hawaii Time - HST',
  'US/Mountain': 'Mountain Time - MT',
  'US/Pacific': 'Pacific Time - PT',
} as const;

export const timezonesArray = Object.keys(timezones).map(key => {
  return {
    // @ts-ignore
    label: timezones[key],

    value: key,
  };
});

const interests = {
  adventure: 'Adventure',
  arts: 'Arts',
  books: 'Books',
  education: 'Education',
  entertainment: 'Entertainment',
  fitness: 'Fitness',
  gaming: 'Gaming',
  health: 'Health',
  outdoors: 'Outdoors',
  podcasts: 'Podcasts',
  sport: 'Sport',
  stem: 'STEM',
};

export const interestsArray = Object.keys(interests).map(key => {
  return {
    // @ts-ignore
    label: interests[key],

    value: key,
  };
});
