import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../interfaces/Theme";
import { Dashboard } from "../utils/constants/DashboardConstants";

export interface GeneralState {
  dashboardSessionsFilter: string;
  dashboardState: string;
  themes: Theme[];
}

/**
 * Default state object with initial values.
 */
const initialState: GeneralState = {
  dashboardSessionsFilter: Dashboard.upcoming,
  dashboardState: Dashboard.tabs.calendar,
  themes: [],
};

export const coachSlice = createSlice({
  initialState,
  name: "coach",
  reducers: {
    setDashboardSessionFilter(state, action: PayloadAction<string>) {
      state.dashboardSessionsFilter = action.payload;
    },
    setDashboardState(state, action: PayloadAction<string>) {
      state.dashboardState = action.payload;
    },
  },
});

export const { setDashboardSessionFilter, setDashboardState } =
  coachSlice.actions;

export default coachSlice.reducer;
