import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";
import { localStorageUser } from "../../../utils/helpers/localStorageUser";
import { Theme } from "../interfaces/Theme";

export const useThemesFetch = () => {
  const { data, error } = useSWR(`themes`, interceptorFetcher, {
    revalidateOnFocus: false,
  });

  return {
    isError: error,
    isLoading: !error && !data,
    themes: data as Theme[],
  };
};
