import axiosInstance from '../../../utils/helpers/http';
import { TimeslotUpdate } from '../interfaces/UpdateTimeslot';
import { firstDateOfWeek, lastDateOfWeek } from '../../../utils/helpers/date';

export const updateAvailableTimeslot = async (
  coachId: string,
  from: string,
  to: string,
  availableTimeslots: TimeslotUpdate[],
) => {
  return axiosInstance.post(
    `coaches/available-timeslots?coachId=${coachId}&from=${
      from ? new Date(from).getTime() : firstDateOfWeek().getTime()
    }&to=${to ? new Date(to).getTime() : lastDateOfWeek().getTime()}`,
    availableTimeslots,
  );
};
