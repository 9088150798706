import { ReactNode } from "react";

import styles from "./GenericInfo.module.scss";

type Props = {
  button: ReactNode;
  image: string;
  subtitle: string;
  title: string;
};

const GenericInfo = ({ button, image, subtitle, title }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.image}>
        <img alt="" src={image} />
      </div>
      <div className={styles.button}>{button}</div>
    </div>
  );
};

export default GenericInfo;
