import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardTabs } from "../../../utils/constants/CoacheeDashboard";

export interface GeneralState {
  dashboardTab: string;
}
/**
 * Default state object with initial values.
 */
const initialState: GeneralState = {
  dashboardTab: DashboardTabs.UPCOMING,
};
export const coacheeSlice = createSlice({
  initialState,
  name: "coachee",
  reducers: {
    setDashboardTab(state, action: PayloadAction<string>) {
      state.dashboardTab = action.payload;
    },
  },
});
export const { setDashboardTab } = coacheeSlice.actions;
export default coacheeSlice.reducer;
