import React, { FC, useState } from 'react';
import { DetailsProps } from './details.types';
import {
  Button,
  Conditional,
  Flex,
  Form,
  Icon,
  Typography,
} from '../../../../../../common';
import Avatar from '../../../../../general/components/avatar/avatar';
import Tabs from '../../../../../general/components/tabs/tabs';
import { DetailsForm } from '../details-form';

export const Details: FC<DetailsProps> = props => {
  const { admin, onEdit, onHideDrawer } = props;
  const [activeTab, setActiveTab] = useState('Details');

  return (
    <Form
      Footer={
        activeTab === 'Details' && (
          <Button
            icon={<Icon name="actions:pencil" />}
            iconPosition="right"
            text="Edit Coach Details"
            variant="secondary"
            onClick={onEdit}
          />
        )
      }
      Header={
        <React.Fragment>
          <Flex alignItems="center" gap="3x" marginBottom="4x">
            <Avatar height={80} image={admin.profile_img} width={80} />

            <div>
              <Typography text={admin?.name} variant="title3" />
              <Typography
                marginTop=".5x"
                text="Active Coach"
                variant="caption"
              />
            </div>
          </Flex>

          <Tabs active={activeTab} tab1="Details" onTabChange={setActiveTab} />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Conditional condition={activeTab === 'Details'}>
        <DetailsForm admin={admin} />
      </Conditional>
    </Form>
  );
};
