import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mapConfig } from "../utils/helpers/mapConfig";
import { stepKeys } from "../utils/constants/RegisterStudent";

import {
  ExtendableListing,
  Listing,
  UserDataInput,
  UserDataObject,
} from "../../../utils/interfaces/RegisterStudent";

export interface RegisterStudentState {
  coachPersonalityDescriptors: {
    predefined: Listing[];
  };
  coachPreferences: ExtendableListing;
  currentStep: string;
  errors: {
    [step: string]: {
      [name: string]: string;
    };
  };
  interests: ExtendableListing;
  isButtonDisabled: boolean;
  listings: { [key: string]: Listing[] };
  preferredCommunication: ExtendableListing;
  preferredTime: ExtendableListing;

  topics: ExtendableListing;
  userData: { [key: string]: {} };
}

type Error = {
  currentStep: string;
  error: {};
};

/**
 * Default state object with initial values.
 */
const initialState: RegisterStudentState = {
  coachPersonalityDescriptors: {
    predefined: [],
  },
  coachPreferences: {
    custom: [],
    predefined: [],
  },
  currentStep: stepKeys.topics,
  errors: {
    aboutYou: {},
    basicInfo: {},
    forgottenPassword: {},
  },
  interests: {
    custom: [],
    predefined: [],
  },
  isButtonDisabled: true,
  listings: {
    coachType: [],
    coachingLanguages: [],
    engagementOptions: [],
    schools: [],
    timezones: [],
  },
  preferredCommunication: {
    predefined: [],
  },
  preferredTime: {
    predefined: [],
  },

  topics: {
    custom: [],
    predefined: [],
  },
  userData: {
    aboutYou: {
      birthdate: "",
      coachType: "",
      coachingLanguage: "",
      engagement: "",
      school: "",
      timezone: "",
    },
    basicInfo: {
      email: "",
      lastName: "",
      name: "",
      phoneNo: "",
      studentId: "",
    },
    coachPreferences: {},
    coachingAcknowledgment: {
      allow_participation: false,
      coachingAcknowledgment: false,
    },
    congrats: {},
    interests: {},
    parentalConsent: {
      email: "",
      phoneNo: "",
    },
    preferredCommunication: {},

    preferredTime: {},
    schoolEntry: {
      customSchool: "",
    },
    topics: {},
  },
};

/**
 * Create a slice as a reducer containing actions.
 *
 * In this example actions are included in the slice. It is fine and can be
 * changed based on your needs.
 */
export const registerStudentSlice = createSlice({
  initialState,
  name: "registerStudent",
  reducers: {
    addCustomCoachPreference(state, action: PayloadAction<Listing[]>) {
      state.coachPreferences.custom = action.payload;
      const userDataEntries = action.payload.reduce((accumulator, val) => {
        return { ...accumulator, [val.label]: true };
      }, []);
      state.userData.coachPreferences = {
        ...state.userData.coachPreferences,
        ...userDataEntries,
      };
    },

    addCustomInterest(state, action: PayloadAction<Listing[]>) {
      state.interests.custom = action.payload;
      const userDataEntries = action.payload.reduce((accumulator, val) => {
        return { ...accumulator, [val.label]: true };
      }, []);
      state.userData.interests = {
        ...state.userData.interests,
        ...userDataEntries,
      };
    },

    addCustomTopic(state, action: PayloadAction<Listing[]>) {
      state.topics.custom = action.payload;
      const userDataEntries = action.payload.reduce((accumulator, val) => {
        return { ...accumulator, [val.label]: true };
      }, []);
      state.userData.topics = {
        ...state.userData.topics,
        ...userDataEntries,
      };
    },
    handleError(state, action: PayloadAction<Error>) {
      const {currentStep} = action.payload;
      const key = Object.keys(action.payload.error)[0];
      const value =
        action.payload.error[key as keyof typeof action.payload.error];
      if (value === "") {
        delete state.errors[currentStep][key];
        return;
      }
      state.errors = {
        ...state.errors,
        [currentStep]: {
          ...state.errors[currentStep],
          [key]: [value].toString(),
        },
      };
    },

    handleInputDataChange(state, action: PayloadAction<UserDataInput | any>) {
      state.userData = {
        ...state.userData,
        [Object.keys(action.payload)[0]]: {
          ...state.userData[Object.keys(action.payload)[0]],
          [Object.keys(action.payload[Object.keys(action.payload)[0]])[0]]: [
            action.payload[Object.keys(action.payload)[0]][
              Object.keys(action.payload[Object.keys(action.payload)[0]])[0]
            ],
          ].toString(),
        },
      };
    },

    handlePrefferedCommunication(state, action: PayloadAction<object>) {
      state.userData.preferredCommunication = action.payload;
    },
    handleScreenDataChange(state, action: PayloadAction<UserDataObject>) {
      state.userData = { ...state.userData, ...action.payload };
    },

    handleSetCurrentStep(state, action: PayloadAction<string>) {
      state.currentStep = action.payload;
    },
    removeCustomCoachPreference(state, action: PayloadAction<string>) {
      state.coachPreferences.custom = state.coachPreferences.custom!.filter(
        (topic) => {return topic.label !== action.payload}
      );
      const currentState = { ...state.userData.coachPreferences };
      delete currentState[action.payload as keyof typeof currentState];
      state.userData.coachPreferences = currentState;
    },
    removeCustomInterest(state, action: PayloadAction<string>) {
      state.interests.custom = state.interests.custom!.filter(
        (topic) => {return topic.label !== action.payload}
      );
      const currentState = { ...state.userData.interests };
      delete currentState[action.payload as keyof typeof currentState];
      state.userData.interests = currentState;
    },
    removeCustomTopic(state, action: PayloadAction<string>) {
      state.topics.custom = state.topics.custom!.filter(
        (topic) => {return topic.label !== action.payload}
      );
      const currentState = { ...state.userData.topics };
      delete currentState[action.payload as keyof typeof currentState];
      state.userData.topics = currentState;
    },
    removeForgotPasswordErrors(state, action: PayloadAction<{}>) {
      state.errors.forgottenPassword = action.payload;
    },

    setConfig(state, action: PayloadAction<any>) {
      if (state && action.payload.configuration) {
        // Populate the store
        state.topics.predefined = mapConfig(
          "topics",
          action.payload.configuration
        );
        state.interests.predefined = mapConfig(
          "interests",
          action.payload.configuration
        );
        state.coachPreferences.predefined = mapConfig(
          "preferences",
          action.payload.configuration
        );
        state.preferredTime.predefined = mapConfig(
          "preferredTime",
          action.payload.configuration
        );
        state.preferredCommunication.predefined = mapConfig(
          "preferredCommunication",
          action.payload.configuration
        );
        state.coachPersonalityDescriptors.predefined = mapConfig(
          "coachPersonalityDescriptors",
          action.payload.configuration
        );
        state.listings.timezones = mapConfig(
          "timezones",
          action.payload.configuration
        );
        state.listings.coachingLanguages = mapConfig(
          "coachingLanguages",
          action.payload.configuration
        );
        state.listings.coachType = mapConfig(
          "coachType",
          action.payload.configuration
        );
        state.listings.engagementOptions = mapConfig(
          "engagementOptions",
          action.payload.configuration
        );
        state.listings.personalityDescriptors = mapConfig(
          "coachPersonalityDescriptors",
          action.payload.configuration
        );
      }
    },

    setCurrentStep(state, action: PayloadAction<string>) {
      state.currentStep = action.payload;
    },
    setIsButtonDisabled(state, action: PayloadAction<boolean>) {
      state.isButtonDisabled = action.payload;
    },
  },
});

// Exports all actions
export const {
  addCustomCoachPreference,
  addCustomInterest,
  addCustomTopic,
  handleError,
  handleInputDataChange,
  handlePrefferedCommunication,
  handleScreenDataChange,
  handleSetCurrentStep,
  removeCustomCoachPreference,
  removeCustomInterest,
  removeCustomTopic,
  removeForgotPasswordErrors,
  setConfig,
  setCurrentStep,
  setIsButtonDisabled,
} = registerStudentSlice.actions;

export default registerStudentSlice.reducer;
