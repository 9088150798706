import { useEffect, useState } from 'react';
import Students from '../Students/Students';
import { Dashboard } from '../../../utils/constants/DashboardConstants';
import Tabs from '../../../../general/components/tabs/tabs';
import { Student } from '../../../../register-coachee/interfaces/Student';
import { fetchStudents } from '../../../api/useFetchCoachStudents';
import { fetchArchivedStudents } from '../../../api/fetchArchivedStudents';

import styles from './StudentConversationListing.module.scss';

const StudentConversationListing = () => {
  const [selectedTab, setSelectedTab] = useState<string>(Dashboard.ongoing);
  const [students, setStudents] = useState<Student[] | undefined>();

  const handleStudents = async (selection: string) => {
    if (selection === Dashboard.ongoing) {
      const res = await fetchStudents();
      setStudents(res);
    } else if (selection === Dashboard.archive) {
      const res = await fetchArchivedStudents();
      setStudents(res);
    }
  };

  useEffect(() => {
    handleStudents(selectedTab);
  }, [selectedTab]);

  return (
    <div>
      <Tabs
        active={selectedTab}
        tab1={Dashboard.ongoing}
        tab2={Dashboard.archive}
        onTabChange={(data: string) => {
          return setSelectedTab(data);
        }}
      />
      <div className={styles.container}>
        <div className={styles.conversationsListing}>
          <Students
            archived={selectedTab === Dashboard.archive}
            isConversationListing
            students={students}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentConversationListing;
