import moment from 'moment';
import toast from 'react-hot-toast';
import { Fragment } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { Listing } from '../../../../utils/interfaces/RegisterStudent';
import UserInfo from '../../../coachee/components/user-info/user-info';
import InterestCard from '../../../coach-matching/components/interest-card/interest-card';
import ListingCard from '../../../general/components/listing-card/listing-card';
import Loading from '../../../general/components/loading/loading';
import { StudentOverviewResponse } from '../../interfaces/StudentOverviewResponse';
import Survey from '../../../../assets/icons/common/survey.svg';
import { sendExitSurvey } from '../../api/sendExitSurvey';

import styles from './StudentOverview.module.scss';

type Props = {
  data: StudentOverviewResponse;
};

const StudentOverview = ({ data }: Props) => {
  const aboutMeLabel = <div className={styles.sectionTitle}>ABOUT ME</div>;

  const birthday = data && (
    <div>
      <div className={styles.greyTitle}>Birthday</div>
      <div className={styles.plain}>
        {data.studentData &&
          moment(data.studentData.date_of_birth).format('MM/DD/YYYY')}
      </div>
    </div>
  );

  const email = data && (
    <div>
      <div className={styles.greyTitle}>Email</div>
      <div className={styles.plain}>
        {data.studentData && data.studentData.email}
      </div>
    </div>
  );

  const phone = data && (
    <div>
      <div className={styles.greyTitle}>Phone</div>
      <div className={styles.plain}>
        {data.studentData && data.studentData.phone_number}
      </div>
    </div>
  );

  const topicsListing = useAppSelector(state => {
    return state.registerStudent.topics;
  });

  const interestsListing = useAppSelector(state => {
    return state.registerStudent.interests;
  });

  const preferredTimeListing = useAppSelector(state => {
    return state.registerStudent.preferredTime;
  });

  const preferredCommunicationListing = useAppSelector(state => {
    return state.registerStudent.preferredCommunication;
  });

  const coachPreferencesListing = useAppSelector(state => {
    return state.registerStudent.coachPreferences;
  });

  const filteredTopics =
    data.studentData &&
    topicsListing.predefined.filter(topic => {
      return data.studentData && data.studentData.topics.includes(topic.value);
    });
  const filteredInterests =
    data.studentData &&
    interestsListing.predefined.filter(interest => {
      return (
        data.studentData && data.studentData.interests.includes(interest.value)
      );
    });
  const filteredPreferredTime =
    data.studentData &&
    preferredTimeListing.predefined.filter(time => {
      return (
        data.studentData && data.studentData.preferred_time.includes(time.value)
      );
    });

  const filteredPreferredCommunication =
    data.studentData &&
    preferredCommunicationListing.predefined.find(element => {
      return element.value === data.studentData.preferred_communication_channel;
    });

  const filtredCoachPreferences =
    coachPreferencesListing &&
    coachPreferencesListing.predefined.filter(preference => {
      return (
        data.studentData &&
        data.studentData.preferences.includes(preference.value)
      );
    });

  const capitalizedLanguage =
    data.studentData &&
    data.studentData.coaching_language &&
    data.studentData.coaching_language.charAt(0).toUpperCase() +
      data.studentData.coaching_language.slice(1);

  const language = (
    <div>
      <div className={styles.greyTitle}>Language for coaching</div>
      <div className={styles.plain}>{capitalizedLanguage}</div>
    </div>
  );

  return (
    <div className={styles.container}>
      {data.isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <UserInfo
            name={`${data.studentData?.name || ''} ${
              data.studentData?.surname || ''
            }`}
          />
          <div className={styles.aboutMeContainer}>
            {aboutMeLabel}
            {birthday}
            {email}
            {phone}
            {language}
          </div>
          <div className={styles.cardsContainer}>
            <ListingCard title="Coaching topics">
              {filteredTopics &&
                filteredTopics.map((topic: Listing, index: number) => {
                  return `${topic.label}${
                    index < filteredTopics.length - 1 ? ', ' : ''
                  }`;
                })}
            </ListingCard>
            <ListingCard title="Interests">
              <div className={styles.interestsBar}>
                {filteredInterests &&
                  filteredInterests.map((interest: Listing) => {
                    return (
                      <InterestCard
                        interest={{
                          label: interest.label,
                          value: interest.value,
                        }}
                        key={interest.label + interest.value}
                      />
                    );
                  })}
              </div>
            </ListingCard>
            <ListingCard title="Preferred time">
              {filteredPreferredTime &&
                filteredPreferredTime.map((time: Listing, index: number) => {
                  return `${time.label}${
                    index < filteredPreferredTime.length - 1 ? ', ' : ''
                  }`;
                })}
            </ListingCard>
            <ListingCard title="WAY OF COMMUNICATION">
              {filteredPreferredCommunication?.label}
            </ListingCard>
            <ListingCard title="Coach preferences">
              {filtredCoachPreferences.map((preference, index) => {
                return `${preference.label}${
                  index < filtredCoachPreferences.length - 1 ? ', ' : ''
                }`;
              })}
            </ListingCard>
          </div>

          <div
            className={styles.surveyButton}
            onClick={() => {
              sendExitSurvey(data.studentData.id).then(r => {
                if (r.success) {
                  toast.success('Survey sent');
                }
              });
            }}
          >
            Sent exit survey
            <img
              alt="Sent exit survey"
              className={styles.survey}
              src={Survey}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default StudentOverview;
