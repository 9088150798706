import React, { FC, useState } from 'react';
import { DetailsProps } from './details.types';
import {
  Button,
  Conditional,
  Flex,
  Icon,
  Panel,
  Typography,
} from '../../../../../../common';
import Avatar from '../../../../../general/components/avatar/avatar';
import Tabs from '../../../../../general/components/tabs/tabs';
import { DetailsForm } from '../details-form';
import { CoachSessions } from '../coach-sessions';
import { CoachCapacity } from '../coach-capacity';

export const Details: FC<DetailsProps> = props => {
  const { coach, onEdit, onHideDrawer, onRefresh } = props;
  const [activeTab, setActiveTab] = useState('Details');

  return (
    <Panel
      Footer={
        activeTab === 'Details' && (
          <Button
            icon={<Icon name="actions:pencil" />}
            iconPosition="right"
            text="Edit Coach Details"
            variant="secondary"
            onClick={onEdit}
          />
        )
      }
      Header={
        <React.Fragment>
          <Flex alignItems="center" gap="3x" marginBottom="4x">
            <Avatar height={80} image={coach.profile_img} width={80} />

            <div>
              <Typography text={coach?.name} variant="title3" />
              <Typography
                marginTop=".5x"
                text="Active Coach"
                variant="caption"
              />
            </div>
          </Flex>

          <Tabs
            active={activeTab}
            tab1="Details"
            tab2="Sessions"
            tab3="Capacity"
            onTabChange={setActiveTab}
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Conditional condition={activeTab === 'Details'}>
        <DetailsForm coach={coach} />
      </Conditional>

      <Conditional condition={activeTab === 'Sessions'}>
        <CoachSessions coach={coach} />
      </Conditional>

      <Conditional condition={activeTab === 'Capacity'}>
        <CoachCapacity
          coach={coach}
          onHideDrawer={onHideDrawer}
          onRefresh={onRefresh}
        />
      </Conditional>
    </Panel>
  );
};
