import moment from "moment";
import { localStorageUser } from "../../../../utils/helpers/localStorageUser";

export const generateGoogleCalendarLink = (
  timeslot: string,
  agenda?: string
) => {
  const startTime = moment(timeslot);
  const endTime = moment(timeslot).add(30, "minutes");

  const formattedStartTime =
    `${moment(startTime)
      .tz(localStorageUser().timezone)
      .format("YYYYMMDDTHHmmss")  }z`;
  const formattedEndTime =
    `${moment(endTime).tz(localStorageUser().timezone).format("YYYYMMDDTHHmmss") 
    }z`;
  const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    "Trula coaching session"
  )}&details=${encodeURIComponent(
    agenda || ""
  )}&dates=${formattedStartTime}%2F${formattedEndTime}`;

  return calendarUrl;
};
