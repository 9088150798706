import * as yup from 'yup';

export const adminFormSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  name: yup.string().required('Name is required'),
  phone_number: yup
    .string()
    .required('Phone is required')
    .matches(
      /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
      'Phone number must be in format: +1 (999) 999-9999',
    ),
  surname: yup.string().required('Surname is required'),
});
