import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useUniversitiesFetch = () => {
  const { data, error } = useSWR(`/universities`, interceptorFetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    universities: data,
  };
};
