import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgPhone = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.94 17.844a1.963 1.963 0 0 0-.997-1.242l-5.117-2.654a1.948 1.948 0 0 0-2.483.567l-.827 1.135a.298.298 0 0 1-.385.087 15.021 15.021 0 0 1-3.31-2.555 15.031 15.031 0 0 1-2.553-3.311.298.298 0 0 1 .087-.385l1.13-.828a1.95 1.95 0 0 0 .566-2.484L7.416 1.057A1.958 1.958 0 0 0 4.594.328L1.38 2.481A3.096 3.096 0 0 0 .006 5.25c.043.915.166 1.825.368 2.72.873 3.37 2.98 6.834 5.904 9.765 2.925 2.931 6.392 5.03 9.76 5.896.894.202 1.803.325 2.718.368h.207a3.093 3.093 0 0 0 2.557-1.362l2.143-3.217a1.958 1.958 0 0 0 .277-1.577Zm-1.654.654-2.131 3.22a1.448 1.448 0 0 1-1.291.634 14.295 14.295 0 0 1-2.412-.318c-3.087-.804-6.28-2.745-8.999-5.477-2.718-2.733-4.667-5.916-5.47-9.005a14.307 14.307 0 0 1-.322-2.413 1.437 1.437 0 0 1 .633-1.288l3.215-2.144a.294.294 0 0 1 .165-.05h.079a.298.298 0 0 1 .19.153l2.648 5.121a.302.302 0 0 1-.087.381l-1.13.828a1.954 1.954 0 0 0-.529 2.538 16.783 16.783 0 0 0 2.813 3.647 16.77 16.77 0 0 0 3.645 2.815 1.952 1.952 0 0 0 2.537-.53l.827-1.134a.301.301 0 0 1 .38-.087l5.119 2.65a.302.302 0 0 1 .111.434l.009.025Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgPhone;
