import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSWRConfig } from 'swr';
import UserInfo from '../../../coachee/components/user-info/user-info';
import Card from '../../../general/components/card/card';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import Loading from '../../../general/components/loading/loading';
import EmptyButton from '../../../general/components/ui/empty-button/empty-button';
import UserLayout from '../../../general/components/user-layout/user-layout';
import { useSesssionDetailsFetch } from '../../api/useSessionDetailsFetch';
import edit from '../../../../assets/icons/common/edit.svg';
import add from '../../../../assets/icons/common/add.svg';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { createSessionNote } from '../../api/createSessionNote';
import { useAppDispatch } from '../../../../store/hooks';
import SessionThemes from './SessionThemes';
import Checkbox from '../../../register-coachee/components/ui/Checkbox/Checkbox';
import { Theme } from '../../interfaces/Theme';
import bell from '../../../../assets/icons/common/bell.svg';
import { updateSessionThemes } from '../../api/updateSessionThemes';
import { updateSessionTimeslot } from '../../api/updateSessionTimeslot';
import { UpdateSesssionTimeslot } from '../../interfaces/UpdateSessionTimeslot';
import { convertDateTimeToTimezone } from '../../../general/utils/helpers/convertDateTime';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import EmptyListing from '../../../general/components/empty-listing/empty-listing';
import emptyNotes from '../../../../assets/icons/EmptyListing/emptyNotes.svg';
import addToCalendar from '../../../../assets/icons/common/addToCalendar.svg';
import { useThemesFetch } from '../../api/useThemesFetch';
import { setOverlay } from '../../../general/store/generalSlice';
import { fetchIcal } from '../../../general/api/fetchIcal';
import { generateGoogleCalendarLink } from '../../../general/utils/helpers/generateGoogleCalendarLink';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import { Conditional } from '../../../../common';
import { isDateInFuture } from '../../../../utils/helpers/date';

import styles from './CoachSessionOverview.module.scss';

const CoachSessionOverview = () => {
  const { id } = useParams();
  const [googleCalendarLink, setGoogleCalendarLink] = useState<string>('');
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showAgendaModal, setShowAgendaModal] = useState(false);
  const [showThemesModal, setShowThemesModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showEscalationModal, setShowEscalationModal] = useState(false);

  const [note, setNote] = useState('');
  const [escalation, setEscalation] = useState('');
  const [agenda, setAgenda] = useState('');

  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const coach = localStorageUser();
  const themesData = useThemesFetch();
  const dispatch = useAppDispatch();
  const sessionData = useSesssionDetailsFetch(id as string);

  //* Themes logic
  const [chosenThemes, setChosenThemes] = useState<Theme[]>([]);

  useEffect(() => {
    if (!sessionData.isLoading) {
      setChosenThemes(sessionData.session.coaching_themes);
    }
  }, [sessionData.session]);

  const revalidateSessionData = () => {
    mutate(`coaches/session-timeslots/${id}`);
  };

  const handleEscalation = async () => {
    try {
      const update: UpdateSesssionTimeslot = {
        escalate_reason: escalation,
        escalated: true,
      };
      await updateSessionTimeslot(id as string, update);
      revalidateSessionData();
      toast.success('Escalation created succesfully');
      setShowEscalationModal(false);
      dispatch(setOverlay(false));
    } catch {
      toast.error('Escalation was not successful');
    }
  };

  const handleChosenThemes = (theme: Theme, isChecked: boolean) => {
    if (isChecked) {
      setChosenThemes((prevState: any) => {
        const update = [...prevState];
        update.push(theme);
        return update;
      });
    } else {
      setChosenThemes((prevState: any) => {
        const update = [...prevState];
        return update.filter(chosenTheme => {
          return chosenTheme.id !== theme.id;
        });
      });
    }
  };

  const handleUpdateSessionThemes = async () => {
    try {
      await updateSessionThemes(chosenThemes, id as string);
      toast.success('Coaching themes updated');
      revalidateSessionData();
      setShowThemesModal(false);
      dispatch(setOverlay(false));
    } catch {
      toast.error("Couldn't save coaching themes");
    }
  };

  const handleSaveAgenda = async () => {
    try {
      const update = {
        agenda,
      };
      await updateSessionTimeslot(id as string, update);
      revalidateSessionData();
      toast.success('Sesssion updated');
      dispatch(setOverlay(false));
      setShowAgendaModal(false);
    } catch {
      toast.error("Couldn't save note");
    }
  };

  const handleSaveNote = () => {
    saveNote();
    setNote('');
    setShowNotesModal(false);
    dispatch(setOverlay(false));
  };

  const agendaRender =
    sessionData.session &&
    sessionData.session.agenda !== '' &&
    !sessionData.isLoading &&
    sessionData.session.agenda ? (
      sessionData.session.agenda
    ) : (
      <EmptyListing
        image={emptyNotes}
        subtitle="Your agenda on this session will appear here"
        title="No agenda entered"
      />
    );

  const escalateModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowEscalationModal(false);
      }}
      title="Escalate student"
    >
      <textarea
        className={styles.textarea}
        name="note"
        rows={12}
        onChange={e => {
          return setEscalation(e.target.value);
        }}
      />
      <div className={styles.nextButton}>
        <Button
          className="topShift"
          isDisabled={escalation === ''}
          label="Escalate"
          onClickHandler={() => {
            return handleEscalation();
          }}
        />
      </div>
    </EmptyModal>
  );

  const editAgendaButton = (
    <div
      className={styles.actionButton}
      onClick={() => {
        dispatch(setOverlay(true));
        setShowAgendaModal(true);
      }}
    >
      <img alt="Edit session agenda" src={edit} width={14} />
    </div>
  );

  const agendaModal = (
    <EmptyModal
      cssClass="mediumModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowAgendaModal(false);
      }}
      title="Edit agenda"
    >
      <textarea
        className={styles.textarea}
        defaultValue={sessionData.session && sessionData.session.agenda}
        name="note"
        rows={12}
        onChange={e => {
          return setAgenda(e.target.value);
        }}
      />
      <div className={styles.nextButton}>
        <Button
          className="topShift"
          isDisabled={false}
          label="Save"
          onClickHandler={() => {
            handleSaveAgenda();
          }}
        />
      </div>
    </EmptyModal>
  );

  const addToCalendarButton = (
    <div
      className={styles.addToCalendarButton}
      onClick={() => {
        setGoogleCalendarLink(
          generateGoogleCalendarLink(
            sessionData?.session?.timeslot,
            sessionData?.session.agenda,
          ),
        );
        dispatch(setOverlay(true));
        setShowCalendarModal(true);
      }}
    >
      <img alt="Add to calendar" src={addToCalendar} />
    </div>
  );

  const addToCalendarModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowCalendarModal(false);
      }}
      title="Add to calendar"
    >
      <div className={styles.calendarModal}>
        <Button
          isDisabled={false}
          label="iCal/Outlook Calendar"
          onClickHandler={() => {
            fetchIcal(sessionData.session.id as string);
            setShowCalendarModal(false);
            dispatch(setOverlay(false));
          }}
        />
        <a
          className={styles.googleCalendarLink}
          href={googleCalendarLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button
            isDisabled={false}
            label="Google Calendar"
            onClickHandler={() => {
              setShowCalendarModal(false);
              dispatch(setOverlay(false));
            }}
          />
        </a>
        <div
          className={styles.cancelLabel}
          onClick={() => {
            setShowCalendarModal(false);
            dispatch(setOverlay(false));
          }}
        >
          Cancel
        </div>
      </div>
    </EmptyModal>
  );

  const editThemesButton = (
    <SessionThemes
      themes={sessionData?.session?.coaching_themes}
      onEdit={() => {
        setShowThemesModal(true);
        dispatch(setOverlay(true));
      }}
    />
  );

  const themesModal = (
    <EmptyModal
      cssClass="largeModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowThemesModal(false);
      }}
      title="Edit coaching themes"
    >
      <div className={styles.themeListingModal}>
        {!themesData.isLoading &&
          themesData.themes.map(theme => {
            return (
              <Checkbox
                checked={
                  sessionData.session &&
                  sessionData.session.coaching_themes.some(
                    (themeElement: Theme) => {
                      return themeElement.theme === theme.theme;
                    },
                  )
                }
                key={theme.id}
                label={theme.theme}
                value={theme.theme}
                onCheck={(value: any, e: ChangeEvent<HTMLInputElement>) => {
                  handleChosenThemes(theme, e.target.checked);
                }}
              />
            );
          })}
      </div>
      <div className={styles.nextButton}>
        <Button
          className="topShift"
          isDisabled={false}
          label="Save"
          onClickHandler={() => {
            handleUpdateSessionThemes();
          }}
        />
      </div>
    </EmptyModal>
  );

  const addSessionButton = (
    <div
      className={styles.actionButton}
      onClick={() => {
        setShowNotesModal(true);
        dispatch(setOverlay(true));
      }}
    >
      <img alt="Add session note" src={add} width={14} />
    </div>
  );

  const noteModal = (
    <EmptyModal
      cssClass="mediumModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowNotesModal(false);
      }}
      title="Add note"
    >
      <textarea
        className={styles.textarea}
        name="note"
        rows={12}
        onChange={e => {
          return setNote(e.target.value);
        }}
      />
      <div className={styles.nextButton}>
        <Button
          className="topShift"
          isDisabled={note === ''}
          label="Save"
          onClickHandler={() => {
            handleSaveNote();
          }}
        />
      </div>
    </EmptyModal>
  );

  const saveNote = async () => {
    try {
      await createSessionNote(id as string, note);
      revalidateSessionData();
      toast.success('Note created');
    } catch {
      toast.error("Couldn't save note");
    }
  };

  const filteredNotes = useMemo(() => {
    if (sessionData && !sessionData.isLoading) {
      return sessionData.session.session_timeslot_notes.length === 0
        ? 'no_notes_available'
        : sessionData.session.session_timeslot_notes.reduce(
            (groupedNotes: { [date: string]: string[] }, element: any) => {
              const noteDate =
                element &&
                coach &&
                convertDateTimeToTimezone(
                  element!.created_at,
                  coach.timezone,
                  'MM-DD-YYYY',
                ).split('T')[0];

              if (!groupedNotes[noteDate as keyof typeof groupedNotes]) {
                groupedNotes[noteDate as keyof typeof groupedNotes] = [];
              }
              groupedNotes[noteDate as keyof typeof groupedNotes].push(
                element.note,
              );
              return groupedNotes;
            },
            {},
          );
    }
  }, [sessionData]);

  const notesRender =
    filteredNotes && filteredNotes !== 'no_notes_available' ? (
      Object.keys(filteredNotes)
        .sort()
        .map((date, index) => {
          return (
            <div key={index}>
              <div className={styles.noteDate}>{date}</div>
              <ul className={styles.notesContainer}>
                {filteredNotes[date].map((note: string, index: number) => {
                  return (
                    <li className={styles.note} key={note + index}>
                      {note}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })
    ) : (
      <EmptyListing
        image={emptyNotes}
        subtitle="Your notes on this student will appear here"
        title="No notes yet"
      />
    );

  const startSession = async () => {
    if (sessionData.session.communication_channel_type === 'video_call') {
      navigate(`/video/${sessionData.session.id}`, {
        state: {
          roomNameSet: `${sessionData.session.id}${coach.id}${sessionData.session.student.id}`,
          type: `${sessionData.session.communication_channel_type}`,
        },
      });

      return;
    }

    if (sessionData.session.communication_channel_type === 'phone_call') {
      navigate(
        `/coach/session/${sessionData.session.id}/${sessionData.session.communication_channel_type}/${sessionData.session.id}${sessionData.session.student.id}${coach.id}`,
        {
          state: {
            studentName: `${sessionData.session.student.name} ${sessionData.session.student.surname}`,
            type: `${sessionData.session.communication_channel_type}`,
          },
        },
      );

      return;
    }

    navigate(`/coach/session/${sessionData.session.id}/conversation`);
  };

  return (
    <UserLayout centeredTitle title="Session Overview">
      {sessionData.isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {showCalendarModal && addModalToPortal(addToCalendarModal)}
          {showAgendaModal && addModalToPortal(agendaModal)}
          {showThemesModal && addModalToPortal(themesModal)}
          {showNotesModal && addModalToPortal(noteModal)}
          {showEscalationModal && addModalToPortal(escalateModal)}

          <UserInfo
            name={`${sessionData.session.student.name} ${sessionData.session.student.surname}`}
            timeslot={sessionData.session.timeslot}
          />

          <Conditional
            condition={isDateInFuture(+sessionData.session.session_date)}
          >
            <div className={styles.topActions}>
              <Link to={`/coach/session/${id}/reschedule`}>
                <EmptyButton>Reschedule session</EmptyButton>
              </Link>

              {addToCalendarButton}
            </div>
          </Conditional>

          <Conditional condition={sessionData.session?.escalated}>
            <div className={styles.escalation}>Session has been escalated</div>
          </Conditional>

          <Conditional
            condition={
              !sessionData.session?.escalated &&
              !isDateInFuture(+sessionData.session.session_date)
            }
          >
            <div className={styles.escalateButtonContainer}>
              <div
                className={styles.escalateButton}
                onClick={() => {
                  setShowEscalationModal(true);
                  dispatch(setOverlay(true));
                }}
              >
                <img alt="Escalate" src={bell} width={15} />
              </div>
            </div>
          </Conditional>

          <div className={styles.content}>
            {editThemesButton}
            <Card
              action={editAgendaButton}
              standalone
              subtitle="(content will be shared with the coachee)"
              title="AGENDA"
            >
              {agendaRender}
            </Card>
            <Card action={addSessionButton} standalone title="SESSION NOTES">
              {notesRender}
            </Card>
          </div>
        </Fragment>
      )}
      <div className={styles.nextButton}>
        <Conditional
          condition={
            !sessionData.isLoading &&
            +sessionData.session.session_date > Date.now()
          }
        >
          <Button
            isDisabled={false}
            label="Start session"
            onClickHandler={startSession}
          />
        </Conditional>
      </div>
    </UserLayout>
  );
};

export default CoachSessionOverview;
