import React, { FC, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useSWR from 'swr';
import { Table, Typography } from '../../../../../../common';
import { Coach } from '../../../../../../types';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';

export const CampusCoaches: FC<{ campusId: string }> = ({ campusId }) => {
  const { data } = useSWR(
    `admin/campus/coaches?campusId=${campusId}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const columns: ColumnDef<Coach, keyof Coach>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        cell: ({ getValue, row: { original } }) => {
          return (
            <div>
              <Typography marginBottom=".25x" text={getValue()} />
              <Typography color="secondary" text={original.email} />
            </div>
          );
        },
        header: 'Name',
      },
      {
        accessorKey: 'pastSessionsCount',
        header: 'Sessions',
      },
      {
        accessorKey: 'studentsCount',
        header: 'Students',
      },
    ];
  }, []);

  return <Table columns={columns} data={data || []} />;
};
