import useSWR from "swr";
import axiosInstance from "../../../utils/helpers/http";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useFetchConversationsByStudent = (studentId: string) => {
  const { data, error } = useSWR(
    `comm/archive/${studentId}`,
    interceptorFetcher,
    { revalidateOnFocus: false }
  );

  return {
    conversationData: data,
    isError: error,
    isLoading: !error && !data,
  };
};

export const fetchStudentConversations = (studentId: string) => {
  return axiosInstance.get(`comm/archive/${studentId}`);
};
