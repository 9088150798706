import { useEffect } from 'react';
import { DashboardTabs } from '../../../../utils/constants/CoacheeDashboard';
import UserLayout from '../../../general/components/user-layout/user-layout';
import Tabs from '../../../general/components/tabs/tabs';
// @ts-ignore
import UpcomingSessions from '../upcoming-sessions/upcoming-sessions';
// @ts-ignore
import { useSessionsFetch } from '../../api/useSessionsFetch';
// @ts-ignore
import { useCoachFetch } from '../../api/useCoachFetch';
// @ts-ignore
import { Session } from '../../interfaces/session';
import Loading from '../../../general/components/loading/loading';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
// @ts-ignore
import { setDashboardTab } from '../../store/coacheeSlice';
import { Conditional } from '../../../../common';
// @ts-ignore
import HistorySessions from '../history-sessions/history-sessions';

import styles from './dashboard.module.scss';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(state => {
    return state.coachee.dashboardTab;
  });
  const sessionsData = useSessionsFetch();
  const upcomingSessions: Session[] = [];
  const historySessions: Session[] = [];
  const cognitoGroup = localStorage.getItem('cognitoGroup');
  const coachData = useCoachFetch();

  if (!!cognitoGroup && cognitoGroup === 'coachee' && coachData?.coach) {
    localStorage.setItem('assignedCoach', JSON.stringify(coachData.coach));
  }

  if (sessionsData && sessionsData.sessions) {
    sessionsData.sessions.forEach((element: Session) => {
      if (new Date(+element.session_date).getTime() > Date.now()) {
        upcomingSessions.push(element);
      } else {
        historySessions.push(element);
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const coachName =
    `${coachData?.coach?.name} ${coachData?.coach?.surname}`.trim();

  return (
    <UserLayout hideBackElement title="Session Schedule">
      <Tabs
        active={selectedTab}
        tab1={DashboardTabs.UPCOMING}
        tab2={DashboardTabs.HISTORY}
        onTabChange={(data: string) => {
          return dispatch(setDashboardTab(data));
        }}
      />

      <div className={styles.content}>
        <Conditional condition={!sessionsData.isLoading} Fallback={<Loading />}>
          <Conditional
            condition={selectedTab === DashboardTabs.UPCOMING}
            Fallback={
              <HistorySessions
                coachName={coachName}
                historySessions={historySessions}
              />
            }
          >
            <UpcomingSessions
              coachName={coachName}
              upcomingSessions={upcomingSessions}
            />
          </Conditional>
        </Conditional>
      </div>
    </UserLayout>
  );
};

export default Dashboard;
