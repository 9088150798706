import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

const CurrentStep = ({ children: currentComponent, className }: Props) => {
  return <div className={className}>{currentComponent}</div>;
};

export default CurrentStep;
