import React, { FC, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useSWR from 'swr';
import { Table, Typography } from '../../../../../../common';
import { Student } from '../../../../../../types';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';

export const CampusStudents: FC<{ campusId: string }> = ({ campusId }) => {
  const { data } = useSWR(
    `admin/campus/students?campusId=${campusId}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const columns: ColumnDef<Student, keyof Student>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        cell: ({ getValue, row: { original } }) => {
          return (
            <div>
              <Typography marginBottom=".25x" text={getValue()} />
              <Typography color="secondary" text={original.email} />
            </div>
          );
        },
        header: 'Name',
      },
      {
        accessorKey: 'pastSessionsCount',
        cell: ({ getValue }) => {
          return (
            <div>
              <Typography marginBottom=".25x" text={getValue()} />
              <Typography color="secondary" text="0 Past" />
            </div>
          );
        },
        header: 'Sessions',
      },
      {
        accessorKey: 'coachName',
        cell: ({ getValue, row: { original } }) => {
          return (
            <div>
              <Typography marginBottom=".25x" text={getValue()} />
              <Typography color="secondary" text={original.coachEmail} />
            </div>
          );
        },
        header: 'Coach',
      },
    ];
  }, []);

  return <Table columns={columns} data={data || []} />;
};
