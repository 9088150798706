// import useSWR from "swr";
// import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

// export const useFetchArchivedStudent = (coacheeId: string | null) => {
//   const { data, error } = useSWR(
//     coacheeId ? `comm/archive/${coacheeId}` : null,
//     interceptorFetcher,
//     { revalidateOnFocus: false }
//   );

//   return {
//     studentData: data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// };

import axiosInstance from "../../../utils/helpers/http";

export const fetchArchivedConversations = async (studentId: string) => {
  return axiosInstance.get(`comm/archive/${studentId}`);
};
