import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import toast from 'react-hot-toast';
import { useSWRConfig } from 'swr';
import { useSessionOverviewFetch } from '../../api/useSessionOverviewFetch';
import { updateSessionDetails } from '../../api/updateSessionDetails';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { CreateRateSession } from '../../interfaces/create-rate-session';
import UserLayout from '../../../general/components/user-layout/user-layout';
import Button from '../../../register-coachee/components/ui/Button/Button';
import Card from '../../../general/components/card/card';
import Loading from '../../../general/components/loading/loading';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import { cancelSession } from '../../../general/api/cancelSession';
import { setOverlay } from '../../../general/store/generalSlice';
import addToCalendar from '../../../../assets/icons/common/addToCalendar.svg';
import { generateGoogleCalendarLink } from '../../../general/utils/helpers/generateGoogleCalendarLink';
import { fetchIcal } from '../../../general/api/fetchIcal';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import Avatar from '../../../general/components/avatar/avatar';
import { Conditional } from '../../../../common';
import UserInfo from '../user-info/user-info';
import { isDateInFuture } from '../../../../utils/helpers/date';

import styles from './session-overview.module.scss';
import generalStyles from '../../../general/styles/General.module.scss';

const SessionOverview = () => {
  const { mutate } = useSWRConfig();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const { endSession, sessionRating } = state || false;
  const [googleCalendarLink, setGoogleCalendarLink] = useState<string>('');
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [endVideoSession, setEndVideoSession] = useState(endSession);
  const [rate, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const coachData = localStorage.getItem('assignedCoach')
    ? JSON.parse(localStorage.getItem('assignedCoach')!)
    : null;

  const sessionData = useSessionOverviewFetch(id as string);
  const { session } = sessionData;

  const ratingValue = session && session.rating ? session.rating : 0;

  const interestsListing = useAppSelector(state => {
    return state.registerStudent.interests;
  });

  const coachPreferencesListing = useAppSelector(state => {
    return state.registerStudent.coachPreferences;
  });

  const filteredInterests = interestsListing.predefined.filter(interest => {
    return coachData.interests.includes(interest.value);
  });

  const filtredCoachPreferences = coachPreferencesListing.predefined.filter(
    preference => {
      return coachData.preferences.includes(preference.value);
    },
  );

  const startSession = async () => {
    if (session.communication_channel_type === 'video_call') {
      navigate(`/video/${session.id}`, {
        state: {
          rating: session.rating,
          roomNameSet: `${session.id}${coachData.id}${localStorageUser().id}`,
          type: `${session.communication_channel_type}`,
        },
      });
    } else {
      navigate(-1);
    }
  };

  //* Session overview renders
  const coachPlaceholder = {
    gender: coachData && coachData.gender,
    interests: filteredInterests,
    languages: coachData && coachData.coaching_language,
    name:
      coachData &&
      `${coachData.name} ${`${coachData.surname.charAt(0).toUpperCase()}.`}`,
  };

  const rescheduleSession = (
    <Link to={`/coachee/session/${id}/reschedule`}>
      <div className={styles.rescheduleSessionContainer}>
        <div className={styles.rescheduleSession}>Reschedule session</div>
      </div>
    </Link>
  );

  const handleCancelSession = async () => {
    try {
      await cancelSession(id as string);
      navigate('/coachee/dashboard');
      toast.success('Session canceled sucessfully');
    } catch {
      toast.success('Could not cancel session');
    }
  };

  const agenda = (
    <Card standalone title="Agenda">
      {session && session.agenda ? (
        <span>{session.agenda}</span>
      ) : (
        <span className={generalStyles.greyLabel}>No agenda</span>
      )}
    </Card>
  );

  const comments = (
    <Card title="Comments">
      <Conditional
        condition={!!session?.comment}
        Fallback={<span className={generalStyles.greyLabel}>No comments</span>}
      >
        <span>{session?.comment}</span>
      </Conditional>
    </Card>
  );

  const rating = (
    <div
      onClick={() => {
        return onRateSession();
      }}
    >
      <Card standalone title="Rate Session">
        <Rating
          fillColor="#D66361"
          iconsCount={5}
          initialValue={ratingValue}
          readonly
          size={60}
        />

        <Conditional
          condition={session?.comment !== null}
          Fallback={
            <Fragment>
              <div className={styles.separator} />
              <div>{comments}</div>
            </Fragment>
          }
        >
          <div className={styles.separator} />
          {comments}
        </Conditional>
      </Card>
    </div>
  );

  const button = (
    <div className={styles.buttonContainer}>
      <Conditional
        condition={session?.communication_channel_type === 'video_call'}
      >
        <Button
          isDisabled={false}
          label="Join Session"
          onClickHandler={startSession}
        />
      </Conditional>

      <div
        className={styles.cancelButton}
        onClick={() => {
          dispatch(setOverlay(true));
          setShowCancelModal(true);
        }}
      >
        Cancel session
      </div>
    </div>
  );

  const nameRender = (
    <div className={styles.name}>
      {coachData &&
        `${coachData.name} ${coachData.surname.charAt(0).toUpperCase()}.`}
    </div>
  );

  const modalInterests = (
    <div className={styles.plain}>
      {filtredCoachPreferences.map((preference, index) => {
        return `${preference.label}${
          index < filtredCoachPreferences.length - 1 ? ', ' : ''
        }`;
      })}
    </div>
  );

  const rateSession = (rate: number) => {
    setRating(rate);
  };

  const modalRating = (
    <Rating
      fillColor="#D66361"
      iconsCount={5}
      size={60}
      onClick={rateSession}
    />
  );

  const modalComments = (
    <Fragment>
      <p className={styles.comments}>Comments</p>
      <textarea
        className={styles.textarea}
        name="comment"
        placeholder="Please share any comments that you have on the session..."
        rows={5}
        onChange={e => {
          return setComment(e.target.value);
        }}
      />
    </Fragment>
  );

  const coachInfo = (
    <div className={styles.coachInfo}>
      <Avatar image={coachData.profile_img} name={coachData.name} />
      {nameRender}
      {modalInterests}
      {modalRating}
      {modalComments}
    </div>
  );

  const cancelSessionModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowCancelModal(false);
      }}
    >
      <div className={styles.cancelModalContainer}>
        <div className={styles.cancelTitle}>Cancel session?</div>
        <div className={styles.actions}>
          <Button
            isDisabled={false}
            label="Yes, please delete"
            onClickHandler={() => {
              handleCancelSession();
              dispatch(setOverlay(false));
              setShowCancelModal(false);
            }}
          />
          <div
            className={styles.discard}
            onClick={() => {
              dispatch(setOverlay(false));
              setShowCancelModal(false);
            }}
          >
            Discard
          </div>
        </div>
      </div>
    </EmptyModal>
  );

  const onRateSession = () => {
    if (session.rating || sessionRating) {
      setShowModal(false);
      setOverlay(false);
    } else {
      setShowModal(true);
      dispatch(setOverlay(true));
    }
  };

  const handleRateSession = async () => {
    try {
      const rateSession: CreateRateSession = {
        comment,
        rating: rate,
      };

      await updateSessionDetails(session.id as string, rateSession);
      mutate(`session-timeslots/${session.id}`);
      toast.success('Rating successfully saved!');

      resetData();
    } catch (error) {
      return toast.error("Couldn't rate the session");
    }
    setShowModal(false);
    dispatch(setOverlay(false));
    setEndVideoSession(false);
    window.history.replaceState({}, document.title);
  };

  const resetData = () => {
    setComment('');
    setRating(0);
    setIsDisabled(true);
    setShowModal(false);
    dispatch(setOverlay(false));
    setEndVideoSession(false);
    //* Clear location state on page reload and set endSession to undefiend
    window.history.replaceState({}, document.title);
  };

  const navigateAction = () => {
    navigate('/coachee/dashboard');
  };

  useEffect(() => {
    if (rate !== 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rate, comment]);

  const modal = (
    <EmptyModal
      cssClass="largeModal"
      handleClose={() => {
        return resetData();
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>Rate session</div>
        <p className={styles.modalSubtitle}>How did the session go?</p>
        {coachInfo}
        <div className={styles.modalButtonContainer}>
          <Button
            isDisabled={isDisabled}
            label="Rate session"
            onClickHandler={handleRateSession}
          />
          <div className={styles.skipButton} onClick={resetData}>
            Skip
          </div>
        </div>
      </div>
    </EmptyModal>
  );

  //* Add to calendar logic
  const addToCalendarButton = (
    <div
      className={styles.addToCalendarButton}
      onClick={() => {
        setGoogleCalendarLink(
          generateGoogleCalendarLink(
            sessionData?.session?.timeslot,
            sessionData?.session.agenda,
          ),
        );
        dispatch(setOverlay(true));
        setShowCalendarModal(true);
      }}
    >
      <img alt="Add to calendar" src={addToCalendar} />
    </div>
  );

  const calendarModal = (
    <EmptyModal
      cssClass="smallModal"
      handleClose={() => {
        dispatch(setOverlay(false));
        setShowCalendarModal(false);
      }}
      title="Add to calendar"
    >
      <div className={styles.calendarModal}>
        <Button
          isDisabled={false}
          label="iCal/Outlook Calendar"
          onClickHandler={() => {
            fetchIcal(sessionData.session.id);
            setShowCalendarModal(false);
            dispatch(setOverlay(false));
          }}
        />
        <a
          className={styles.googleCalendarLink}
          href={googleCalendarLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button
            isDisabled={false}
            label="Google Calendar"
            onClickHandler={() => {
              setShowCalendarModal(false);
              dispatch(setOverlay(false));
            }}
          />
        </a>
        <div
          className={styles.cancelLabel}
          onClick={() => {
            setShowCalendarModal(false);
            dispatch(setOverlay(false));
          }}
        >
          Cancel
        </div>
      </div>
    </EmptyModal>
  );

  return (
    <UserLayout
      centeredTitle
      customBackAction={navigateAction}
      title="Session Overview"
    >
      {sessionData.isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <UserInfo
            name={coachPlaceholder.name}
            timeslot={session?.session_date}
            userExternal={coachData}
          />
          {showCalendarModal && addModalToPortal(calendarModal)}

          <Conditional condition={isDateInFuture(session?.session_date)}>
            <div className={styles.topActions}>
              {rescheduleSession}
              {addToCalendarButton}
            </div>
          </Conditional>

          <div className={styles.cardsContent}>
            {agenda}

            <Conditional condition={isDateInFuture(session?.session_date)}>
              {rating}
            </Conditional>
          </div>

          <Conditional condition={isDateInFuture(session?.session_date)}>
            {button}
          </Conditional>
        </Fragment>
      )}

      {showModal && addModalToPortal(modal)}
      {endVideoSession && !sessionRating && modal}
      {showCancelModal && addModalToPortal(cancelSessionModal)}
    </UserLayout>
  );
};

export default SessionOverview;
