import { ReactNode, useState } from "react";

import styles from "./SelectableCardIcon.module.scss";

type Props = {
  children?: ReactNode;
  onSelect: (children: any, value: string, isSelected: boolean) => void;
  selected: boolean;
  value: string;
};

const SelectableCardIcon = ({ children, onSelect, selected, value }: Props) => {
  const [isSelected, setIsSelected] = useState(selected);

  const handleSelect = () => {
    setIsSelected(!isSelected);
  };

  return (
    <div
      className={`${styles.selectableCardIcon} ${
        isSelected ? styles.selected : ""
      }`}
      onClick={() => {
        handleSelect();
        onSelect(value, value, !isSelected);
      }}
    >
      {children}
    </div>
  );
};

export default SelectableCardIcon;
