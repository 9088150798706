import React, { FC } from 'react';
import { Divider, Flex, Typography } from '../../../../../../common';
import { DetailsRow } from '../../../details-row';
import { DetailsFormProps } from './details-form.types';
import { formatDate } from '../../../../../../utils/helpers/date';

export const DetailsForm: FC<DetailsFormProps> = props => {
  const { coach } = props;

  return (
    <div>
      <Typography
        marginBottom="x"
        text="About"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginTop="4x">
        <DetailsRow label="Email" value={coach.email} />
        <DetailsRow label="Phone" value={coach.phone_number} />
        <DetailsRow label="Timezone" value={coach.timezone} />
        <DetailsRow label="Native Language" value={coach.native_language} />
        <DetailsRow
          label="Language for Coaching"
          value={coach.coaching_language}
          valueProps={{ transform: 'capitalize' }}
        />
        <DetailsRow
          label="Birthday"
          value={
            coach.date_of_birth
              ? formatDate(coach.date_of_birth, 'MM/DD/YYYY')
              : ''
          }
        />
        <DetailsRow label="Country" value={coach.country} />
        <DetailsRow label="State" value={coach.state} />
        <DetailsRow label="City" value={coach.city} />
        <DetailsRow label="School" value="University of Arizona" />
        <DetailsRow label="Biography" value={coach.biography} />
      </Flex>
    </div>
  );
};
