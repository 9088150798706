import useSWR from 'swr';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useTwilioGeneratedToken = (
  communicationType: string | undefined,
  id: string,
) => {
  const { data, error } = useSWR(
    id ? `/comm/token?grant=${communicationType}&session_id=${id}` : null,
    interceptorFetcher,
    { revalidateOnFocus: false },
  );

  return {
    isError: error,
    isLoading: !error && !data,
    token: data,
  };
};
