import React, { FC, useMemo, useState } from 'react';
import { ColumnDef, Row } from '@tanstack/react-table';
import useSWR, { mutate } from 'swr';
import toast from 'react-hot-toast';
import { Flex, Typography, WrapperMenu } from '../../../../common';
import { EntitySection } from '../entity-section';
import { Student, StudentStatus } from '../../../../types';
import { Drawer } from '../drawer';
import { StudentDrawer } from './components';
import { interceptorFetcher } from '../../../../utils/helpers/interceptorFetcher';
import { CampusDrawerProps } from '../campuses/components/campus-drawer/campus-drawer.types';
import { Menu, MenuItem } from '../../../../common/menu';
import axiosInstance from '../../../../utils/helpers/http';
import { studentStatusNameMapping } from './students.constants';
import { extractStatusColor } from './students.adapters';

export const Students: FC = () => {
  const [search, setSearch] = useState('');
  const [sortTerm, setSortTerm] = useState('full_name');
  const [currentDrawerType, toggleCurrentDrawerType] =
    useState<CampusDrawerProps['type']>(null);
  const [currentStudent, setCurrentStudent] = useState<Student>(null);

  const { data } = useSWR(
    `admin/students?search=${search}&sort=${sortTerm}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const handleRefresh = () => {
    mutate(`admin/students?search=${search}&sort=${sortTerm}`);
  };

  const archiveStudent = async (studentId: string) => {
    try {
      await axiosInstance.put('admin/set-student-status', {
        id: studentId,
        status: 'archived',
      });
      handleRefresh();
      toast.success('Student archived successfully');
    } catch {
      toast.error('An error occurred while archiving student');
    }
  };

  const blockStudent = async (studentId: string) => {
    try {
      await axiosInstance.put('admin/set-student-status', {
        id: studentId,
        status: 'blocked',
      });
      handleRefresh();
      toast.success('Student blocked successfully');
    } catch {
      toast.error('An error occurred while blocking student');
    }
  };

  const columns: ColumnDef<Student, keyof Student>[] = useMemo(() => {
    return [
      {
        accessorKey: 'full_name',
        cell: ({ getValue, row: { original } }) => {
          return (
            <Flex direction="column">
              {original.has_unresolved_escalations && (
                <Typography
                  color="alert1"
                  text="Escalated"
                  variant="subtitle3"
                />
              )}
              <Typography text={getValue()} />

              {[
                StudentStatus.archived,
                StudentStatus.blocked,
                StudentStatus.register_pending_school_entry,
                StudentStatus.register_parental_consent,
                StudentStatus.waitlist,
              ].includes(original.status) && (
                <Typography
                  color="secondary"
                  text={
                    // @ts-ignore
                    studentStatusNameMapping[original.status]
                  }
                  variant="body2"
                />
              )}
            </Flex>
          );
        },
        header: 'Name',
      },
      {
        accessorKey: 'school',
        header: 'School',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'country',
        header: 'Country',
      },
      {
        accessorKey: 'coachName',
        header: 'Coach',
      },
      {
        accessorKey: 'referral_source',
        header: 'Referral Source',
      },
      {
        accessorKey: 'pastSessionsCount',
        header: 'Past Sessions',
      },
      {
        cell: ({ row: { original } }) => {
          return (
            <Flex alignItems="center" gap="2x" justifyContent="flex-end">
              <WrapperMenu
                Menu={
                  <Menu headerText="Update Status">
                    <MenuItem
                      text="Archive Student"
                      onClick={() => {
                        return archiveStudent(original.id);
                      }}
                    />
                    <MenuItem
                      text="Block Student"
                      onClick={() => {
                        return blockStudent(original.id);
                      }}
                    />
                  </Menu>
                }
              />
            </Flex>
          );
        },
        header: 'Actions',
      },
    ];
  }, []);

  return (
    <Drawer
      Content={
        <StudentDrawer
          student={currentDrawerType !== 'create' ? currentStudent : null}
          type={currentDrawerType}
          onHideDrawer={() => {
            return toggleCurrentDrawerType(null);
          }}
          onRefresh={handleRefresh}
        />
      }
      open={currentDrawerType !== null}
    >
      <EntitySection
        columns={columns}
        data={data}
        getRowRibbonColor={({ original }) => {
          return extractStatusColor(original.status);
        }}
        isRowHighlighted={(record: Row<Student>) => {
          return record.original.has_unresolved_escalations;
        }}
        name="Student"
        pluralName="Students"
        showCreateBtn={false}
        sortOptions={[
          { label: 'Name', value: 'full_name' },
          { label: 'Waitlist', value: 'waitlist' },
          {
            label: 'Resolved escalations',
            value: 'resolved_escalations_count',
          },
          {
            label: 'Unresolved escalations',
            value: 'unresolved_escalations_count',
          },
        ]}
        onSearch={setSearch}
        onShowDetails={student => {
          setCurrentStudent(student.original);
          return toggleCurrentDrawerType('details');
        }}
        onSort={setSortTerm}
      />
    </Drawer>
  );
};
