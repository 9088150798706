import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTwilioGeneratedToken } from '../../../general/api/useTwilioGeneratedToken';
import Loading from '../../../general/components/loading/loading';
import RoomComponent from './RoomComponent';

export const VideoComponent = () => {
  const navigate = useNavigate();
  const cognitoGroup = localStorage.getItem('cognitoGroup');
  const { id } = useParams();
  const { state }: any = useLocation();
  const { rating, roomNameSet } = state || {};
  const sessionRating = rating;
  const [roomName, setRoomName] = useState(roomNameSet);
  const [token, setToken] = useState(null);

  const JWTdata = useTwilioGeneratedToken('video_call', id as string);

  const handleEndSession = useCallback(() => {
    if (!!cognitoGroup && cognitoGroup === 'coach') {
      setToken(null);
      setRoomName(null);
      navigate('/coach/dashboard');
      window.location.reload();
    } else if (!!cognitoGroup && cognitoGroup === 'coachee') {
      setToken(null);
      setRoomName(null);
      navigate(`/coachee/session/${id}`, {
        state: {
          endSession: true,
          sessionRating,
        },
      });
      window.location.reload();
    }
  }, [cognitoGroup]);

  useEffect(() => {
    if (!!JWTdata && !JWTdata.isLoading && JWTdata.token.token) {
      setToken(JWTdata.token.token);
      return;
    }

    if (!(!!cognitoGroup && cognitoGroup === 'coachee')) {
      return;
    }

    if (!!JWTdata.token && JWTdata.token.success === false) {
      toast.error('Please wait, your coach will soon join!', {
        id: 'toastId',
      });
    }
  }, [roomName, JWTdata.token]);

  return token ? (
    <div>
      <RoomComponent
        handleEndSession={handleEndSession}
        roomName={roomName}
        token={token}
      />
    </div>
  ) : (
    <Loading />
  );
};
