import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useFetchSessionlessConversation = (studentId: string | null) => {
  const { data, error } = useSWR(
    studentId ? `comm/conversation/${studentId}` : null,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    conversationData: data,
    isError: error,
    isLoading: !error && !data,
  };
};
