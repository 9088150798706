import { FC } from 'react';
import cx from 'classnames';
import { ButtonProps } from './button.types';
import { Typography } from '../typography';
import { ButtonPresets } from './button.presets';
import { getThemeColor } from '../../utils/theme';

import styles from './button.module.scss';

export const Button: FC<ButtonProps> = props => {
  const {
    backgroundColor,
    className,
    disabled,
    icon,
    iconPosition,
    onClick,
    text,
    type,
    variant,
  } = props;

  return (
    <button
      className={cx(styles.button, styles[variant], className)}
      disabled={disabled}
      style={{
        backgroundColor: variant !== 'action' && getThemeColor(backgroundColor),
      }}
      type={type}
      onClick={onClick}
    >
      {iconPosition === 'left' && icon}

      <Typography
        color={disabled ? 'secondary' : 'primary'}
        text={text}
        align="center"
      />

      {iconPosition === 'right' && icon}
    </button>
  );
};

Button.defaultProps = ButtonPresets;
