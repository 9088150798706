import React from 'react';
import { RowData } from '@tanstack/react-table';
import { TableRowProps } from './table-row.types';
import { TableRowCells } from '../table-row-cells';
import { getRowComponentProps } from './helpers/get-row-component-props';

export const TableRow = <TData extends RowData>(
  props: TableRowProps<TData>,
) => {
  const { getCellProps, getRowRibbonColor, isRowHighlighted, reordering, row } =
    props;

  return (
    <tr
      {...getRowComponentProps(props)}
      data-highlighted={isRowHighlighted?.(row) ? 'true' : 'false'}
      data-ribbon-color={getRowRibbonColor?.(row) || ''}
    >
      <TableRowCells
        getCellProps={getCellProps}
        reordering={reordering}
        row={row}
      />
    </tr>
  );
};
