import useSWR from 'swr';
import { fetcher } from '../../../utils/helpers/fetcher';

export const useConfigFetch = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_TRULA_API_BASE_URL}/config`,
    fetcher,
    { revalidateOnFocus: false },
  );

  return {
    configuration: data,
    isError: error,
    isLoading: !error && !data,
  };
};
