import * as yup from 'yup';

export const campusFormSchema = yup.object().shape({
  city: yup.string().required('City is required'),
  contactEmail: yup.string().email('Invalid email').nullable(),
  contactFullName: yup.string().nullable(),
  country: yup.string().required('Country is required'),
  mailingAddress: yup.string().nullable(),
  name: yup.string().required('Name is required'),
  state: yup.string().required('State is required'),
});
