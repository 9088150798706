import { useEffect } from 'react';
import SelectInput from '../ui/SelectInput/SelectInput';

import MaskedInput from '../ui/MaskedInput/MaskedInput';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  handleError,
  handleInputDataChange,
  handleSetCurrentStep,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';
import { useUniversitiesFetch } from '../../api/useUniversitiesFetch';
import { University } from '../../interfaces/University';
import { UseValidation } from '../../hooks/UseValidation';
import { Icon } from '../../../../common';

import styles from './AboutYou.module.scss';

type Props = {
  data: any;
  errors: { [key: string]: string };
};

const AboutYou = ({ data, errors }: Props) => {
  const dispatch = useAppDispatch();
  const storeErrors = useAppSelector(state => {
    return state.registerStudent.errors.aboutYou;
  });
  const universities = useUniversitiesFetch().universities?.map(
    (item: University) => {
      return {
        label: item.name,
        value: item.id,
        ...item,
      };
    },
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      data.coachType !== '' &&
      data.coachingLanguage !== '' &&
      data.birthdate !== '' &&
      data.timezone !== '' &&
      data.engagement !== '' &&
      data.school !== ''
    ) {
      dispatch(setIsButtonDisabled(false));
    } else {
      dispatch(setIsButtonDisabled(true));
    }
  }, [data]);

  const coachingLanguages = useAppSelector(state => {
    return state.registerStudent.listings.coachingLanguages;
  });
  const coachTypes = useAppSelector(state => {
    return state.registerStudent.listings.coachType;
  });
  const engagementOptions = useAppSelector(state => {
    return state.registerStudent.listings.engagementOptions;
  });

  const timezones = useAppSelector(state => {
    return state.registerStudent.listings.timezones;
  });

  const handleDataChange = (data: any) => {
    const error = UseValidation(data, 'aboutYou');
    dispatch(handleError({ currentStep: 'aboutYou', error }));
    dispatch(
      handleInputDataChange({
        aboutYou: data,
      }),
    );
  };

  return (
    <div className={styles.container} data-testid="about-you">
      <SelectInput
        data-testid="coaching-language"
        defaultValue={data.coachingLanguage || ''}
        error={storeErrors.coachingLanguage}
        label="Language for coaching"
        name="coachingLanguage"
        options={coachingLanguages}
        tabIndex={1}
        onChange={data => {
          return handleDataChange(data);
        }}
      />

      <MaskedInput
        className={styles.birthdate}
        data-testid="birthday"
        defaultValue={data.birthdate}
        error={storeErrors.birthdate}
        format="##/##/####"
        label="Birthdate"
        mask="_"
        name="birthdate"
        placeholder="Set date (mm/dd/yyyy)"
        tabIndex={2}
        onChange={handleDataChange}
      >
        <Icon
          className={styles.calendar}
          color="t2"
          name="ui:calendar"
          size="16px"
        />
      </MaskedInput>

      <SelectInput
        defaultValue={data.timezone || ''}
        error={storeErrors.timezone}
        label="Timezone"
        name="timezone"
        options={timezones}
        tabIndex={3}
        onChange={handleDataChange}
      />

      <SelectInput
        defaultValue={data.engagement || ''}
        error={storeErrors.engagement}
        label="How did you hear about us"
        name="engagement"
        options={engagementOptions}
        tabIndex={4}
        onChange={handleDataChange}
      />

      <SelectInput
        defaultValue={data.uuid || ''}
        error={storeErrors.school}
        label="Choose your school"
        name="school"
        options={universities || []}
        tabIndex={5}
        onChange={handleDataChange}
      />

      <SelectInput
        data-testid="coach-type"
        defaultValue={data.coachType || ''}
        error={storeErrors.coachType}
        label="Which best describes you"
        name="coachType"
        options={coachTypes}
        tabIndex={6}
        onChange={data => {
          return handleDataChange(data);
        }}
      />

      <span
        className={styles.customSchool}
        onClick={() => {
          return dispatch(handleSetCurrentStep('schoolEntry'));
        }}
      >
        Can't find your school?
      </span>
    </div>
  );
};

export default AboutYou;
