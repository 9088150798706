export const GoogleIcon = () => {
  return (
    <svg
      fill="none"
      height="1.6rem"
      viewBox="0 0 40 40"
      width="1.6rem"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9779 20.3742C39.9779 18.7355 39.8418 17.5396 39.5474 16.2994H20.3984V23.6961H31.6384C31.4119 25.5342 30.1882 28.3025 27.4688 30.1626L27.4306 30.4103L33.4852 34.9939L33.9046 35.0349C37.757 31.5579 39.9779 26.4422 39.9779 20.3742"
        fill="#4285F4"
      />
      <path
        d="M20.3986 39.8626C25.9052 39.8626 30.5281 38.0908 33.9048 35.0348L27.4689 30.1626C25.7466 31.3363 23.4351 32.1557 20.3986 32.1557C15.0052 32.1557 10.4276 28.6789 8.79581 23.8733L8.55663 23.8931L2.26104 28.6545L2.17871 28.8782C5.53257 35.389 12.4217 39.8626 20.3986 39.8626Z"
        fill="#34A853"
      />
      <path
        d="M8.79548 23.8733C8.36492 22.6331 8.11574 21.3043 8.11574 19.9313C8.11574 18.5582 8.36492 17.2295 8.77283 15.9893L8.76142 15.7252L2.38694 10.8873L2.17838 10.9843C0.796089 13.6861 0.00292969 16.7202 0.00292969 19.9313C0.00292969 23.1424 0.796089 26.1763 2.17838 28.8782L8.79548 23.8733"
        fill="#FBBC05"
      />
      <path
        d="M20.3986 7.70672C24.2283 7.70672 26.8116 9.32336 28.2847 10.6744L34.0407 5.18214C30.5056 1.97099 25.9052 0 20.3986 0C12.4217 0 5.53257 4.47343 2.17871 10.9843L8.77316 15.9893C10.4276 11.1837 15.0052 7.70672 20.3986 7.70672"
        fill="#EB4335"
      />
    </svg>
  );
};
