import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { TooltipProps } from './tooltip.props';
import { TooltipPresets } from './tooltip.presets';
import { Typography } from '../typography';
import { Overlay } from '../overlay';

import styles from './tooltip.module.scss';

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = props => {
  const {
    Content,
    children,
    disabled,
    freezeContentOnHover,
    offsetX,
    offsetY,
    onClick,
    position,
    style,
    text,
    textAlign,
  } = props;

  const [visible, setVisible] = useState(false);
  const id = useId();
  const contentBeingHovered = useRef(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const childrenContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setVisible(true);
    }, 50);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!freezeContentOnHover || !contentBeingHovered.current) {
        setVisible(false);
      }
    }, 50);
  };

  const handleContentMouseEnter = () => {
    contentBeingHovered.current = true;
  };

  const handleContentMouseLeave = () => {
    contentBeingHovered.current = false;
    setVisible(false);
  };

  useEffect(() => {
    if (!visible) {
      return;
    }

    childrenContainerRef.current?.addEventListener(
      'mouseleave',
      handleMouseLeave,
    );

    /**
     * Added to hide the tooltip inside the tooltip content that has a data-tooltip-close attribute
     * */
    let elements: Element[] = [];

    setTimeout(() => {
      elements = Array.from(
        document.querySelectorAll(`[data-tooltip-close="true"]`),
      );
      elements.forEach(link => {
        link.addEventListener('click', handleContentMouseLeave);
      });
    }, 500);

    return () => {
      childrenContainerRef.current?.removeEventListener(
        'mouseleave',
        handleMouseLeave,
      );

      elements?.forEach(link => {
        link.removeEventListener('click', handleContentMouseLeave);
      });
    };
  }, [visible]);

  return (
    <Overlay
      content={
        <div
          className={cx(styles.overlay, styles[position])}
          data-reference-id={id}
          ref={contentRef}
          onClick={onClick}
          onMouseEnter={handleContentMouseEnter}
          onMouseLeave={handleContentMouseLeave}
        >
          {Content || (
            <Typography
              align={textAlign}
              color="white"
              text={text}
              variant="body2"
            />
          )}
        </div>
      }
      disabled={disabled}
      offsetX={offsetX}
      offsetY={offsetY}
      open={visible}
      position={position}
      triggerEvent="manual"
    >
      <div
        className={cx(styles.container, { [styles.disabled]: disabled })}
        ref={childrenContainerRef}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </Overlay>
  );
};

Tooltip.defaultProps = TooltipPresets;
