import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgCameraOff = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={27} cy={27} r={27} fill="#fff" />
      <path
        d="m30.566 25.664 5.092-5.092a.81.81 0 0 1 1.381.572v12.278a.81.81 0 0 1-1.38.572L30.565 28.9m-12.138 5.665h9.71a2.427 2.427 0 0 0 2.427-2.428v-9.71A2.428 2.428 0 0 0 28.139 20h-9.71A2.428 2.428 0 0 0 16 22.428v9.71a2.428 2.428 0 0 0 2.428 2.428v0Z"
        stroke={color}
        strokeWidth={1.618}
        strokeLinecap="round"
      />
    </svg>
  );
};
export default SvgCameraOff;
