import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgPhonePlus = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.333 6.667v-2.5h-2.5V2.5h2.5V0H13v2.5h2.5v1.667H13v2.5h-1.667ZM14.625 15c-1.792 0-3.538-.4-5.24-1.198a15.871 15.871 0 0 1-4.52-3.167 15.87 15.87 0 0 1-3.167-4.52C.9 4.412.5 2.666.5.874.5.625.583.417.75.25A.848.848 0 0 1 1.375 0H4.75a.8.8 0 0 1 .52.188.733.733 0 0 1 .272.479l.541 2.916c.028.195.025.372-.01.531a.856.856 0 0 1-.24.428l-2.02 2.041c.583 1 1.316 1.938 2.198 2.813.881.875 1.85 1.632 2.906 2.27l1.958-1.958a1.17 1.17 0 0 1 .49-.281c.201-.063.399-.08.593-.052l2.875.583a.847.847 0 0 1 .48.281.762.762 0 0 1 .187.511v3.375c0 .25-.083.458-.25.625a.848.848 0 0 1-.625.25ZM3.021 5l1.375-1.375-.354-1.958H2.188c.069.57.166 1.132.291 1.687.125.556.306 1.104.542 1.646Zm7.458 7.458a10.342 10.342 0 0 0 3.354.834v-1.834l-1.958-.396-1.396 1.396Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgPhonePlus;
