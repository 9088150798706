import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useFetchReadOnlyChat = (sid: string) => {
  const { data, error } = useSWR(`comm/messages/${sid}`, interceptorFetcher, {
    revalidateOnFocus: false,
  });

  return {
    chatData: data,
    isError: error,
    isLoading: !error && !data,
  };
};
