import React, { FC, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useSWR, { mutate } from 'swr';
import { Flex, Icon } from '../../../../common';
import { Admin } from '../../../../types';
import { EntitySection } from '../entity-section';
import { Drawer } from '../drawer';
import { AdminsDrawer } from './components';
import { interceptorFetcher } from '../../../../utils/helpers/interceptorFetcher';
import { StudentDrawerProps } from '../students/components/student-drawer/student-drawer.types';

export const Admins: FC = () => {
  const [currentDrawerType, toggleCurrentDrawerType] =
    useState<StudentDrawerProps['type']>(null);
  const [search, setSearch] = useState('');
  const [currentAdmin, setCurrentAdmin] = useState<Admin>(null);

  const { data } = useSWR(`admin/admins?search=${search}`, interceptorFetcher, {
    revalidateOnFocus: false,
  });

  const handleRefresh = () => {
    mutate(`admin/admins?search=${search}`);
  };

  const columns: ColumnDef<Admin, keyof Admin>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'surname',
        header: 'Surname',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
      },
      {
        cell: ({ row: { original } }) => {
          return (
            <Flex alignItems="center" gap="2x" justifyContent="flex-end">
              <div
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  setCurrentAdmin(original);
                  toggleCurrentDrawerType('edit');
                }}
              >
                <Icon color="t2" name="actions:pencil" variant="button" />
              </div>
            </Flex>
          );
        },
        header: 'Actions',
      },
    ];
  }, []);

  return (
    <Drawer
      Content={
        <AdminsDrawer
          admin={currentDrawerType !== 'create' ? currentAdmin : null}
          type={currentDrawerType}
          onEdit={() => {
            toggleCurrentDrawerType('edit');
          }}
          onHideDrawer={() => {
            return toggleCurrentDrawerType(null);
          }}
          onRefresh={handleRefresh}
        />
      }
      open={currentDrawerType !== null}
    >
      <EntitySection
        columns={columns}
        data={data}
        name="Admin"
        pluralName="Admins"
        onCreate={() => {
          return toggleCurrentDrawerType('create');
        }}
        onSearch={setSearch}
        onShowDetails={campus => {
          setCurrentAdmin(campus.original);
          return toggleCurrentDrawerType('details');
        }}
      />
    </Drawer>
  );
};
