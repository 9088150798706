import { useNavigate } from 'react-router-dom';
import GenericInfo from '../GenericInfo/GenericInfo';
import pageNotFound from '../../../../assets/icons/common/page_not_found.svg';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';

const PageNotFound = () => {
  const navigate = useNavigate();
  const cognitoGroup = localStorage.getItem('cognitoGroup');
  const user = localStorageUser();

  const handleBackAction = () => {
    switch (cognitoGroup) {
      case null:
        navigate('/login');
        break;
      case 'coachee':
        if (user.status === STATUSES.ACTIVE) {
          navigate('/coachee/dashboard');
        } else if (user.status === STATUSES.REGISTER_CHOOSE_YOUR_COACH) {
          navigate('/coachee/coach-matching');
        } else if (user.status === STATUSES.CHANGE_COACH) {
          navigate('/coachee/coach-matching');
        } else {
          navigate('/coachee/register');
        }
        break;
      case 'coach':
        navigate('/coach/dashboard');
        break;
      case 'admin':
        navigate('/admin');
        break;
      default:
        navigate('/login');
        break;
    }
  };
  const button = (
    <Button
      isDisabled={false}
      label="Back"
      onClickHandler={() => {
        return handleBackAction();
      }}
    />
  );
  return (
    <GenericInfo
      button={button}
      image={pageNotFound}
      subtitle="The page you're looking for doesn't exist or has been moved."
      title="Page not found"
    />
  );
};

export default PageNotFound;
