import { Message } from "@twilio/conversations";
import moment from "moment";
import { localStorageUser } from "../../../../utils/helpers/localStorageUser";
import { MessageThread } from "../../interfaces/MessageThread";

import styles from "./MessageThreadCard.module.scss";

type Props = {
  message: Message;
  outgoing: boolean;
};

const MessageThreadCard = ({ message, outgoing }: Props) => {
  return (
    <div
      className={`${styles.container} ${
        outgoing ? styles.containerBlue : styles.container
      }`}
    >
      <div className={styles.cardContainer}>
        <div className={styles.timeslotContainer}>
          <span>
            {moment(message.dateCreated)
              .tz(localStorageUser().timezone)
              .format("hh:mm a")}
          </span>
        </div>
        <div
          className={`${styles.separator} ${
            !outgoing ? styles.separatorWhite : styles.separator
          }`}
         />
        <div className={styles.messageInfo}>
          <div className={styles.messageText}>{message.body}</div>
        </div>
      </div>
    </div>
  );
};

export default MessageThreadCard;
