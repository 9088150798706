import { FC } from 'react';
import { DetailsRowProps } from './details-row.types';
import { Conditional, Grid, Typography } from '../../../../common';

export const DetailsRow: FC<DetailsRowProps> = props => {
  const { label, value, valueProps } = props;

  return (
    <Grid
      columns="200px 1fr"
      style={{ borderBottom: '1px solid rgba(214, 218, 226, 1)' }}
      verticalPadding="x"
    >
      <Typography
        color="secondary"
        text={label}
        textStyle="bold"
        variant="body2"
      />
      <Conditional condition={typeof value === 'string'} Fallback={value}>
        <Typography text={value as string} variant="body2" {...valueProps} />
      </Conditional>
    </Grid>
  );
};
