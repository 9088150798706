import React, { FC, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useSWR, { mutate } from 'swr';
import { Flex, Icon } from '../../../../common';
import { Campus } from '../../../../types';
import { EntitySection } from '../entity-section';
import { Drawer } from '../drawer';
import { CampusDrawer } from './components';
import { CampusDrawerProps } from './components/campus-drawer/campus-drawer.types';
import { interceptorFetcher } from '../../../../utils/helpers/interceptorFetcher';

export const Campuses: FC = () => {
  const [currentDrawerType, toggleCurrentDrawerType] =
    useState<CampusDrawerProps['type']>(null);
  const [currentCampus, setCurrentCampus] = useState<Campus>(null);
  const [search, setSearch] = useState('');

  const { data } = useSWR(
    `admin/campuses?search=${search}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const columns: ColumnDef<Campus, keyof Campus>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'studentsCount',
        header: 'Students',
      },
      {
        accessorKey: 'coachesCount',
        header: 'Coaches',
      },
      {
        accessorKey: 'sessionsRating',
        header: 'Session Rating',
      },
      {
        accessorKey: 'pastSessionsCount',
        header: 'Past Sessions',
      },
      {
        cell: ({ row: { original } }) => {
          return (
            <Flex alignItems="center" gap="2x" justifyContent="space-between">
              <Icon
                color="t2"
                cursor="pointer"
                name="actions:pencil"
                variant="button"
                onClick={event => {
                  event.stopPropagation();
                  setCurrentCampus(original);
                  return toggleCurrentDrawerType('edit');
                }}
              />
              <Icon color="t2" name="actions:three_dots" />
            </Flex>
          );
        },
        header: 'Actions',
      },
    ];
  }, []);

  return (
    <Drawer
      Content={
        <CampusDrawer
          campus={currentDrawerType !== 'create' ? currentCampus : null}
          type={currentDrawerType}
          onEdit={() => {
            return toggleCurrentDrawerType('edit');
          }}
          onHideDrawer={() => {
            return toggleCurrentDrawerType(null);
          }}
          onRefresh={() => {
            mutate(`admin/campuses?search=${search}`);
          }}
        />
      }
      open={currentDrawerType !== null}
    >
      <EntitySection
        columns={columns}
        data={data}
        name="Campus"
        pluralName="Campuses"
        onCreate={() => {
          return toggleCurrentDrawerType('create');
        }}
        onSearch={setSearch}
        onShowDetails={campus => {
          setCurrentCampus(campus.original);
          return toggleCurrentDrawerType('details');
        }}
      />
    </Drawer>
  );
};
