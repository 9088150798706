import { useEffect } from 'react';
import VerifyPhoneNumber from '../../VerifyPhoneNumber/VerifyPhoneNumber';
import Password from '../../../../register-coachee/components/ui/Password/Password';
import { IPasswordData } from '../../../../../utils/interfaces/RegisterStudent';

import styles from './SetNewPassword.module.scss';

type Props = {
  onOtpCodeChange: (otpCode: string) => void;
  onPasswordChange: (passwordData: IPasswordData) => void;
  resendConfirmationCode: () => void;
};

const SetNewPassword = ({
  onOtpCodeChange,
  onPasswordChange,
  resendConfirmationCode,
}: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePasswordChange = (passwordData: IPasswordData) => {
    onPasswordChange(passwordData);
  };

  const handleOtpChange = (code: string) => {
    onOtpCodeChange(code);
  };

  const handleResendConfirmationCode = () => {
    resendConfirmationCode();
  };

  return (
    <div className={styles.container}>
      <VerifyPhoneNumber
        onResendConfirmationCode={() => {
          return handleResendConfirmationCode();
        }}
        onVerifyPhoneNumber={handleOtpChange}
      />

      <p className={styles.subtitle}>Please set your new password</p>

      <Password
        errorMessage="Passwords must match"
        label="Password"
        placeholder="Set your password"
        repeatLabel="Repeat Password"
        repeatPlaceholder="Repeat Password"
        onChangePasswordHandler={handlePasswordChange}
      />
    </div>
  );
};

export default SetNewPassword;
