import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/UseAuth';
import {
  forgotPasswordSteps,
  stepKeys,
  stepValidationErrors,
} from '../../../utils/constants/ForgotPassword';
import { initialPasswordData } from '../../../../register-coachee/utils/constants/RegisterStudent';
import CurrentStep from '../../../../general/components/current-step/current-step';
import Button from '../../../../register-coachee/components/ui/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  removeForgotPasswordErrors,
  setIsButtonDisabled,
} from '../../../../register-coachee/store/registerStudentSlice';
import { Layout } from '../../../../../common';

import styles from './Wizard.module.scss';

const ForgotPasswordWizard = () => {
  const navigate = useNavigate();
  const { cognitoForgotPassword, cognitoForgotPasswordSubmit } = useAuth();
  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [passwordData, setPasswordData] = useState(initialPasswordData);
  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState(
    forgotPasswordSteps[stepKeys.forgottenPassword],
  );

  const CurrentStepComponent = currentStep ? currentStep.component : '';

  const currentStepErrors = useAppSelector(state => {
    return state.registerStudent.errors[currentStep.key];
  });

  const isStoreButtonDisabled = useAppSelector(state => {
    return state.registerStudent.isButtonDisabled;
  });

  const validateChild = useCallback(async () => {
    if (currentStep.customValidation) {
      switch (currentStep.key) {
        case stepKeys.forgottenPassword:
          setIsDisabled(
            (currentStepErrors && Object.keys(currentStepErrors).length > 0) ||
              isStoreButtonDisabled,
          );
          break;
        case stepKeys.setNewPassword:
          if (
            passwordData.data &&
            passwordData.data.password !== '' &&
            passwordData.data.repeatPassword !== '' &&
            !passwordData.isInvalid &&
            passwordData.isMatch &&
            otpCode.length === 6
          ) {
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
          break;
        default:
          break;
      }
    }
  }, [
    currentStep,
    isStoreButtonDisabled,
    currentStepErrors,
    passwordData,
    otpCode,
  ]);

  const handleNextStep = async () => {
    toast.dismiss();
    if (currentStep.key === stepKeys.forgottenPassword && !isDisabled) {
      try {
        await cognitoForgotPassword(email);
        setCurrentStep(forgotPasswordSteps.setNewPassword);
        toast.dismiss();
      } catch (error: unknown) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    } else if (currentStep.key === stepKeys.setNewPassword && !isDisabled) {
      try {
        await cognitoForgotPasswordSubmit(
          email,
          otpCode,
          passwordData.data.password,
        );
        toast.success('Successfully changed password!');
        navigate('/login');
      } catch (error: unknown) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    } else if (stepValidationErrors[currentStep.key]) {
      toast.error(stepValidationErrors[currentStep.key], {
        style: {
          fontFamily: 'Source Sans 3',
          fontSize: '0.875rem',
        },
      });
    }
  };

  const handlePreviousStep = () => {
    dispatch(removeForgotPasswordErrors({}));
    toast.dismiss();
    navigate('/login');
  };

  const handleResendConfirmationCode = async () => {
    try {
      await cognitoForgotPassword(email);
      toast.success('Verification code has been sent!');
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  //* Validate the data when a step is changed
  useEffect(() => {
    validateChild();
  }, [currentStep, validateChild]);

  useEffect(() => {
    if (email !== '') {
      dispatch(setIsButtonDisabled(false));
    } else {
      dispatch(setIsButtonDisabled(true));
    }
  }, [email]);

  return (
    <Layout
      Footer={
        <Button
          isDisabled={isDisabled}
          label={currentStep.button}
          onClickHandler={() => {
            return handleNextStep();
          }}
        />
      }
      hasBackButton={currentStep.key === 'forgottenPassword'}
      subtitle={currentStep.subtitle}
      title={currentStep.title}
      onBackButtonClick={handlePreviousStep}
    >
      <CurrentStep className={styles.currentComponent}>
        <CurrentStepComponent
          resendConfirmationCode={handleResendConfirmationCode}
          onEmailChangeHandler={(data: {
            [key: string]: { [key: string]: string };
          }) => {
            return setEmail(data.forgottenPassword.email);
          }}
          onOtpCodeChange={(data: string) => {
            return setOtpCode(data);
          }}
          onPasswordChange={(passData: any) => {
            return setPasswordData(passData);
          }}
        />
      </CurrentStep>
    </Layout>
  );
};

export default ForgotPasswordWizard;
