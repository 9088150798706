import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';

//* Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TRULA_API_BASE_URL,
  timeout: 10000,
});

//* Step-2: Create request, response & error handlers

// Request
const requestHandler = async (
  request: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  try {
    const data = await Auth.currentSession();
    const jwtToken = data.getAccessToken().getJwtToken();
    request!.headers!.Authorization = `Bearer ${jwtToken}`;
  } catch (error: any) {
    console.error(error);
  }
  return request;
};

const requestErrorHandler = (error: AxiosError): Promise<AxiosError> => {
  //   console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

// Response
const responseHandler = (response: AxiosResponse): AxiosResponse => {
  //   console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const responseErrorHandler = async (error: AxiosError): Promise<AxiosError> => {
  switch (error.response?.status) {
    case 401:
      localStorage.clear();
      window.location.replace(`/login?redirectUrl=${window.location.pathname}`);
      break;
    case 403:
      window.location.replace('/denied');
      break;
    case 404:
      // window.location.replace('/404');
      break;
    default:
      break;
  }
  return Promise.reject(error);
};
//* Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
axiosInstance.interceptors.request.use(
  request => {
    return requestHandler(request);
  },
  error => {
    return requestErrorHandler(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return responseHandler(response);
  },
  error => {
    return responseErrorHandler(error);
  },
);

//* Step-4: Export the newly created Axios instance to be used in different locations.
export default axiosInstance;
