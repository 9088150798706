import { Navigate, Route } from 'react-router-dom';
import React, { Fragment } from 'react';
import PermissionDenied from '../features/authentication/components/PermissionDenied/PermissionDenied';
import { APP_ROUTES } from './constants';
import PageNotFound from '../features/authentication/components/PageNotFound/PageNotFound';
import Login from '../pages/Login/Login';
import RegisterStudent from '../pages/RegisterStudent/RegisterStudent';
import CoachMatching from '../pages/CoachMatching/CoachMatching';
import Dashboard from '../features/coachee/components/dashboard/dashboard';
import SecureRoute from './SecureRoute';
import Profile from '../features/coachee/components/profile/profile';
import SessionOverview from '../features/coachee/components/session-overview/session-overview';
import Reschedule from '../features/coachee/components/reschedule/reschedule';
import CoachDashboard from '../features/coach/components/CoachDashboard/CoachDashboard';
import CoachSessionOverview from '../features/coach/components/CoachSessionOverview/CoachSessionOverview';
import CreateSession from '../pages/CreateSession/create-session';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import StudentProfile from '../pages/StudentOverview/StudentProfile';
import CoachMessageThreadList from '../features/coach/components/CoachMessageThreadList/CoachMessageThreadList';
import CoachingTimeslots from '../features/coach/components/coaching-timeslots/coaching-timeslots';
import CoachProfileOverview from '../features/coach/components/CoachProfileOverview/CoachProfileOverview';
import VoiceProvider from '../features/Twilio/Voice/VoiceProvider';
import StudentConversationsOverview from '../features/coach/components/StudentConversationsOverview/StudentConversationsOverview';
import ConversationReadOnly from '../features/coach/components/ConversationReadOnly/ConversationReadOnly';
import {
  AdminDashboard,
  Calendar,
  Campuses,
  CoachCoordinators,
  Coaches,
  Students,
} from '../features/admin';
import { Video } from '../pages/Video';
import { Admins } from '../features/admin/components/admins';
import Export from '../features/admin/components/export/export';
import { Welcome } from '../pages/welcome';

const MainRoutes = () => {
  return (
    <Fragment>
      {/* Coachee routes */}

      <SecureRoute>
        <Route element={<Login />} path={APP_ROUTES.LOGIN} />
        <Route element={<Video />} path="/video/:id" />

        <Route path="/coachee">
          <Route
            element={<RegisterStudent />}
            path={`/coachee/${APP_ROUTES.REGISTER}`}
          />
          <Route
            element={<CoachMatching />}
            path={`/coachee/${APP_ROUTES.COACH_MATCHING}`}
          />
          <Route
            element={<Dashboard />}
            path={`/coachee/${APP_ROUTES.DASHBOARD}`}
          />
          <Route element={<Profile />} path="/coachee/profile" />
          <Route element={<SessionOverview />} path="/coachee/session/:id" />
          <Route
            element={<Reschedule />}
            path="/coachee/session/:id/reschedule"
          />

          <Route element={<Dashboard />} path="/coachee" />
        </Route>

        {/* coach routes */}
        <Route>
          <Route element={<CoachDashboard />} path="/coach/dashboard" />
          <Route element={<CoachSessionOverview />} path="/coach/session/:id" />
          <Route element={<CreateSession />} path="/coach/session/new" />
          <Route
            element={<Reschedule />}
            path="/coach/session/:id/reschedule"
          />
          <Route element={<StudentProfile />} path="/coach/students/:id" />
          <Route
            element={<CoachingTimeslots />}
            path="/coach/coaching-timeslots"
          />
          <Route element={<CoachProfileOverview />} path="/coach/profile" />

          {/* CONVERSATIONS */}

          {/* Listing of conversations with an ongoing student */}
          <Route
            element={<StudentConversationsOverview />}
            path="/coach/students/:studentId/conversations"
          />

          {/* Listing of conversations with an archived students */}
          <Route
            element={<StudentConversationsOverview />}
            path="/coach/students/:studentId/conversations/archived"
          />

          {/* Session conversation */}
          <Route
            element={<CoachMessageThreadList />}
            path="/coach/session/:sessionId/conversation"
          />

          {/* New conversation without a session */}
          <Route
            element={<CoachMessageThreadList />}
            path="/coach/conversations/:studentId/new"
          />

          {/* Readonly conversation in the past - Twilio */}
          {/* Archived */}
          <Route
            element={<ConversationReadOnly />}
            path="/coach/students/:name/conversations/:conversationId/archived"
          />

          {/* Ongoing */}
          <Route
            element={<ConversationReadOnly />}
            path="/coach/students/:studentId/:name/conversations/:conversationId"
          />

          <Route
            element={<VoiceProvider />}
            path="/coach/session/:id/phone_call/:roomName"
          />

          <Route element={<Navigate to="/coach/dashboard" />} path="/coach/*" />
        </Route>

        {/* Admin routes */}
        <Route element={<AdminDashboard />} path="/admin">
          <Route element={<Campuses />} index />
          <Route element={<Campuses />} path="dashboard" />
          <Route element={<Campuses />} path="campuses" />
          <Route element={<Students />} path="students" />
          <Route element={<Coaches />} path="coaches" />
          <Route element={<Admins />} path="admins" />
          <Route element={<CoachCoordinators />} path="coach-coordinators" />
          <Route element={<Calendar />} path="calendar" />
          <Route element={<Export />} path="export" />
        </Route>

        <Route element={<ForgotPassword />} path={APP_ROUTES.FORGOT_PASSWORD} />
        <Route element={<PermissionDenied />} path={APP_ROUTES.DENIED} />

        <Route element={<Welcome />} path="/" />
        <Route element={<PageNotFound />} path="*" />
        <Route element={<PageNotFound />} path="/404" />
      </SecureRoute>
    </Fragment>
  );
};

export default MainRoutes;
