import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { RowData } from '@tanstack/react-table';
import { CSS } from '@dnd-kit/utilities';
import { DraggableRowProps } from './table-draggable-row.types';
import { TableRowCells } from '../table-row-cells';
import { getRowComponentProps } from '../table-row/helpers/get-row-component-props';

import styles from './table-draggable-row.module.scss';

export const TableDraggableRow = <TData extends RowData>(
  props: DraggableRowProps<TData>,
) => {
  const { getCellProps, isRowHighlighted, reordering, row } = props;

  const isReorderEnabled = reordering?.isActive;

  const { attributes, listeners, setNodeRef, transform, transition } =
    // @ts-ignore
    useSortable({
      disabled: !isReorderEnabled,
      id: row.id,
    });

  if (!row?.id) {
    throw new Error('DraggableRow must have a non null id prop');
  }

  return (
    <tr
      ref={setNodeRef}
      {...getRowComponentProps(props)}
      {...listeners}
      {...attributes}
      className={styles.rowWrapper}
      data-highlighted={isRowHighlighted?.(row) ? 'true' : 'false'}
      data-rowid={row.id}
      style={{ transform: CSS.Transform.toString(transform), transition }}
    >
      <TableRowCells
        getCellProps={getCellProps}
        reordering={reordering}
        row={row}
      />
    </tr>
  );
};
