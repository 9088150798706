import cx from 'classnames';
import { FC } from 'react';
import cancelIcon from '../../../../../assets/icons/common/cancel.png';

import styles from './CustomEntry.module.scss';

type Props = {
  inWizard?: boolean;
  label: string;
  removeSelection: (value: string) => void;
};

const CustomEntry: FC<Props> = props => {
  const { inWizard, label, removeSelection } = props;
  return (
    <div
      className={cx(styles.container, {
        [styles.selected]: inWizard,
      })}
    >
      {label}
      <img
        alt="Remove item"
        className={styles.cancelIcon}
        src={cancelIcon}
        onClick={() => {
          return removeSelection(label);
        }}
      />
    </div>
  );
};

export default CustomEntry;
