import React, { FC } from 'react';
import { FlexProps } from './flex.types';
import { FlexPresets } from './flex.presets';
import { extractSpacingProps } from '../../helpers/spacing';

export const Flex: FC<FlexProps> = props => {
  const {
    alignItems,
    children,
    className,
    direction,
    gap,
    height,
    justifyContent,
    variant,
    width,
  } = props;

  return (
    <div
      className={className}
      style={{
        alignItems,
        display: variant,
        flexDirection: direction,
        gap,
        height,
        justifyContent,
        width,
        ...extractSpacingProps(props),
      }}
    >
      {children}
    </div>
  );
};

Flex.defaultProps = FlexPresets;
