import React, { useEffect, useRef, useState } from 'react';
import {
  CellContext,
  ColumnOrderState,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { closestCenter, DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Conditional } from '../conditional';
import { TableProps } from './table.types';
import { Typography } from '../typography';
import { useDragAndDropRows } from './use-drag-and-drop-rows';
import {
  TableDraggableRow,
  TableHeader,
  TableRow,
  TableRowOverlay,
} from './components';

import styles from './table.module.scss';

const DefaultCell = <TData extends RowData>({
  getValue,
}: CellContext<TData, unknown>) => {
  return <Typography text={(getValue() as string) || '-'} />;
};

/**
 * See https://components.gantri.com/?path=/story/core-table for usage examples.
 *
 * Official docs: https://tanstack.com/table/v8/docs/guide/overview
 */
export const Table = <TData extends RowData>(props: TableProps<TData>) => {
  const {
    columns,
    data: startingData,
    getCellProps,
    getHeaderCellProps,
    getRowProps,
    getRowRibbonColor,
    isRowHighlighted,
    onRowClick,
    options,
    reordering,
  } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([]);
  const [data, setData] = useState<TData[]>(startingData);

  const table = useReactTable({
    columns,
    data,
    defaultColumn: { cell: DefaultCell },
    getCoreRowModel: getCoreRowModel(),
    getRowId: ({ id }, index) => {
      return id ? String(id) : String(index);
    },
    // @ts-expect-error
    getSubRows: row => {
      const fallback: TData[] = [];

      return row?.subRows || fallback;
    },
    onColumnOrderChange: setColumnOrder,
    ...options,
    state: {
      ...options?.state,
      columnOrder,
    },
  });

  const { rows } = table.getRowModel();

  const { activeIndex, handleDragEnd, handleDragStart, isDragging, sensors } =
    useDragAndDropRows({ data, reordering, rows, setData });

  useEffect(() => {
    setData(startingData);
  }, [startingData]);

  const TableRowElement = reordering ? TableDraggableRow : TableRow;

  const TableRows = (
    <React.Fragment>
      {rows.map(row => {
        return (
          <TableRowElement
            customRowProps={getRowProps?.(row, rows) || {}}
            getCellProps={getCellProps}
            getRowRibbonColor={getRowRibbonColor}
            isRowHighlighted={isRowHighlighted}
            key={row.id}
            reordering={reordering}
            row={row}
            onRowClick={onRowClick}
          />
        );
      })}
    </React.Fragment>
  );

  const TableComponent = (
    <div
      className={styles.tableContainer}
      data-table-container=""
      ref={tableContainerRef}
    >
      <table className={styles.table} ref={tableRef}>
        <TableHeader
          getHeaderCellProps={getHeaderCellProps}
          reordering={reordering}
          table={table}
        />

        <tbody className={styles.tbody}>
          <Conditional condition={!!reordering} Fallback={TableRows}>
            <SortableContext
              items={data.map(({ id }) => {
                return { id: String(id) };
              })}
              strategy={verticalListSortingStrategy}
            >
              {TableRows}
            </SortableContext>
          </Conditional>
        </tbody>
      </table>
    </div>
  );

  return (
    <Conditional condition={!!reordering} Fallback={TableComponent}>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        {TableComponent}

        <Conditional condition={isDragging || activeIndex !== -1}>
          <TableRowOverlay
            getCellProps={getCellProps}
            getHeaderCellProps={getHeaderCellProps}
            getHeaderGroups={table.getHeaderGroups}
            reordering={reordering}
            row={rows[activeIndex]}
            table={tableRef.current as HTMLElement}
          />
        </Conditional>
      </DndContext>
    </Conditional>
  );
};
