import React, { FC, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { Button, Flex, Form, Typography } from '../../../../../../common';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { SelectV2 } from '../../../../../register-coachee/components/ui/select-v2';
import {
  interestsArray,
  languages,
  personalityDescriptorsArray,
  timezonesArray,
} from '../../coaches.constants';
import SelectInput from '../../../../../register-coachee/components/ui/SelectInput/SelectInput';
import {
  countries,
  states,
} from '../../../../../coach/utils/constants/USStates';
import Textarea from '../../../../../register-coachee/components/ui/Textarea/Textarea';
import { EditFormProps } from './edit-form.types';
import { University } from '../../../../../register-coachee/interfaces/University';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';
import { formatDate } from '../../../../../../utils/helpers/date';
import { coachFormSchema } from '../create-form/create-form.schema';
import axiosInstance from '../../../../../../utils/helpers/http';
import {
  phoneToE164Format,
  phoneToReadableFormat,
} from '../../../../../../utils/helpers/helpers';

export const EditCoachFormContent: FC<EditFormProps> = props => {
  const { coach, imageUrl, onHideDrawer, onRefresh } = props;
  const [biography, setBiography] = useState(coach.biography || '');

  const { data: universitiesData } = useSWR<University[]>(
    `universities`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const universities = useMemo(() => {
    return (
      universitiesData?.map(university => {
        return {
          label: university.name,
          value: university.id,
        };
      }) || []
    );
  }, [universitiesData]);

  const { control, formState, handleSubmit, register } = useForm({
    defaultValues: {
      ...coach,
      campus_id: coach.campus_id || universities?.[0]?.value,
      date_of_birth: formatDate(coach.date_of_birth, 'MM/DD/YYYY'),
      mailing_address: coach.mailing_address || '',
      phone_number: phoneToReadableFormat(coach.phone_number),
      profile_img: imageUrl || coach.profile_img || '',
    },
    resolver: yupResolver(coachFormSchema),
  });

  const [selectedPersonalityDescriptors, setSelectedPersonalityDescriptors] =
    useState(coach.personality_descriptors || []);
  const [selectedInterests, setSelectedInterests] = useState(
    coach.interests || [],
  );
  const [selectedLanguagesForCoaching, setSelectedLanguagesForCoaching] =
    useState(coach.coaching_language || []);

  const {
    field: { onChange: onStateChange, value: stateValue, ...restStateField },
  } = useController({ control, name: 'state' });

  const {
    field: {
      onChange: onUniversityChange,
      value: universityValue,
      ...restUniversityField
    },
  } = useController({ control, name: 'campus_id' });

  const {
    field: {
      onChange: onTimezoneChange,
      value: timezoneValue,
      ...restTimezoneField
    },
  } = useController({ control, name: 'timezone' });

  const {
    field: {
      onChange: onNativeLanguageChange,
      value: nativeLanguageValue,
      ...restNativeLanguageField
    },
  } = useController({ control, name: 'native_language' });

  const {
    field: {
      onChange: onCountryChange,
      value: countryValue,
      ...restCountryField
    },
  } = useController({ control, name: 'country' });

  const { errors } = formState;

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.put('admin/coaches', {
        ...data,
        biography,
        coaching_language: selectedLanguagesForCoaching,
        interests: selectedInterests,
        personality_descriptors: selectedPersonalityDescriptors,
        phone_number: phoneToE164Format(data.phone_number),
        profile_img: imageUrl || coach.profile_img || '',
      });
      toast.success('Coach created successfully');
      onRefresh();
      onHideDrawer();
      // @ts-ignore
    } catch (error: AxiosError<any>) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while updating coach',
      );
    }
  };

  return (
    <Form
      Footer={
        <React.Fragment>
          <Button
            iconPosition="right"
            text="Discard"
            variant="action"
            onClick={onHideDrawer}
          />
          <Button iconPosition="right" text="Save Changes" type="submit" />
        </React.Fragment>
      }
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Flex direction="column" gap="2x">
        <InputV2
          errorMessage={errors.name?.message}
          label="Full Name"
          placeholder="Enter name"
          {...register('name')}
        />

        <InputV2
          errorMessage={errors.email?.message}
          label="Email"
          placeholder="email@domain.com"
          {...register('email')}
        />

        <InputMask mask="+1 (999) 999-9999" {...register('phone_number')}>
          {
            // @ts-ignore
            (inputProps: any) => {
              return (
                <InputV2
                  {...inputProps}
                  errorMessage={errors.phone_number?.message}
                  label="Phone"
                  placeholder="+1 234 567 8912"
                  type="tel"
                />
              );
            }
          }
        </InputMask>

        <SelectV2
          errorMessage={errors.timezone?.message}
          label="Timezone"
          options={timezonesArray}
          placeholder="Choose Timezone"
          value={
            timezoneValue
              ? timezonesArray.find(item => {
                  return item.value === timezoneValue;
                })
              : null
          }
          onChange={option => {
            return onTimezoneChange(option ? option.value : option);
          }}
          {...restTimezoneField}
        />

        <SelectV2
          errorMessage={errors.native_language?.message}
          label="Native Language"
          options={languages}
          placeholder="Choose Native Language"
          value={
            nativeLanguageValue
              ? languages.find(item => {
                  return item.value === nativeLanguageValue;
                })
              : null
          }
          onChange={option => {
            return onNativeLanguageChange(option ? option.value : option);
          }}
          {...restNativeLanguageField}
        />

        <SelectInput
          defaultValue={selectedLanguagesForCoaching}
          isMulti
          label="Language for Coaching"
          name="coaching_language"
          options={languages}
          placeholder="Select Language for Coaching"
          onChangeMultiple={event => {
            setSelectedLanguagesForCoaching(event.coaching_language);
          }}
        />

        <InputMask mask="99/99/9999" {...register('date_of_birth')}>
          {
            // @ts-ignore
            (inputProps: any) => {
              return (
                <InputV2
                  {...inputProps}
                  errorMessage={errors.date_of_birth?.message}
                  label="Phone"
                  placeholder="MM/DD/YYYY"
                  type="tel"
                />
              );
            }
          }
        </InputMask>

        <SelectV2
          errorMessage={errors.country?.message}
          label="Country"
          options={countries}
          placeholder="Choose country"
          value={
            countryValue
              ? countries.find(country => {
                  return country.value === countryValue;
                })
              : null
          }
          onChange={option => {
            return onCountryChange(option ? option.value : option);
          }}
          {...restCountryField}
        />

        <SelectV2
          errorMessage={errors.state?.message}
          label="State"
          options={states}
          placeholder="Choose state"
          value={
            stateValue
              ? states.find(state => {
                  return state.value === stateValue;
                })
              : null
          }
          onChange={option => {
            return onStateChange(option ? option.value : option);
          }}
          {...restStateField}
        />

        <InputV2
          errorMessage={errors?.city?.message}
          label="City"
          placeholder="Enter city"
          {...register('city')}
        />

        <SelectV2
          errorMessage={errors.campus_id?.message}
          label="School"
          options={universities}
          placeholder="Choose school"
          value={
            universityValue
              ? universities.find(state => {
                  return state.value === universityValue;
                })
              : { label: '', value: coach.campus_id }
          }
          onChange={option => {
            return onUniversityChange(option ? option.value : option);
          }}
          {...restUniversityField}
        />

        <SelectInput
          defaultValue={selectedPersonalityDescriptors}
          isMulti
          label="Personality Descriptors"
          name="personality_descriptors"
          options={personalityDescriptorsArray}
          placeholder="Select personality descriptors"
          onChangeMultiple={event => {
            setSelectedPersonalityDescriptors(event.personality_descriptors);
          }}
        />

        <SelectInput
          defaultValue={selectedInterests}
          isMulti
          label="Interests"
          name="interests"
          options={interestsArray}
          placeholder="Select interests"
          onChangeMultiple={event => {
            setSelectedInterests(event.interests);
          }}
        />

        <InputV2
          label="Mailing Address"
          placeholder="Enter mailing address"
          {...register('mailing_address')}
        />

        <div>
          <Typography color="secondary" marginBottom=".5x" text="Biography" />
          <Textarea
            defaultValue={biography}
            name="biography"
            placeholder="Enter coach biography..."
            rows={6}
            onChangeArea={event => {
              setBiography(event.biography);
            }}
          />
        </div>
      </Flex>
    </Form>
  );
};
