import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from '@twilio/conversations';
import Loading from '../../../general/components/loading/loading';
import UserLayout from '../../../general/components/user-layout/user-layout';
import MessageThreadCard from '../MessageThreadCard/MessageThreadCard';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { useFetchReadOnlyChat } from '../../api/useFetchReadOnlyChat';

import styles from './ConversationReadOnly.module.scss';

const ConversationReadOnly = () => {
  const { conversationId, studentId } = useParams();

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const { name } = useParams();

  const chatData = useFetchReadOnlyChat(conversationId as string);

  const renderConversations = !chatData.isLoading ? (
    chatData.chatData.map((element: Message, index: number) => {
      return (
        <div className={styles.messageContainer} key={index}>
          <div
            className={`${
              element.author === localStorageUser().email
                ? styles.containerLeft
                : styles.containerRight
            }`}
          >
            <MessageThreadCard
              message={element}
              outgoing={element.author === localStorageUser().email}
            />
          </div>
        </div>
      );
    })
  ) : (
    <Loading />
  );

  return (
    <Fragment>
      <UserLayout centeredTitle title={name}>
        <div className={`${styles.container}`}>
          <Fragment>{renderConversations}</Fragment>
        </div>
      </UserLayout>
    </Fragment>
  );
};

export default ConversationReadOnly;
