import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useFetchStudentNotes = (studentId: string) => {
  const { data, error } = useSWR(
    `students/${studentId}/notes`,
    interceptorFetcher,
    { revalidateOnFocus: false }
  );

  return {
    isError: error,
    isLoading: !error && !data,
    notesData: data,
  };
};
