import axiosInstance from '../../../utils/helpers/http';
import { CreateRateSession } from '../interfaces/create-rate-session';

export const updateSessionDetails = async (
  sessionId: string,
  rateSession: CreateRateSession,
) => {
  return axiosInstance.patch(
    `/students/session_timeslots/${sessionId}`,
    rateSession,
  );
};
