import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  coachMatchingSteps,
  stepKeys,
} from '../../../../utils/constants/CoachMatching';
import CurrentStep from '../../../general/components/current-step/current-step';
import MatchingCoaches from '../../../register-coachee/components/matching-coaches/matching-coaches';
import { useCoachMatching } from '../../api/useCoachMatching';
import { useAppSelector } from '../../../../store/hooks';
import { createCoachingSession } from '../../../general/api/createCoachingSession';
// @ts-ignore
import { createChangeCoachSession } from '../../../coachee/api/createChangeCoachSession';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { CoachMatch } from '../../interfaces/CoachMatch';
import { updateStudent } from '../../../register-coachee/api/updateStudent';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';
import { Timeslot } from '../../../general/interfaces/Timeslot';
import { Button, Conditional, Flex, Layout } from '../../../../common';
import { Waitlist } from '../../../register-coachee/components/waitlist';

import styles from './wizard.module.scss';

const CoachCatchingWizard = () => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState<{
    chooseCoach: Partial<CoachMatch>;
    chooseTimeslot: Partial<Timeslot>;
  }>({
    chooseCoach: {},
    chooseTimeslot: {},
  });

  const [firstSession, setFirstSession] = useState('');

  const coachesData = useCoachMatching(
    useAppSelector(state => {
      return state.registerStudent.interests.predefined;
    }),
    useAppSelector(state => {
      return state.registerStudent.coachPreferences.predefined;
    }),
    useAppSelector(state => {
      return state.registerStudent.coachPersonalityDescriptors.predefined;
    }),
  );

  const [currentCoachesCount, setCurrentCoachesCount] = useState(3);

  const [currentStep, setCurrentStep] = useState(
    coachMatchingSteps[stepKeys.chooseCoach],
  );
  const CurrentStepComponent = currentStep.component;

  const handleDataChange = (childData: any) => {
    setData((prevState: any) => {
      const update = { ...prevState };

      if (childData) {
        update[currentStep.key] = childData;
      } else {
        update[currentStep.key] = {};
      }
      return update;
    });
  };

  const validateChild = useCallback(async () => {
    if (currentStep.objectValidation) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Object.keys(data[currentStep.key as keyof typeof data]).length === 0
        ? setIsDisabled(true)
        : setIsDisabled(false);
    }
  }, [data, currentStep]);

  const handleNextStep = async () => {
    //* Save the coach and timeslot choices
    if (currentStep.nextStepKey && !isDisabled) {
      const status = !!localStorageUser() && localStorageUser().status;

      switch (currentStep.key) {
        case stepKeys.chooseTimeslot: {
          try {
            if (status === STATUSES.CHANGE_COACH) {
              const sessionsResponse = await createChangeCoachSession(
                localStorageUser().id,
                data.chooseCoach.id,
                data.chooseTimeslot.id,
                +data.chooseTimeslot.start_time,
              );
              setFirstSession(
                sessionsResponse.data.session_timeslots[0].timeslot,
              );
            } else if (status === STATUSES.REGISTER_CHOOSE_YOUR_COACH) {
              const sessionsResponse = await createCoachingSession(
                localStorageUser().id,
                data.chooseCoach.id,
                data.chooseTimeslot.id,
                +data.chooseTimeslot.start_time,
              );

              setFirstSession(
                sessionsResponse.data.session_timeslots[0].timeslot,
              );
            }
            const userResponse = await updateStudent({
              status: STATUSES.ACTIVE,
            });
            localStorage.setItem('user', JSON.stringify(userResponse?.data));
            setCurrentStep(coachMatchingSteps[currentStep.nextStepKey]);
          } catch (err: any) {
            return console.error('error');
          }
          break;
        }
        default: {
          setCurrentStep(coachMatchingSteps[currentStep.nextStepKey]);
          break;
        }
      }
    } else if (currentStep.key === stepKeys.congratulations) {
      navigate('/coachee/dashboard');
    }
  };

  const handlePreviousStep = () => {
    if (currentStep.prevStepKey) {
      setCurrentStep(coachMatchingSteps[currentStep.prevStepKey]);
    }
  };

  useEffect(() => {
    validateChild();
  }, [data, currentStep, validateChild]);

  const handleExploreMore = () => {
    const firstNewRendered = currentCoachesCount + 1;
    setCurrentCoachesCount(
      Math.min(currentCoachesCount + 3, coachesData.coaches?.length || 0),
    );

    setTimeout(() => {
      const element = document.querySelector(
        `[class^="user-card_container"]:nth-of-type(${firstNewRendered})`,
      );

      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 1);
  };

  if (coachesData.isLoading) {
    return <MatchingCoaches />;
  }

  return (
    <Fragment>
      {coachesData &&
      coachesData.isEmpty !== undefined &&
      !coachesData.isEmpty ? (
        <div className={styles.container}>
          <Layout
            Footer={
              <Flex className={styles.nextButton} gap="2x" width="100%">
                <Conditional
                  condition={currentStep.key === stepKeys.chooseCoach}
                >
                  <Button
                    disabled={
                      currentCoachesCount >= coachesData.coaches?.length!
                    }
                    text="Explore more"
                    variant="secondary"
                    onClick={handleExploreMore}
                  />
                </Conditional>

                <Button
                  disabled={isDisabled}
                  text={currentStep.button}
                  onClick={handleNextStep}
                />
              </Flex>
            }
            hasBackButton={!!currentStep.prevStepKey}
            subtitle={currentStep.subtitle}
            title={currentStep.title}
            onBackButtonClick={handlePreviousStep}
          >
            <CurrentStep className={styles.currentComponent}>
              <CurrentStepComponent
                coach={data.chooseCoach}
                coaches={
                  coachesData.coaches?.slice(0, currentCoachesCount) || []
                }
                handleDataChange={handleDataChange}
                timeslot={firstSession}
              />
            </CurrentStep>
          </Layout>
        </div>
      ) : (
        <Waitlist />
      )}
    </Fragment>
  );
};

export default CoachCatchingWizard;
