import * as yup from 'yup';

export const capacityFormSchema = yup.object().shape({
  id: yup.string(),
  new_students_capacity: yup
    .number()
    .required('New students capacity is required')
    .min(0, 'New students capacity must be at least 0')
    .test(
      'lessThanWeeklyCapacity',
      'New students capacity cannot be more than weekly capacity',
      function (value) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { weekly_capacity } = this.parent;
        return value <= weekly_capacity;
      },
    ),
  weekly_capacity: yup
    .number()
    .required('Weekly capacity is required')
    .min(0, 'Weekly capacity must be at least 0')
    .max(80, 'Weekly capacity cannot be more than 80'),
});
