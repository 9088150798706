import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgEyeOff = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.733 8.866 9.766 7.9c.1-.522-.05-1.011-.45-1.467-.4-.455-.917-.633-1.55-.533l-.967-.967a2.83 2.83 0 0 1 .575-.2c.195-.044.403-.067.625-.067.834 0 1.542.292 2.126.875.583.584.874 1.292.874 2.125 0 .223-.022.431-.066.626a2.833 2.833 0 0 1-.2.574Zm2.133 2.1-.967-.933a7.303 7.303 0 0 0 1.126-1.059c.327-.383.608-.819.841-1.308a6.555 6.555 0 0 0-2.392-2.675C10.435 4.331 9.277 4 7.999 4a6.71 6.71 0 0 0-.95.066 6.419 6.419 0 0 0-.916.2L5.099 3.233A7.404 7.404 0 0 1 8 2.666c1.678 0 3.173.464 4.484 1.392a7.937 7.937 0 0 1 2.85 3.608 7.814 7.814 0 0 1-1.008 1.826 7.35 7.35 0 0 1-1.459 1.474Zm.333 4.1L10.4 12.3a8.03 8.03 0 0 1-1.174.275c-.395.061-.803.091-1.226.091-1.677 0-3.172-.463-4.483-1.39a7.937 7.937 0 0 1-2.85-3.61 7.91 7.91 0 0 1 .883-1.642 7.681 7.681 0 0 1 1.217-1.358L.933 2.8l.933-.934 12.267 12.267-.934.934ZM3.7 5.6a7.158 7.158 0 0 0-.883.95 6.01 6.01 0 0 0-.683 1.116 6.552 6.552 0 0 0 2.391 2.675c1.04.662 2.198.992 3.475.992.223 0 .44-.014.65-.041.211-.028.428-.059.65-.092l-.6-.633a3.003 3.003 0 0 1-.35.074 2.327 2.327 0 0 1-.35.025 2.895 2.895 0 0 1-2.125-.874 2.895 2.895 0 0 1-.875-2.126 2.39 2.39 0 0 1 .1-.7L3.7 5.6Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgEyeOff;
