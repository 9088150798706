import React, { FC } from 'react';
import { CampusDrawerProps } from './campus-drawer.types';
import { CreateCampusForm } from '../create-form';
import { EditCampusForm } from '../edit-form';
import { Details } from '../details';

export const CampusDrawer: FC<CampusDrawerProps> = props => {
  const { campus, onEdit, onHideDrawer, onRefresh, type } = props;

  if (type === 'create') {
    return (
      <CreateCampusForm onHideDrawer={onHideDrawer} onRefresh={onRefresh} />
    );
  }

  if (type === 'edit') {
    return (
      <EditCampusForm
        campus={campus}
        onHideDrawer={onHideDrawer}
        onRefresh={onRefresh}
      />
    );
  }

  if (type === 'details') {
    return (
      <Details campus={campus} onEdit={onEdit} onHideDrawer={onHideDrawer} />
    );
  }
};
