import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import { GridProps } from './grid.types';
import { GridPresets } from './grid.presets';
import { extractSpacingProps } from '../../helpers/spacing';

export const Grid: ForwardRefExoticComponent<
  PropsWithoutRef<GridProps> & RefAttributes<Partial<GridProps>>
> = forwardRef((props, ref) => {
  const {
    children,
    className,
    columns,
    flow,
    inline,
    onClick,
    rows,
    style,
    ...otherStyles
  } = props;

  return (
    <div
      className={className}
      ref={ref as any}
      style={{
        display: inline ? 'inline-grid' : 'grid',
        gridAutoFlow: flow,
        gridTemplateColumns:
          typeof columns === 'string'
            ? columns
            : `repeat(${columns}, minmax(0, 1fr))`,
        gridTemplateRows: rows
          ? typeof rows === 'string'
            ? rows
            : `repeat(${rows}, max-content)`
          : null,
        ...otherStyles,
        ...extractSpacingProps(props),
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

Grid.defaultProps = GridPresets;
