import React, { FC, useMemo } from 'react';
import { AdminDrawerProps } from './availability-drawer.types';
import { Panel, Table, Typography } from '../../../../../../common';

export const AvailabilityDrawer: FC<AdminDrawerProps> = props => {
  const { coaches, onHideDrawer } = props;

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'surname',
        header: 'Surname',
      },
    ];
  }, []);

  return (
    <Panel
      Header={
        <React.Fragment>
          <Typography
            text="List of coaches with availability"
            variant="title3"
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Table columns={columns} data={coaches as any} />
    </Panel>
  );
};
