import axiosInstance from "../../../utils/helpers/http";
import { UpdateSesssionTimeslot } from "../interfaces/UpdateSessionTimeslot";

export const updateSessionTimeslot = async (
  sessionTimeslotId: string,
  sessionUpdate: UpdateSesssionTimeslot
) => {
  return axiosInstance.patch(
    `/coaches/session_timeslots/${sessionTimeslotId}`,
    sessionUpdate
  );
};
