export const colors = [
  'black',
  'white',
  'gray-500',
  'gray-200',
  'gray-100',
  'teal',
] as const;

export type ThemeColor = (typeof colors)[number];

export interface Theme {
  colors: Record<ThemeColor, string>;
}

export const theme: Theme = {
  colors: colors.reduce((acc, color) => {
    return {
      ...acc,
      [color]: `var(--color-${color})`,
    };
  }, {}) as Record<ThemeColor, string>,
};

export const getThemeColor = (color: ThemeColor): string => {
  return theme.colors[color] ?? color ?? 'var(--color-purple)';
};
