import moment from 'moment-timezone';

export const convertDateTimeToUtc = (dateTime: string) => {
  return moment(dateTime).utc().toString();
};
export const daysMapping = {
  Friday: 5,
  Monday: 1,
  Saturday: 6,
  Sunday: 7,
  Thursday: 4,
  Tuesday: 2,
  Wednesday: 3,
};

export const convertDateTimeToTimezone = (
  dateTime: string,
  timezone: string,
  format?: string,
  day?: string,
) => {
  if (day) {
    if (format) {
      return moment(dateTime)
        .tz('GMT')
        .set('day', daysMapping[day as keyof typeof daysMapping])
        .tz(timezone)
        .format(format)
        .toString();
    } 
      return moment(dateTime)
        .tz('GMT')
        .set('day', daysMapping[day as keyof typeof daysMapping])
        .tz(timezone)
        .toString();
    
  } 
    return format
      ? moment(dateTime).tz(timezone).format(format).toString()
      : moment(dateTime).tz(timezone).toString();
  
};
