import { useMemo } from 'react';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { Note } from '../../interfaces/Note';
import EmptyListing from '../../../general/components/empty-listing/empty-listing';
import ListingCard from '../../../general/components/listing-card/listing-card';
import Loading from '../../../general/components/loading/loading';
import EmptyButton from '../../../general/components/ui/empty-button/empty-button';
import { convertDateTimeToTimezone } from '../../../general/utils/helpers/convertDateTime';
import emptyNotes from '../../../../assets/icons/EmptyListing/emptyNotes.svg';

import styles from './StudentNotes.module.scss';

type Props = {
  handleShowModal: () => void;
  studentNotesData: {
    isError: {};
    isLoading: boolean;
    notesData: Note[];
  };
};

const StudentNotes = ({ handleShowModal, studentNotesData }: Props) => {
  const user = localStorageUser();
  const filtredSessionNotes: Note[] = [];
  const filtredGeneralNotes: Note[] = [];

  if (studentNotesData.notesData && !studentNotesData.isLoading) {
    studentNotesData.notesData.map((noteData: Note) => {
      if (noteData.session_timeslot_id) {
        return filtredSessionNotes.push(noteData);
      }
      return filtredGeneralNotes.push(noteData);
    });
  }

  const sessionNotes: any = useMemo(() => {
    return filtredSessionNotes.length === 0
      ? 'no_notes_available'
      : filtredSessionNotes.reduce(
          (groupedNotes: { [date: string]: string[] }, element: any) => {
            const sessionDate =
              element &&
              user &&
              convertDateTimeToTimezone(
                element!.timeslot,
                user.timezone,
                'YYYY-MM-DD',
              ).split('T')[0];

            if (!groupedNotes[sessionDate as keyof typeof groupedNotes]) {
              groupedNotes[sessionDate as keyof typeof groupedNotes] = [];
            }
            groupedNotes[sessionDate as keyof typeof groupedNotes].push(
              element,
            );
            return groupedNotes;
          },
          {},
        );
  }, [filtredSessionNotes]);

  const sessionNotesRender =
    sessionNotes &&
    sessionNotes !== 'no_notes_available' &&
    Object.keys(sessionNotes)
      .sort()
      .map((date, index) => {
        const notes: any =
          sessionNotes[date] &&
          sessionNotes[date].reduce(
            (groupedNotes: { [date: string]: string[] }, element: any) => {
              const createdDate =
                element &&
                user &&
                convertDateTimeToTimezone(
                  element!.created_at,
                  user.timezone,
                  'YYYY-MM-DD',
                ).split('T')[0];

              if (!groupedNotes[createdDate as keyof typeof groupedNotes]) {
                groupedNotes[createdDate as keyof typeof groupedNotes] = [];
              }
              groupedNotes[createdDate as keyof typeof groupedNotes].push(
                element.note,
              );
              return groupedNotes;
            },
            {},
          );

        return (
          <ListingCard
            className={styles.title}
            key={date + index}
            title={`Session - ${date}`}
          >
            {notes &&
              notes !== 'no_notes_availabe' &&
              Object.keys(notes)
                .sort()
                .map((date, index) => {
                  return (
                    <div key={index}>
                      <div className={styles.noteDate}>{date}</div>
                      <ul className={styles.notesContainer}>
                        {notes[date].map((note: string, index: number) => {
                          return (
                            <li className={styles.note} key={note + index}>
                              {note}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
          </ListingCard>
        );
      });

  const generalNotes: any = useMemo(() => {
    return filtredGeneralNotes.length === 0
      ? 'no_notes_available'
      : filtredGeneralNotes.reduce(
          (groupedNotes: { [date: string]: string[] }, element: any) => {
            const noteDate =
              element &&
              user &&
              convertDateTimeToTimezone(
                element!.created_at,
                user.timezone,
                'YYYY-MM-DD',
              ).split('T')[0];

            if (!groupedNotes[noteDate as keyof typeof groupedNotes]) {
              groupedNotes[noteDate as keyof typeof groupedNotes] = [];
            }
            groupedNotes[noteDate as keyof typeof groupedNotes].push(
              element.note,
            );
            return groupedNotes;
          },
          {},
        );
  }, [filtredGeneralNotes]);

  const generalNotesRender = (
    <ListingCard className={styles.title} title="General notes">
      {generalNotes &&
        generalNotes !== 'no_notes_available' &&
        Object.keys(generalNotes)
          .sort()
          .map((date, index) => {
            return (
              <div key={index}>
                <div className={styles.noteDate}>{date}</div>
                <ul className={styles.notesContainer} key={index}>
                  {generalNotes[date].map((note: string, index: number) => {
                    return (
                      <li className={styles.note} key={note + index}>
                        {note}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
    </ListingCard>
  );

  return (
    <div className={styles.container}>
      <span
        onClick={() => {
          return handleShowModal();
        }}
      >
        <EmptyButton fullWidth>Add a note</EmptyButton>
      </span>

      {studentNotesData.isLoading && <Loading /> ? (
        <div className={`${styles.container} ${styles.notesContainer}`}>
          {sessionNotesRender}
          {generalNotesRender}
        </div>
      ) : studentNotesData.notesData.length > 0 ? (
        <div className={`${styles.container}`}>
          {sessionNotesRender}
          {generalNotesRender}
        </div>
      ) : (
        <EmptyListing
          image={emptyNotes}
          subtitle="Your notes on this student will appear here"
          title="No notes yet"
        />
      )}
    </div>
  );
};

export default StudentNotes;
