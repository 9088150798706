import { memoize } from 'lodash';

export const hyphenateColumnId = memoize((id: string) => {
  return id
    ?.replace(/([A-Z])/g, ' $1')
    ?.replace(/[^a-zA-Z0-9]+/g, ' ')
    ?.trim()
    ?.replace(/\s+/g, '-')
    ?.toLowerCase();
});
