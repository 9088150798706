import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgCamera = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.561 6.043a1.92 1.92 0 0 0-1.995.104L14.864 8.63V6.656a2.054 2.054 0 0 0-2.052-2.052H3.236a2.054 2.054 0 0 0-2.052 2.052v9.576a2.054 2.054 0 0 0 2.052 2.052h9.576a2.054 2.054 0 0 0 2.052-2.052v-1.976l3.703 2.485a1.906 1.906 0 0 0 1.994.104 2.221 2.221 0 0 0 1.143-1.984V8.026a2.22 2.22 0 0 0-1.143-1.983Zm-7.065 10.189a.685.685 0 0 1-.684.684H3.236a.685.685 0 0 1-.684-.684V6.656a.685.685 0 0 1 .684-.684h9.576a.685.685 0 0 1 .684.684v9.576Zm6.84-1.371a.858.858 0 0 1-.418.777.546.546 0 0 1-.589-.033l-4.465-2.996v-2.33l4.464-2.996a.554.554 0 0 1 .59-.034.857.857 0 0 1 .418.777v6.835Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgCamera;
