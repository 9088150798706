import { ChangeEvent, FC, PropsWithChildren, useRef, useState } from 'react';
import FormData from 'form-data';
import axios from 'axios';
import cx from 'classnames';
import { ImageUploadProps } from './image-upload.types';
import { Icon } from '../icon';
import { Flex } from '../flex';
import { Conditional } from '../conditional';
import { Typography } from '../typography';

import styles from './image-upload.module.scss';

export const ImageUpload: FC<PropsWithChildren<ImageUploadProps>> = props => {
  const {
    children,
    imageUrl: initialImageUrl,
    onImageUpload,
    onUploading,
    size = 'l',
  } = props;
  const [error, setError] = useState<string | null>(null);
  const fileRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(initialImageUrl);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('File is not an image.');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError('Image size should not exceed 5MB.');
        return;
      }

      setError(null);

      try {
        setUploading(true);
        onUploading?.(true);

        const axiosInstance = axios.create();
        const data = new FormData();

        data.append('file', file);
        // data.append('public_id', publicId);
        data.append('upload_preset', 'trula-app');
        data.append('folder', 'avatars');
        data.append('api_key', '474978634342639');

        const cloudinaryResponse = await axiosInstance.post(
          `https://api.cloudinary.com/v1_1/ddogkzysg/image/upload`,
          data,
        );

        const fileUrl = cloudinaryResponse?.data?.secure_url;

        onImageUpload?.(fileUrl);

        setImageUrl(fileUrl);
      } catch (error) {
        setError('An error occurred while uploading the image.');
        setImageUrl('');
      } finally {
        setUploading(false);
        onUploading?.(false);
      }
    }
  };

  const showFilePopup = () => {
    fileRef.current.click();
  };

  return (
    <div>
      {children ? (
        <div style={{ cursor: 'pointer' }} onClick={showFilePopup}>
          {children}
        </div>
      ) : (
        <Flex alignItems="center" direction="column" justifyContent="center">
          <div
            className={cx(styles.preview, styles[size])}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
            onClick={showFilePopup}
          >
            <Conditional condition={!imageUrl}>
              <Icon height="28px" name="ui:photo-camera" width="38px" />
            </Conditional>

            <span className={styles.editIcon}>
              <Icon name="actions:pencil" />
            </span>

            <Conditional condition={uploading}>
              <div className={styles.ldsRing}>
                <div />
                <div />
                <div />
                <div />
              </div>
            </Conditional>
          </div>

          <div>
            <Conditional condition={!!error}>
              <Typography
                align="center"
                color="alert1"
                text={error}
                variant="caption"
              />
            </Conditional>
          </div>
        </Flex>
      )}

      <input
        className={styles.file}
        ref={fileRef}
        type="file"
        onChange={handleImageChange}
      />
    </div>
  );
};
