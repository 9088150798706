import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { RowData } from '@tanstack/react-table';
import { DragOverlay } from '@dnd-kit/core';
import cx from 'classnames';
import { TableRowCells } from '../table-row-cells';
import { TableRowOverlayProps } from './table-row-overlay.types';

import styles from './table-row-overlay.module.scss';

export const TableRowOverlay = <TData extends RowData>(
  props: TableRowOverlayProps<TData>,
) => {
  const { getCellProps, getHeaderGroups, reordering, row, table } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const sizesRules = useMemo(() => {
    const group = getHeaderGroups?.();

    if (!group) {
      return '';
    }

    return group?.[0]?.headers
      ?.map((header, index) => {
        const ths = table.querySelectorAll('.thead .tr .th');

        const width = ths[index].clientWidth;

        return `
        .table-overlay .tr > .td:nth-child(${
          index + 1
        }) { max-width: ${width}px; width: ${width}px;}`;
      })
      .join(' ');
  }, [getHeaderGroups]);

  useEffect(() => {
    setTimeout(() => {
      if (!row?.id) {
        return;
      }

      const tr = document.querySelector(
        `.tr[data-rowid="${row.id}"]`,
      ) as HTMLTableRowElement;

      if (containerRef?.current) {
        containerRef.current.style.height = `${tr.clientHeight}px`;
        containerRef.current.style.maxHeight = `${tr.clientHeight}px`;
      }
    }, 1);
  }, [row?.id, containerRef?.current]);

  return (
    <DragOverlay
      className="table-overlay"
      dropAnimation={null}
      style={{ cursor: 'grabbing' }}
    >
      <style>{sizesRules}</style>

      <tr
        className={cx('tr', styles.tr)}
        data-testid={`overlay-${row.id}`}
        ref={containerRef as RefObject<HTMLTableRowElement>}
      >
        <TableRowCells
          getCellProps={getCellProps}
          reordering={reordering}
          row={row}
        />
      </tr>
    </DragOverlay>
  );
};
