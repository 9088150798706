import styles from './empty-listing.module.scss';

type Props = {
  image: string;
  subtitle: string;
  title: string;
};

const EmptyListing = ({ image, subtitle, title }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img alt={title} src={image} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default EmptyListing;
