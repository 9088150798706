import React, { FC } from 'react';
import { Divider, Flex, Typography } from '../../../../../../common';
import { DetailsRow } from '../../../details-row';
import { DetailsFormProps } from './details-form.types';
import { formatDate } from '../../../../../../utils/helpers/date';
import {
  stringifyCoachPreferences,
  stringifyInterests,
  stringifyLanguages,
  stringifyPreferredCommunication,
  stringifyPreferredTime,
  stringifyTopics,
} from '../../students.adapters';

export const DetailsForm: FC<DetailsFormProps> = props => {
  const { student } = props;

  return (
    <div>
      <Typography
        marginBottom="x"
        text="About"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginTop="4x">
        <DetailsRow
          label="Birthday"
          value={
            student.date_of_birth
              ? formatDate(student.date_of_birth, 'MMM DD, YYYY')
              : ''
          }
        />
        <DetailsRow label="Email" value={student.email} />
        <DetailsRow label="Phone" value={student.phone_number} />
        <DetailsRow
          label="Language for coaching"
          value={stringifyLanguages([student.coaching_language])}
        />
        <DetailsRow label="City" value={student.city} />
        <DetailsRow label="State" value={student.state} />
        <DetailsRow label="Country" value={student.country} />
        <DetailsRow label="Referral Source" value={student.referral_source} />
      </Flex>

      <Typography
        marginBottom="x"
        marginTop="4x"
        text="Coaching preferences"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginBottom="8x" marginTop="4x">
        <DetailsRow
          label="Coaching topics"
          value={stringifyTopics(student.topics)}
        />
        <DetailsRow
          label="Interests"
          value={stringifyInterests(student.interests)}
        />
        <DetailsRow
          label="Preferred time"
          value={stringifyPreferredTime(student.preferred_time)}
        />
        <DetailsRow
          label="Way of communication"
          value={stringifyPreferredCommunication([
            student.preferred_communication_channel,
          ])}
        />
        <DetailsRow
          label="Coach preference"
          value={stringifyCoachPreferences(student.preferences)}
        />
      </Flex>
    </div>
  );
};
