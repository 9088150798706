import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Client as ConversationsClient,
  Conversation,
  Message,
} from '@twilio/conversations';
import { useForm } from 'react-hook-form';
import Loading from '../../../general/components/loading/loading';
import UserLayout from '../../../general/components/user-layout/user-layout';
import MessageThreadCard from '../MessageThreadCard/MessageThreadCard';
import enter from '../../../../assets/icons/CoachDashboard/enter.svg';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { useSesssionDetailsFetch } from '../../api/useSessionDetailsFetch';
import { useTwilioGeneratedToken } from '../../../general/api/useTwilioGeneratedToken';
import emptyConversation from '../../../../assets/icons/EmptyListing/emptyNotes.svg';
import EmptyListing from '../../../general/components/empty-listing/empty-listing';
import { useFetchSessionlessConversation } from '../../api/useFetchSessionlessConversation';
import { MessageFormData } from './coach-message-theread-list.types';

import styles from './CoachMessageThreadList.module.scss';

const CoachMessageThreadList = () => {
  const { sessionId, studentId } = useParams();
  const { handleSubmit, register, setFocus, setValue } =
    useForm<MessageFormData>();

  const urlSegment = window.location.href.split('/').pop();

  const sessionData = useSesssionDetailsFetch(
    urlSegment !== 'new' ? (sessionId as string) : null,
  );

  const chatData = useFetchSessionlessConversation(
    urlSegment === 'new' ? (studentId as string) : null,
  );

  const [messages, setMessages] = useState<Message[]>();
  const [conversation, setConversation] = useState<Conversation>();
  const [token, setToken] = useState<string>('');
  const [sid, setSid] = useState<string>('');
  const [isLoading, setisLoading] = useState(true);
  const [identity, setIdentity] = useState<string>();
  const [isInputFocused, setisInputFocused] = useState(false);
  const [invalidChat, setInvalidChat] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const tokenData = useTwilioGeneratedToken(
    'sms',
    sessionData.session ? sessionData.session.id : null,
  );

  useEffect(() => {
    if (!tokenData.isLoading && !tokenData.isError) {
      setSid(tokenData.token.conversation.sid);
      setToken(tokenData.token.token);
      setIdentity(tokenData.token.participants.coach.identity);
      setisLoading(false);
    } else if (!chatData.isLoading && !chatData.isError) {
      setSid(chatData.conversationData.conversation_id);
      setToken(chatData.conversationData.token);
      setIdentity(localStorageUser().email);
      setisLoading(false);
    } else if (tokenData.isError || chatData.isError) {
      setInvalidChat(true);
      setisLoading(true);
    }
  }, [tokenData, chatData]);

  useEffect(() => {
    if (isInputFocused) {
      window.scrollTo(0, 0);
    }
  }, [isInputFocused]);

  useEffect(() => {
    if (token !== null) {
      createConversation();
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [messages]);

  const createConversation = async () => {
    let convo;
    if (sid && token) {
      const client = token !== '' ? new ConversationsClient(token) : null;
      if (client) {
        client.on('connectionStateChanged', async state => {
          if (state === 'connected') {
            convo = await client.getConversationBySid(sid);
            setConversation(convo);

            try {
              const messagePaginator = await convo.getMessages();
              setMessages(messagePaginator.items);
            } catch {
              setMessages([]);
            }
          }
        });

        client.on('messageAdded', msg => {
          if (msg.conversation.sid === sid) {
            setMessages((prevState: any) => {
              let update = prevState;
              update = [...update!, msg];
              return update;
            });
          }
        });
      }
    }
  };

  const scrollToLastMessage = () => {
    containerRef.current?.scrollTo(0, containerRef.current?.scrollHeight);
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messages?.length]);

  const renderConversations = messages ? (
    messages.map((element: Message) => {
      return (
        <div className={styles.messageContainer} key={element.sid}>
          <div
            className={`${
              element.author === identity
                ? styles.containerLeft
                : styles.containerRight
            }`}
          >
            <MessageThreadCard
              message={element}
              outgoing={element.author === identity}
            />
          </div>
        </div>
      );
    })
  ) : (
    <EmptyListing
      image={emptyConversation}
      subtitle="Your messages for this session will appear here"
      title="No messages yet"
    />
  );

  const onSubmit = async (data: MessageFormData) => {
    if (data?.message !== '') {
      try {
        await conversation!.sendMessage(data.message);
        setValue('message', '');
      } catch (e) {
        console.error(e);
      } finally {
        setFocus('message');
      }
    }
  };

  return (
    <Fragment>
      <UserLayout
        centeredTitle
        title={conversation?.friendlyName ? conversation.friendlyName : ''}
      >
        {isLoading ? (
          <Loading />
        ) : invalidChat ? (
          <EmptyListing
            image={emptyConversation}
            subtitle="Please try again"
            title="Could not load chat"
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`${styles.container} ${
                isInputFocused ? styles.keyboardShrink : ''
              }`}
              ref={containerRef}
            >
              <Fragment>{renderConversations}</Fragment>
            </div>
            <div className={styles.actionBar}>
              <input
                className={styles.input}
                placeholder="Start typing..."
                type="text"
                {...register('message')}
              />

              <div onClick={handleSubmit(onSubmit)}>
                <img alt="Send" className={styles.enter} src={enter} />
              </div>
            </div>
          </form>
        )}
      </UserLayout>
    </Fragment>
  );
};

export default CoachMessageThreadList;
