import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import {
  Button,
  Flex,
  Form,
  ImageUpload,
  Typography,
} from '../../../../../../common';
import { CreateFormProps } from './create-form.types';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { adminFormSchema } from './create-form.schema';
import axiosInstance from '../../../../../../utils/helpers/http';
import { AxiosError } from 'axios';
import InputMask from 'react-input-mask';
import { phoneToE164Format } from '../../../../../../utils/helpers/helpers';

export const CreateAdminForm: FC<CreateFormProps> = props => {
  const { onHideDrawer, onRefresh } = props;
  const [uploadingImage, setUploadingImage] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone_number: '',
      surname: '',
    },
    resolver: yupResolver(adminFormSchema),
  });

  const { errors } = formState;

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.post('admin/admins', {
        ...data,
        phone_number: phoneToE164Format(data.phone_number),
        profile_img: profileImage,
      });
      toast.success('Admin created successfully');
      onRefresh();
      onHideDrawer();
      // @ts-ignore
    } catch (error: AxiosError<any>) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while creating admin',
      );
    }
  };

  return (
    <Form
      Footer={
        <React.Fragment>
          <Button
            disabled={uploadingImage}
            iconPosition="right"
            text="Discard"
            variant="action"
            onClick={onHideDrawer}
          />
          <Button iconPosition="right" text="Add Admin" type="submit" />
        </React.Fragment>
      }
      Header={<Typography text="Add Admin" variant="title3" />}
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Flex direction="column" gap="2x">
        <ImageUpload
          onImageUpload={setProfileImage}
          onUploading={setUploadingImage}
        />

        <InputV2
          errorMessage={errors.name?.message}
          label="Full Name"
          placeholder="Enter name"
          {...register('name')}
        />

        <InputV2
          errorMessage={errors.name?.message}
          label="Surname"
          placeholder="Enter surname"
          {...register('surname')}
        />

        <InputV2
          errorMessage={errors.email?.message}
          label="Email"
          placeholder="email@domain.com"
          {...register('email')}
        />

        <InputMask mask="+1 (999) 999-9999" {...register('phone_number')}>
          {
            // @ts-ignore
            (inputProps: any) => {
              return (
                <InputV2
                  {...inputProps}
                  type="tel"
                  errorMessage={errors.phone_number?.message}
                  label="Phone"
                  placeholder="+1 234 567 8912"
                />
              );
            }
          }
        </InputMask>
      </Flex>
    </Form>
  );
};
