import React, { FC } from 'react';
import { useToggle } from 'react-use';
import { ThreeDotsMenuProps } from './wrapper-menu.types';
import { Overlay } from '../overlay';
import { Icon } from '../icon';

export const WrapperMenu: FC<ThreeDotsMenuProps> = props => {
  const { Content, Menu, offsetX, offsetY } = props;
  const [visible, toggleVisible] = useToggle(false);

  return (
    <div
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Overlay
        containerStyles={{ cursor: 'pointer' }}
        content={Menu}
        offsetX={offsetX}
        offsetY={offsetY}
        open={visible}
        position="right"
        onClose={toggleVisible}
        onOpen={toggleVisible}
      >
        {Content || <Icon color="t2" name="actions:three_dots" />}
      </Overlay>
    </div>
  );
};
