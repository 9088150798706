import { useEffect, useState } from 'react';
import { UseValidation } from '../../hooks/UseValidation';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  handleError,
  handleInputDataChange,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';
import Input from '../ui/Input/Input';
import MaskedInput from '../ui/MaskedInput/MaskedInput';
import Password from '../ui/Password/Password';

import styles from './BasicInfo.module.scss';

type Props = {
  data: any;
  errors: { [key: string]: string };
  onPasswordChange: (passwordData: object) => void;
};

const BasicInfo = ({ data, errors, onPasswordChange }: Props) => {
  const dispatch = useAppDispatch();
  const storeErrors = useAppSelector(state => {
    return state.registerStudent.errors.basicInfo;
  });

  const [passwordValidity, setPasswordValidity] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      data.name !== '' &&
      data.lastName !== '' &&
      data.email !== '' &&
      data.phoneNo !== '' &&
      passwordValidity
    ) {
      dispatch(setIsButtonDisabled(false));
    } else {
      dispatch(setIsButtonDisabled(true));
    }
  }, [data, passwordValidity]);

  const handleDataChange = (data: any) => {
    if (Object.keys(data)[0] === 'phoneNo') {
      data.phoneNo = data.phoneNo.replace(/-/g, '');
    }
    const error = UseValidation(data, 'basicInfo');

    dispatch(handleError({ currentStep: 'basicInfo', error }));
    dispatch(
      handleInputDataChange({
        basicInfo: data,
      } as any),
    );
  };

  const handlePasswordChange = (passwordData: any) => {
    if (
      passwordData.data.password !== '' &&
      passwordData.data.repeatPassword !== '' &&
      !passwordData.isInvalid &&
      passwordData.isMatch
    ) {
      setPasswordValidity(true);
    } else {
      setPasswordValidity(false);
    }
    onPasswordChange(passwordData);
  };

  return (
    <div className={styles.container}>
      <Input
        defaultValue={data ? data.name : ''}
        errorMessage={storeErrors.name}
        isInvalid={!!storeErrors.name}
        label="First Name"
        name="name"
        placeholder="Your name"
        tabIndex={1}
        type="text"
        onChangeHandler={handleDataChange}
      />
      <Input
        defaultValue={data ? data.lastName : ''}
        errorMessage={storeErrors.lastName}
        isInvalid={!!storeErrors.lastName}
        label="Last Name"
        name="lastName"
        placeholder="Your lastname"
        tabIndex={2}
        type="text"
        onChangeHandler={handleDataChange}
      />
      <Input
        defaultValue={data ? data.email : ''}
        errorMessage={storeErrors.email}
        isInvalid={!!storeErrors.email}
        label="Email"
        name="email"
        placeholder="email@domain.com"
        tabIndex={3}
        type="email"
        onChangeHandler={handleDataChange}
      />
      <Password
        errorMessage="Passwords must match"
        label="Password"
        placeholder="Set your password"
        repeatLabel="Repeat Password"
        repeatPlaceholder="Repeat Password"
        tabIndex={4}
        tabIndexConfirm={5}
        onChangePasswordHandler={handlePasswordChange}
      />
      {window.location.host === 'localhost:3000' ||
      window.location.host === 'test.trula.com' ? (
        <Input
          defaultValue={data ? data.phoneNo : ''}
          errorMessage={storeErrors.phoneNo}
          isInvalid={!!storeErrors.phoneNo}
          label="Phone number"
          name="phoneNo"
          placeholder="XXX-XXX-XXXX"
          tabIndex={7}
          type="text"
          onChangeHandler={handleDataChange}
        />
      ) : (
        <MaskedInput
          className={styles.phoneNo}
          defaultValue={data ? data.phoneNo : ''}
          error={errors.phoneNo}
          format="+1-###-###-####"
          label="Phone number"
          mask="_"
          name="phoneNo"
          placeholder="XXX-XXX-XXXX"
          tabIndex={6}
          onChange={handleDataChange}
        />
      )}

      <Input
        defaultValue={data ? data.studentId : ''}
        errorMessage={storeErrors.studentId}
        isInvalid={!!storeErrors.studentId}
        label="Student ID"
        name="studentId"
        optional
        placeholder="Your student ID"
        tabIndex={7}
        type="text"
        onChangeHandler={handleDataChange}
      />
    </div>
  );
};

export default BasicInfo;
