import React from 'react';
import { RowData } from '@tanstack/react-table';
import { Conditional } from '../../../conditional';
import { Grid } from '../../../grid';
import { CellReorderWrapperProps } from './cell-reorder-wrapper.types';

/** When reordering is provided, wraps the first column in the provided props. */
export const CellReorderWrapper = <TData extends RowData>(
  props: CellReorderWrapperProps<TData>,
) => {
  const {
    FirstColumnContent,
    FirstColumnFallback,
    children,
    index,
    reordering,
  } = props;

  return (
    <Conditional condition={!!reordering && index === 0} Fallback={children}>
      <Grid alignItems="center" columns="2.5rem max-content" gap="x">
        <Conditional
          condition={!!reordering?.isAvailable}
          Fallback={FirstColumnFallback}
        >
          {FirstColumnContent}
        </Conditional>

        {children}
      </Grid>
    </Conditional>
  );
};
