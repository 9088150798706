import { ReactNode } from 'react';
import cx from 'classnames';
import closeIcon from '../../../../assets/icons/common/close.svg';

import styles from './empty-modal.module.scss';

type Props = {
  children: ReactNode;
  cssClass?: string;
  handleClose: () => void;
  subtext?: string;
  subtitle?: string;
  title?: string;
};

const EmptyModal = ({
  children,
  cssClass,
  handleClose,
  subtext,
  subtitle,
  title,
}: Props) => {
  return (
    <div className={cx(styles.container, cssClass ?? '')}>
      <div className={styles.closeContainer}>
        <div>
          <img
            alt="Close button"
            className={styles.closeIcon}
            src={closeIcon}
            onClick={handleClose}
          />
        </div>
      </div>
      <div className={styles.titleContainer}>
        {title ? <div className={styles.title}>{title}</div> : <div />}
        {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : <div />}
        {subtext ? <div className={styles.subtext}>{subtext}</div> : <div />}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default EmptyModal;
