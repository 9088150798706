export const Dashboard = {
  archive: "Archive",
  history: "History",
  ongoing: "Ongoing",
  tabs: {
    calendar: "calendar",
    chat: "chat",
    students: "students",
  },
  titles: {
    calendar: "Session Schedule",
    chat: "Conversations",
    students: "Coachees",
  },
  upcoming: "Upcoming",
};
