import React, { FC, ForwardedRef, forwardRef } from 'react';
import Select, { StylesConfig } from 'react-select';
import cx from 'classnames';
import { Conditional, Typography } from '../../../../../common';
import { SelectProps } from './select.types';

import styles from './select.module.scss';

const colourStyles: StylesConfig<any> = {
  control: styles => {
    return {
      ...styles,
      borderColor: 'transparent',
      '&:focus': {
        boxShadow: 'unset',
      },
      '&:hover': {
        boxShadow: 'unset',
      },
      border: '1px solid var(--color-smoke-500)',
      borderRadius: '8px',
      height: '42px',
      boxShadow: 'unset',
    };
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      display: 'none',
    };
  },
  placeholder: styles => {
    return {
      ...styles,
      color: 'rgba(33, 33, 33, 0.3)',
      fontSize: '16px',
      lineHeight: '24px',
    };
  },
};

// simple select component
// it will be expanded to replace current Input component
// and usages of html input tag
export const SelectV2: FC<SelectProps> = forwardRef((props, ref) => {
  const {
    className,
    errorMessage,
    invalid,
    label,
    onChange,
    options,
    value,
    ...rest
  } = props;

  return (
    <div className={styles.container} ref={ref as ForwardedRef<HTMLDivElement>}>
      <Conditional condition={!!label}>
        <Typography text={label} />
      </Conditional>

      <Select
        options={options}
        value={value}
        {...rest}
        className={cx(styles.select, className)}
        styles={colourStyles}
        onChange={onChange}
      />

      <Conditional condition={!!errorMessage}>
        <Typography color="accent" text={errorMessage} variant="body2" />
      </Conditional>
    </div>
  );
});
