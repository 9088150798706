import styles from './Textarea.module.scss';

type Props = {
  defaultValue?: string;
  id?: string;
  name?: string;
  onChangeArea?: (data: any) => void;
  placeholder?: string;
  rows: number;
};

const Textarea = ({
  defaultValue,
  id,
  name,
  onChangeArea,
  placeholder,
  rows,
}: Props) => {
  return (
    <textarea
      className={styles.textarea}
      defaultValue={defaultValue}
      id={id}
      name={name}
      placeholder={placeholder}
      rows={rows}
      onChange={event => {
        return onChangeArea({ [event.target.name]: event.target.value });
      }}
    />
  );
};

export default Textarea;
