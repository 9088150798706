import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import startCase from 'lodash/startCase';
import { IconPresets } from './icon.presets';
import { IconProps } from './icon.types';
import * as Icons from './generated';

import styles from './icon.module.scss';

export const Icon: FC<IconProps> = props => {
  const {
    animation,
    ariaLabel,
    bottom,
    className,
    color,
    cursor,
    disabled,
    fallback,
    height,
    hoverColor,
    left,
    name,
    onClick,
    right,
    size,
    strokeColor,
    style,
    top,
    variant,
    width,
    ...extraProps
  } = props;

  const SvgIcon =
    // @ts-ignore
    Icons[startCase(name.split(':').at(-1)).replace(/ /g, '')] ||
    // @ts-ignore
    Icons[startCase(fallback?.split(':').at(-1)).replace(/ /g, '')];

  const ref = useRef<HTMLSpanElement>();

  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty(
        '--temporal-hover-color',
        variant === 'button' ? 'var(--color-icon-hover)' : 'transparent',
      );
    }
  }, [ref, variant, color, hoverColor]);

  const iconCursor = disabled ? 'default' : cursor;

  return (
    <span
      className={cx(className, styles.icon, {
        [styles.wrapper]: variant === 'button',
        [styles.noClickable]: variant === 'icon' || disabled,
      })}
      ref={ref}
      style={{
        bottom,
        cursor: iconCursor,
        left,
        right,
        top,
        ...style,
      }}
      onClick={onClick}
    >
      <span className={styles.clickable}>
        {!!SvgIcon &&
          React.createElement(SvgIcon, {
            'aria-hidden': false,
            'aria-label': ariaLabel || name,
            className: cx(
              styles.container,
              { [styles.disabled]: disabled },
              styles[animation],
            ),
            role: 'img',
            style: {
              cursor: iconCursor,
              height: size ?? height,
              width: size ?? width,
            },
            ...extraProps,
            color: `var(--color-icon-${color})`,
            strokeColor: `var(--color-icon-${strokeColor})`,
          })}
      </span>
    </span>
  );
};

Icon.defaultProps = IconPresets;
