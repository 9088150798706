import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { localStorageUser } from '../utils/helpers/localStorageUser';

const SecureRoute = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const group = localStorage.getItem('cognitoGroup');
    const user = localStorageUser();

    if (
      (location.pathname === '/' || location.pathname === '/login') &&
      !!group
    ) {
      navigate(`/${group}/dashboard`);
    }

    const path = location.pathname;
    const notAnAdmin =
      (path === '/admin' || path.startsWith('/admin/')) && group !== 'admin';
    const notACoachee =
      (path === '/coachee' || path.startsWith('/coachee/')) &&
      group !== 'coachee';
    const notACoach =
      (path === '/coach' || path.startsWith('/coach/')) && group !== 'coach';

    if (!notACoachee && user?.status === 'register_choose_your_coach') {
      navigate('coachee/coach-matching');
      return;
    }

    if (
      (notAnAdmin || notACoachee || notACoach) &&
      path !== '/coachee/register'
    ) {
      localStorage.removeItem('cognitoUser');
      localStorage.removeItem('cognitoGroup');
      localStorage.removeItem('user');
      navigate('/');
    }
    // coachee
  }, [location.pathname]);

  return <Routes>{children}</Routes>;
};
export default SecureRoute;
