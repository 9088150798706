import React, { FC, PropsWithChildren } from 'react';
import { FormProps } from './formtypes';
import { Icon } from '../icon';
import { Conditional } from '../conditional';

import styles from './form.module.scss';

export const Form: FC<PropsWithChildren<FormProps>> = props => {
  const { Footer, Header, children, onClose, onSubmit } = props;

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <div className={styles.header}>
        <div className={styles.headerContent}>{Header}</div>

        <Icon
          className={styles.closeIcon}
          cursor="pointer"
          height="24px"
          name="actions:x"
          variant="button"
          width="24px"
          onClick={onClose}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.content}>{children}</div>
      </div>

      <Conditional condition={!!Footer}>
        <div className={styles.footer}>{Footer}</div>
      </Conditional>
    </form>
  );
};
