import React from 'react';
import { flexRender, RowData } from '@tanstack/react-table';
import cx from 'classnames';
import { Conditional } from '../../../conditional';
import { TableHeaderProps } from './table-header.types';
import { hyphenateColumnId } from '../../helpers';
import { dataAttrCellPrefix } from '../table-row/table-row.constants';
import { Icon } from '../../../icon';
import { Tooltip } from '../../../tooltip';
import { CellReorderWrapper } from '../cell-reorder-wrapper';
import { Flex } from '../../../flex';

import styles from './table-header.module.scss';

export const TableHeader = <TData extends RowData>(
  props: TableHeaderProps<TData>,
) => {
  const { getHeaderCellProps, reordering, table } = props;

  const ReorderToggle = (
    <div
      className={cx(styles.disabled, {
        [styles.disabled]: reordering?.disabled,
        [styles.active]: !!reordering?.isActive,
      })}
      onClick={
        reordering?.disabled
          ? undefined
          : () => {
              reordering?.setIsActive?.(!reordering?.isActive);
            }
      }
    >
      <Icon color={reordering?.disabled ? 't2' : 't1'} name="ui:arrow-back" />
    </div>
  );

  return (
    <thead className="thead">
      {table.getHeaderGroups().map(headerGroup => {
        const lastIndex = headerGroup.headers.length - 1;

        return (
          <tr className="tr" key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const customCellProps = getHeaderCellProps?.(header) || {};
              const hyphenatedColumnId = hyphenateColumnId(header.column.id);
              const dataAttrCellId = `${dataAttrCellPrefix}-${hyphenatedColumnId}`;
              const cellProps = { ...customCellProps, [dataAttrCellId]: '' };

              const headerContent = flexRender(
                header.column.columnDef.header,
                header.getContext(),
              );

              return (
                <th
                  key={header.id}
                  {...cellProps}
                  className={[cellProps?.className, styles.th, 'th']
                    .filter(Boolean)
                    .join(' ')}
                >
                  <CellReorderWrapper
                    FirstColumnContent={
                      <Conditional
                        condition={!!reordering?.disabled}
                        Fallback={
                          <Tooltip
                            position={reordering?.tooltipPosition || 'top'}
                            text={
                              reordering?.tooltipText ||
                              (reordering?.isActive
                                ? 'Disable reordering'
                                : 'Enable reordering')
                            }
                          >
                            {ReorderToggle}
                          </Tooltip>
                        }
                      >
                        {ReorderToggle}
                      </Conditional>
                    }
                    FirstColumnFallback={<span />}
                    index={index}
                    reordering={reordering}
                  >
                    <Conditional condition={!header.isPlaceholder}>
                      <Conditional
                        condition={index === lastIndex}
                        Fallback={headerContent}
                      >
                        <Flex
                          alignItems="center"
                          gap="2x"
                          justifyContent={
                            headerContent ? 'space-between' : 'flex-end'
                          }
                        >
                          {headerContent}
                        </Flex>
                      </Conditional>
                    </Conditional>
                  </CellReorderWrapper>
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};
