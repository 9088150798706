import { FC, PropsWithChildren } from 'react';
import { DrawerProps } from './drawer.types';
import { Conditional } from '../../../../common';

import styles from './drawer.module.scss';

export const Drawer: FC<PropsWithChildren<DrawerProps>> = props => {
  const { Content, children, open } = props;

  return (
    <div>
      {children}

      <Conditional condition={open}>
        <div className={styles.content}>{Content}</div>
        <div className={styles.backdrop} />
      </Conditional>
    </div>
  );
};
