import { Listing } from "../interfaces/RegisterStudent";

export const steps: { [key: string]: string } = {
  chooseStudent: "choose_student",
  chooseTimeslot: "choose_timeslot",
};

export const sessionsNumberMapped: Listing[] = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
];
