export const usePasswordValidation = ({
    password = "",
    repeatPassword = "",
  }) => {
    const isValidLength = password.length >= 6;
    const hasLowerCase = /[a-z]+/.test(password);
    const hasUpperCase = /[A-Z]+/.test(password);
    const hasNumber = /[0-9]+/.test(password);
    const hasSpecialChar = /[^A-Za-z0-9]+/.test(password);
        
    return [isValidLength, hasNumber, hasUpperCase, hasLowerCase, hasSpecialChar];
  };
