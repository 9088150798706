import { FC, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import range from 'lodash/range';
import * as falso from '@ngneat/falso';
import { Flex, Icon } from '../../../../common';
import { CoachCoordinator } from '../../../../types';
import { EntitySection } from '../entity-section';

const generateFakeData = (): CoachCoordinator[] => {
  return range(50).map(() => {
    return {
      city: falso.randCity(),
      coachesCount: 13,
      name: falso.randCompanyName(),
      state: falso.randState(),
    } as CoachCoordinator;
  }) as CoachCoordinator[];
};

export const CoachCoordinators: FC = () => {
  const columns: ColumnDef<CoachCoordinator, keyof CoachCoordinator>[] =
    useMemo(() => {
      return [
        {
          accessorKey: 'name',
          header: 'Name',
        },
        {
          accessorKey: 'city',
          header: 'City',
        },
        {
          accessorKey: 'state',
          header: 'State',
        },
        {
          accessorKey: 'coachesCount',
          header: 'Coaches',
        },
        {
          cell: () => {
            return (
              <Flex alignItems="center" gap="2x" justifyContent="space-between">
                <Icon color="t2" name="actions:pencil" />
                <Icon color="t2" name="actions:three_dots" />
              </Flex>
            );
          },
          header: 'Actions',
        },
      ];
    }, []);

  return (
    <EntitySection
      columns={columns}
      data={generateFakeData()}
      name="Coach Coordinator"
      pluralName="Coach Coordinators"
      onCreate={() => {}}
    />
  );
};
