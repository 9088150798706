import React, { FC, useEffect } from 'react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { addMinutes } from '../../../../utils/helpers/date';
import { CalendarWrapper } from '../../../../common';
import { renderEventContent } from '../../../../helpers/full-calendar';
// @ts-ignore
import { Session } from '../../interfaces/session';

import styles from './upcoming-sessions.module.scss';

type Props = {
  coachName: string;
  upcomingSessions: Session[];
};

const UpcomingSessions: FC<Props> = props => {
  const { coachName, upcomingSessions } = props;
  const navigate = useNavigate();

  const [events, updateEvents] = useImmer<EventInput[]>([]);

  useEffect(() => {
    if (upcomingSessions?.length) {
      updateEvents(
        upcomingSessions?.map((session: Session) => {
          const start = new Date(+session.session_date);
          return {
            backgroundColor: '#73be76',
            borderColor: '#73be76',
            display: 'auto',
            end: addMinutes(start, 30),
            extendedProps: session,
            id: session.id,
            start,
            title: coachName,
          } as EventInput;
        }),
      );
    }
  }, [upcomingSessions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="session-timeslots">
      <CalendarWrapper>
        <FullCalendar
          allDaySlot={false}
          businessHours={false}
          dayHeaderFormat={{ day: 'numeric', weekday: 'short' }}
          eventClick={(arg: EventClickArg) => {
            navigate(`/coachee/session/${arg.event.id}`);
          }}
          eventContent={renderEventContent}
          eventDurationEditable={false}
          events={events}
          headerToolbar={{
            center: 'title',
            left: 'prev,next',
            right: 'timeGridWeek,listWeek',
          }}
          height="calc(100vh - 200px)"
          initialView="timeGridWeek"
          noEventsText="No sessions yet"
          plugins={[
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          scrollTime={null}
          scrollTimeReset={false}
          selectable
          selectOverlap
          unselectAuto={false}
          validRange={{ start: new Date() }}
          views={{
            listWeek: { buttonText: 'List', displayEventTime: true },
            timeGridWeek: {
              buttonText: 'Calendar',
              displayEventTime: false,
            },
          }}
        />
      </CalendarWrapper>
    </div>
  );
};

export default UpcomingSessions;
