import styles from './congratulations-text.module.scss';

const CongratulationsText = () => {
  return (
    <div className={styles.container} data-testid="congratulations-title">
      <div className={styles.row}>
        <div className={styles.numbering}>1</div>
        <div className={styles.plain}>
          Check your email to learn more about your coach and session details.
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.numbering}>2</div>
        <div className={styles.plain}>
          You will receive session reminder notifications and a welcome sms from
          your coach.
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.numbering}>3</div>
        <div className={styles.plain}>
          Join your session and start coaching.
        </div>
      </div>
    </div>
  );
};

export default CongratulationsText;
