import { FC, useMemo } from 'react';
import cx from 'classnames';

import generalStyles from '../../../coach-matching/components/general/general.module.scss';

type Props = {
  height?: number;
  image?: string;
  name?: string;
  size?: string;
  width?: number;
};

const Avatar: FC<Props> = props => {
  const { height, image, name, size, width } = props;

  const sizeClass = useMemo(() => {
    if (width || height) {
      return null;
    }

    return size === 'small'
      ? generalStyles.avatarSm
      : size === 'medium'
      ? generalStyles.avatarLg
      : generalStyles.avatarXl;
  }, [width, height, size]);

  const imageSrc = useMemo(() => {
    if (!image) {
      return 'https://res.cloudinary.com/ddogkzysg/image/upload/v1697004991/common/default-avatar_vkfjnu.png';
    }

    if (image.includes('cloudinary')) {
      return image;
    }

    return `/avatars/${image}.webp`;
  }, [image]);

  return (
    <div>
      <img
        alt={name}
        className={cx(generalStyles.avatar, sizeClass)}
        height={height}
        src={imageSrc}
        width={width}
      />
    </div>
  );
};

export default Avatar;
