import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgTrash = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 13 18H3ZM13 3H3v13h10V3ZM5 14h2V5H5v9Zm4 0h2V5H9v9Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgTrash;
