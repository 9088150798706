export interface Coach {
  biography: string;
  campus_id: string;
  city: string;
  coachesCount: number;
  coaching_language: string[];
  country: string;
  date_of_birth: string;
  email: string;
  id: string;
  interests: string[];
  mailing_address: string;
  modified_by: number;
  name: string;
  native_language: string;
  new_students_capacity?: number;
  pastSessionsCount: number;
  personality_descriptors: string[];
  phone_number: string;
  profile_img: string;
  school: string;
  sessionsRating: number;
  state: string;
  status: CoachStatus;
  studentsCount: number;
  timezone: string;
  weekly_capacity?: number;
}

export enum CoachStatus {
  Active = 'Active',
  Archived = 'Archived',
  Inactive = 'Inactive',
}
