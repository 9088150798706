import { Coach, Student } from '../../features/authentication/interfaces';

export function localStorageUser<T extends Coach | Student>(): T {
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user')!);
  } catch {
    user = null;
  }
  return user;
}
