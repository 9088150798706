import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  CognitoConfirmPhoneNumber,
  cognitoResendCode,
  CognitoUserSignIn,
  CognitoUserSignUp,
} from '../interfaces/cognito-user';

const useAuth = () => {
  const navigate = useNavigate();

  const cognitoUserSignUp = async (cognitoUserSignUp: CognitoUserSignUp) => {
    const newCognitoUser = await Auth.signUp({
      attributes: cognitoUserSignUp.attributes,
      autoSignIn: {
        enabled: cognitoUserSignUp.autoSignIn.enabled,
      },
      password: cognitoUserSignUp.password,
      username: cognitoUserSignUp.username,
    });
    localStorage.setItem('cognito_id', newCognitoUser.userSub);
  };

  const cognitoConfirmSignUpPhoneVerification = async (
    cognitoConfirmPhoneNumber: CognitoConfirmPhoneNumber,
  ) => {
    await Auth.confirmSignUp(
      cognitoConfirmPhoneNumber.username,
      cognitoConfirmPhoneNumber.confirmationCode,
    );
  };

  const cognitoResendConfirmationCode = async (
    cognitoResendCode: cognitoResendCode,
  ) => {
    await Auth.resendSignUp(cognitoResendCode.username);
  };

  const processAfterSignIn = (userdata: CognitoUser | any) => {
    if (userdata.attributes) {
      localStorage.setItem('cognito_id', userdata.attributes.sub);
    } else if (userdata.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return userdata;
    }
  };

  const cognitoUserSignIn = async (cognitoUserSignIn: CognitoUserSignIn) => {
    const result = await Auth.signIn({
      password: cognitoUserSignIn.password,
      username: cognitoUserSignIn.username,
    });

    return processAfterSignIn(result);
  };

  const cognitoUserGoogleSignIn = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });

      const result = Auth.currentAuthenticatedUser();

      return processAfterSignIn(result);
    } catch (error) {
      console.error(error);
    }
  };

  const cognitoUpdateUserEmailAttribute = async (
    user: any,
    attribute: string,
  ) => {
    await Auth.updateUserAttributes(user, {
      email: attribute,
    });
  };

  const cognitoUpdateUserPhoneAttribute = async (
    user: any,
    attribute: string,
  ) => {
    await Auth.updateUserAttributes(user, {
      phone_number: attribute,
    });
  };

  const cognitoVerifyUserAttribute = async (user: any) => {
    await Auth.verifyUserAttribute(user, 'phone_number');
  };

  const cognitoVerifyUserAttributeSubmit = async (
    attribute: string,
    code: string,
  ) => {
    await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
  };

  const cognitoForgotPassword = async (username: string) => {
    await Auth.forgotPassword(username);
  };

  const cognitoForgotPasswordSubmit = async (
    username: string,
    code: string,
    new_password: string,
  ) => {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  };

  const cognitoUserSignOut = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch {
      await Auth.signOut();
    } finally {
      localStorage.clear();
      navigate('/login');
    }
  };

  const cognitoDeleteUser = async () => {
    await Auth.deleteUser();
  };

  return {
    cognitoConfirmSignUpPhoneVerification,
    cognitoDeleteUser,
    cognitoForgotPassword,
    cognitoForgotPasswordSubmit,
    cognitoResendConfirmationCode,
    cognitoUpdateUserEmailAttribute,
    cognitoUpdateUserPhoneAttribute,
    cognitoUserGoogleSignIn,
    cognitoUserSignIn,
    cognitoUserSignOut,
    cognitoUserSignUp,
    cognitoVerifyUserAttribute,
    cognitoVerifyUserAttributeSubmit,
  };
};

export default useAuth;
