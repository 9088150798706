import { FC, PropsWithChildren } from 'react';
import { LayoutProps } from './layout.types';
import { Typography } from '../typography';
import { Conditional } from '../conditional';
import { Icon } from '../icon';

import styles from './layout.module.scss';

export const Layout: FC<PropsWithChildren<LayoutProps>> = props => {
  const {
    Footer,
    children,
    hasBackButton,
    onBackButtonClick,
    subtitle,
    title,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Conditional condition={hasBackButton}>
          <Icon
            className={styles.backButton}
            name="ui:arrow-back"
            size="24px"
            variant="button"
            onClick={onBackButtonClick}
          />
        </Conditional>

        <Typography
          paddingBottom={subtitle ? 'x' : null}
          text={title}
          variant="title"
        />
        <Conditional
          condition={typeof subtitle === 'string'}
          Fallback={subtitle}
        >
          <Typography
            color="secondary"
            text={subtitle as string}
            variant="subtitle2"
          />
        </Conditional>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>{children}</div>
      </div>

      <Conditional condition={!!Footer}>
        <div className={styles.footer}>{Footer}</div>
      </Conditional>
    </div>
  );
};
