import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useCoachFetch = () => {
  const { data, error } = useSWR(`students/coach`, interceptorFetcher, {
    revalidateOnFocus: false,
  });
  return {
    coach: data,
    isError: error,
    isLoading: !error && !data,
  };
};
