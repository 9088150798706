import { ReactNode } from 'react';

import styles from './listing-card.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  title: string;
};

const ListingCard = ({ children, className, title }: Props) => {
  return (
    <div className={styles.card}>
      <div className={`${styles.cardTitle} ${className}`}>{title}</div>
      <div className={styles.plain}>{children}</div>
    </div>
  );
};

export default ListingCard;
