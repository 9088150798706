export interface Student {
  acknowledgement: boolean;
  allow_participation: boolean;
  city: string;
  coachEmail: string;
  coachName: string;
  coaching_language: string;
  country: string;
  custom_campus: string;
  date_of_birth: Date;
  email: string;
  gender: number;
  has_resolved_escalations: boolean;
  has_unresolved_escalations: boolean;
  id: string;
  interests: string[];
  name: string;
  native_language: string;
  parental_consent_contact: string;
  pastSessionsCount: number;
  phone_number: string;
  preferences: string[];
  preferred_communication_channel?: 'sms' | 'phone_call' | 'video_call';
  preferred_time: string[];
  referral_source: string;
  resolved_escalations_count: number;
  school: string;
  state: string;
  status: StudentStatus;
  student_campus_id: string;
  surname: string;
  timezone: string;
  topics: string[];
  unresolved_escalations_count: number;
}

export enum StudentStatus {
  active = 'active',
  active_not_verified = 'active_not_verified',
  archived = 'archived',
  blocked = 'blocked',
  change_coach = 'change_coach',
  register_choose_your_coach = 'register_choose_your_coach',
  register_coaching_acknowledgment = 'register_coaching_acknowledgment',
  register_more_about_you = 'register_more_about_you',
  register_parental_consent = 'register_parental_consent',
  register_pending_parental_consent = 'register_pending_parental_consent',
  register_pending_school_entry = 'register_pending_school_entry',
  register_verify_phone_number = 'register_verify_phone_number',
  waitlist = 'waitlist',
}
