import { useEffect, useState } from 'react';
import { Conditional } from '../../../../../common';

import styles from './SelectableCard.module.scss';

type Props = {
  isDisabled?: boolean;
  label: string;
  onSelect: (value: any, isSelected: boolean) => void;
  selected: boolean;
  sublabel?: string;
  value: any;
};

const SelectableCard = ({
  isDisabled,
  label,
  onSelect,
  selected,
  sublabel,
  value,
}: Props) => {
  const [isSelected, setIsSelected] = useState(selected);

  const handleSelect = () => {
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div
      className={`${styles.selectableCard} ${
        isSelected ? styles.selected : ''
      } ${isDisabled ? styles.disabled : ''}`}
      onClick={() => {
        handleSelect();
        onSelect(value, !isSelected);
      }}
    >
      <div className={styles.labels}>
        <div>{label}</div>

        <Conditional condition={!!sublabel}>
          <div>{sublabel}</div>
        </Conditional>
      </div>
    </div>
  );
};

export default SelectableCard;
