import { MouseEvent } from 'react';
import { RowData } from '@tanstack/react-table';
import { TableRowProps } from '../../table-row.types';
import {
  dataAttrRowIsClickable,
  dataAttrRowStatus,
} from '../../table-row.constants';

export const getRowComponentProps = <TData extends RowData>(
  props: TableRowProps<TData>,
) => {
  const { customRowProps, onRowClick, reordering, row } = props;
  const { className, onClick, status, ...restCustomRowProps } = customRowProps;

  const isReorderEnabled = reordering?.isActive;

  const isRowClickable = !isReorderEnabled && (onClick || onRowClick);
  const rowDataAttrs = {
    [dataAttrRowIsClickable]: isRowClickable ? '' : undefined,
    [dataAttrRowStatus]: status,
  };

  const handleOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    onClick?.(event);
    onRowClick?.(row, event);
  };

  const filterClassName = [className, 'tr'].filter(Boolean).join(' ');

  return {
    ...restCustomRowProps,
    ...rowDataAttrs,
    className: filterClassName,
    onClick: handleOnClick,
  };
};
