import Loader from '../ui/Loader/Loader';

import styles from './matching-coaches.module.scss';

const MatchingCoaches = () => {
  return (
    <div className={styles.container}>
      <div className={styles.circles}>
        <div className={styles.circle1} />
        <div className={styles.circle2} />
        <div className={styles.circle3} />
      </div>

      <div className={styles.text}>
        <Loader message="Matching " />
        <p>Please give us a few seconds to make the best match for you!</p>
      </div>
    </div>
  );
};

export default MatchingCoaches;
