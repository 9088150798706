export const Add = () => {return (
  <svg
    data-icon="Add"
    fill="none"
    height="12"
    style={{ marginRight: `${10  }px`, verticalAlign: `${-1  }px` }}
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.166 11.833v-5h-5V5.166h5v-5h1.667v5h5v1.667h-5v5H5.166Z"
      fill="#212121"
    />
  </svg>
)};

export const ArrowBack = () => {return (
  <svg
    height="16"
    viewBox="8 8 32 32"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 40 8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4Z"
      fill="#21303B"
    />
  </svg>
)};

export const Checkbox = () => {return (
  <svg viewBox="0 0 21 21">
    <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
  </svg>
)};

export const CheckMark = ({ fill }) => {return (
  <svg
    fill="none"
    height="9"
    viewBox="0 0 12 9"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.36689 9.00006L0.566895 5.20007L1.51689 4.25007L4.36689 7.10006L10.4836 0.983398L11.4336 1.9334L4.36689 9.00006Z"
      fill={fill}
      opacity="1"
    />
  </svg>
)};
