import { useEffect, useRef, useState } from 'react';
import {
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Participant,
  Track,
  TrackPublication,
} from 'twilio-video';
import mute from '../../../../assets/icons/common/mute.svg';

import styles from './ParticipantComponent.module.scss';

type Props = {
  participant: Participant;
};

const ParticipantComponent = ({ participant }: Props) => {
  const [videoTracks, setVideoTracks] = useState<LocalVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<LocalAudioTrack[]>([]);
  const [isRemoteVideoHidden, setIsRemoteVideoHidden] = useState(false);
  const [isRemoteAudioMute, setIsRemoteAudioMute] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const trackpubsToTracks = (trackMap: Map<Track.SID, TrackPublication>) => {
    return Array.from(trackMap.values())
      .map((publication: any) => {
        return publication.track;
      })
      .filter(track => {
        return track !== null;
      });
  };

  useEffect(() => {
    const trackSubscribed = (track: LocalTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => {
          return [...videoTracks, track];
        });
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => {
          return [...audioTracks, track];
        });
      }

      const handleTrackDisabled = (track: any) => {
        if (track.kind === 'video') {
          //* Hide the associated <video> element and show an avatar image.
          setIsRemoteVideoHidden(true);
        }
        if (track.kind === 'audio') {
          //* Hide the associated <audio> element and show an muted microphone.
          setIsRemoteAudioMute(true);
        }
      };

      const handleTrackEnabled = (track: any) => {
        if (track.kind === 'video') {
          //* Hide the avatar image and show the associated <video> element.
          setIsRemoteVideoHidden(false);
        }
        if (track.kind === 'audio') {
          //* Hide the muted microphone and show the associated <audio> element.
          setIsRemoteAudioMute(false);
        }
      };

      track.on('disabled', () => {
        return handleTrackDisabled(track);
      });
      track.on('enabled', () => {
        return handleTrackEnabled(track);
      });
      return () => {
        track.off('disabled', () => {
          return handleTrackDisabled(track);
        });
        track.off('enabled', () => {
          return handleTrackEnabled(track);
        });
      };
    };

    const trackUnsubscribed = (track: LocalTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => {
          return videoTracks.filter(v => {
            return v !== track;
          });
        });
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => {
          return audioTracks.filter(a => {
            return a !== track;
          });
        });
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      //   participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      const video = videoTrack.attach(videoRef.current as HTMLVideoElement);
      //* flip (mirror) a local video track horizontally
      video.style.transform = 'scale(-1, 1)';
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current as HTMLAudioElement);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const remoteHiddenIdentityChar = (
    <div className={styles.remoteHiddenUser}>
      <div className={styles.remoteIdentity}>
        {participant &&
          Array.from(participant.identity!)[0].toLocaleUpperCase()}
      </div>
    </div>
  );

  const remoteAudioMuteIcon = (
    <div className={styles.muteRemoteIcon}>
      <img alt="Hide Video" className={`${styles.remoteIcon}`} src={mute} />
    </div>
  );

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video autoPlay ref={videoRef} />
      {isRemoteVideoHidden && remoteHiddenIdentityChar}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio autoPlay ref={audioRef} />
      {isRemoteAudioMute && remoteAudioMuteIcon}
    </div>
  );
};

export default ParticipantComponent;
