import { FC } from 'react';
import cx from 'classnames';
import { Add as AddIcon } from '../../../../../assets/icons/common/icons';
import { IAddItemButton } from '../../../../../utils/interfaces/RegisterStudent';
import { Typography } from '../../../../../common';

import styles from './AddItemButton.module.scss';

const AddItemButton: FC<IAddItemButton> = props => {
  const { className, onClickHandler, title, variant } = props;

  return (
    <div
      className={cx(className, styles.container, styles[variant])}
      data-testid="div-client-id"
      onClick={() => {
        return onClickHandler();
      }}
    >
      <AddIcon />

      <Typography text={title} />
    </div>
  );
};

export default AddItemButton;
