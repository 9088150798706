import {
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Row, RowData } from '@tanstack/react-table';
import { useState } from 'react';
import { UseDragAndDropRowsProps } from './use-drag-and-drop-rows.types';

export const useDragAndDropRows = <TData extends RowData>(
  props: UseDragAndDropRowsProps<TData>,
) => {
  const { data, reordering, rows, setData } = props;

  const sensors = useSensors(useSensor(PointerSensor));

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);

    const activeIndex = rows.findIndex((element: Row<TData>) => {
      return element.id === event?.active.id;
    });

    setActiveIndex(activeIndex);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);

    const { active, over } = event;

    if (!over || !active || over?.disabled || active?.id === over?.id) {
      return;
    }

    const oldIndex = rows.findIndex((element: Row<TData>) => {
      return element.id === active.id;
    });

    const newIndex = rows.findIndex((element: Row<TData>) => {
      return element.id === over.id;
    });

    const reorderedData = arrayMove(data, oldIndex, newIndex);

    setData(reorderedData);

    reordering?.onReorder?.(reorderedData, oldIndex, newIndex);
  };

  return {
    activeIndex,
    handleDragEnd,
    handleDragStart,
    isDragging,
    sensors,
  };
};
