import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgPhotoCamera = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.77 24.585a2.396 2.396 0 0 1-2.396 2.396H5.624a2.396 2.396 0 0 1-2.396-2.396V10.21a2.396 2.396 0 0 1 2.396-2.396h2.808A7.187 7.187 0 0 0 13.51 5.71l1.988-1.984a2.396 2.396 0 0 1 1.69-.702h5.615c.636 0 1.245.252 1.694.702l1.984 1.984a7.188 7.188 0 0 0 5.084 2.105h2.808a2.396 2.396 0 0 1 2.395 2.396v14.375ZM5.623 5.42A4.792 4.792 0 0 0 .832 10.21v14.375a4.792 4.792 0 0 0 4.792 4.792h28.75a4.792 4.792 0 0 0 4.791-4.792V10.21a4.792 4.792 0 0 0-4.791-4.791h-2.808c-1.27 0-2.49-.506-3.388-1.404L26.194 2.03A4.792 4.792 0 0 0 22.807.627H17.19c-1.27 0-2.49.505-3.388 1.404L11.82 4.015a4.792 4.792 0 0 1-3.387 1.404H5.624Z"
        fill="#142963"
      />
      <path
        d="M19.998 22.191a5.99 5.99 0 1 1 0-11.978 5.99 5.99 0 0 1 0 11.978Zm0 2.396a8.385 8.385 0 1 0 0-16.77 8.385 8.385 0 0 0 0 16.77ZM8.018 11.41a1.198 1.198 0 1 1-2.395 0 1.198 1.198 0 0 1 2.396 0Z"
        fill="#142963"
      />
    </svg>
  );
};
export default SvgPhotoCamera;
