import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { OverlayProps } from './overlay.types';
import { placement } from './placement-js';
import { OverlayPresets } from './overlay.presets';

import styles from './overlay.module.scss';

let zIndex = 1000;

export const Overlay: FC<PropsWithChildren<OverlayProps>> = props => {
  const {
    children,
    className,
    containerStyles,
    content,
    disabled,
    offsetX,
    offsetY,
    onClose,
    onOpen,
    open,
    parentContainer,
    position,
    prerenderContent,
    referenceId,
    showBackdrop,
    triggerEvent,
  } = props;

  const [contentVisible, setContentVisible] = useState(open);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const modalZIndex = useMemo(() => {
    zIndex += 1;
    return zIndex;
  }, []);

  useEffect(() => {
    setContentVisible(open);
  }, [open]);

  const showContent = () => {
    setContentVisible(true);
    onOpen?.(true);
  };

  const hideContent = () => {
    setContentVisible(false);
    onClose?.(false);
  };

  const handleMouseLeave = () => {
    setContentVisible(false);
    onClose?.(false);
  };

  useEffect(() => {
    if (!containerRef.current || triggerEvent === 'manual' || disabled) {
      return;
    }

    if (triggerEvent === 'click') {
      containerRef.current?.addEventListener('click', showContent);
    } else {
      containerRef.current?.addEventListener('mouseenter', showContent);
      containerRef.current?.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (triggerEvent === 'click') {
        containerRef.current?.removeEventListener('click', showContent);
      } else {
        containerRef.current?.removeEventListener('mouseenter', showContent);
        containerRef.current?.removeEventListener(
          'mouseleave',
          handleMouseLeave,
        );
      }
    };
  }, [triggerEvent, disabled, containerRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current && contentRef.current) {
        placement(containerRef.current, contentRef.current, {
          flip: true,
          placement: position,
        });

        contentRef.current.classList.add(styles.contentVisible);
      }
    }, 0);
  }, [containerRef, contentRef, contentVisible, position, parentContainer]);

  return (
    <React.Fragment>
      <div
        className={cx(styles.container, className, {
          [styles.disabled]: disabled,
        })}
        ref={containerRef}
        style={containerStyles}
      >
        {children}
      </div>

      {(contentVisible || prerenderContent) &&
        createPortal(
          <div
            className={cx(styles.content, {
              [styles.contentHidden]: !contentVisible,
            })}
            data-reference-id={referenceId}
            ref={contentRef}
            style={{
              translate: `${offsetX ?? 0} ${offsetY ?? 0}`,
              zIndex: modalZIndex,
            }}
          >
            {content}
          </div>,
          document.body,
        )}

      {contentVisible &&
        (showBackdrop || triggerEvent === 'click') &&
        createPortal(
          <div
            className={styles.backdrop}
            data-reference-id={referenceId}
            style={{ zIndex: modalZIndex - 1 }}
            onClick={hideContent}
          />,
          document.body,
        )}
    </React.Fragment>
  );
};

Overlay.defaultProps = OverlayPresets;
