import React, { FC } from 'react';
import cx from 'classnames';
import { TogglePresets } from './toggle.presets';
import { ToggleProps } from './toggle.types';
import { Typography } from '../typography';

import styles from './toggle.module.scss';

export const Toggle: FC<ToggleProps> = props => {
  const { id, label, name, onChange, onToggle, value, variant } = props;

  const handleChange = () => {
    onChange?.({
      target: {
        name,
        value: !value,
      } as unknown,
    });

    onToggle?.(!value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        {React.createElement('input', {
          checked: value,
          className: cx(
            styles.input,
            { [styles.checked]: value, [styles.unchecked]: !value },
            styles[variant],
          ),
          id,
          name,
          onChange: handleChange,
          type: 'checkbox',
        })}
      </div>

      {!!label && (
        <span
          className={styles.text}
          onClick={() => {
            return handleChange?.();
          }}
        >
          <Typography text={label} textStyle="bold" variant="body2" />
        </span>
      )}
    </div>
  );
};

Toggle.defaultProps = TogglePresets;
