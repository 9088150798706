import React, { FC } from 'react';
import { AdminDrawerProps } from './admins-drawer.types';
import { Details } from '../details';
import { CreateAdminForm } from '../create-form';
import { EditAdminForm } from '../edit-form';

export const AdminsDrawer: FC<AdminDrawerProps> = props => {
  const { admin, onEdit, onHideDrawer, onRefresh, type } = props;

  if (type === 'create') {
    return (
      <CreateAdminForm onHideDrawer={onHideDrawer} onRefresh={onRefresh} />
    );
  }

  if (type === 'edit') {
    return (
      <EditAdminForm
        admin={admin}
        onHideDrawer={onHideDrawer}
        onRefresh={onRefresh}
      />
    );
  }

  if (type === 'details') {
    return (
      <Details admin={admin} onEdit={onEdit} onHideDrawer={onHideDrawer} />
    );
  }
};
