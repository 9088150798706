import Select from 'react-select';
import { Listing } from '../../../../../utils/interfaces/RegisterStudent';

import styles from './SelectInput.module.scss';

type Props = {
  defaultValue?: string | string[];
  error?: string;
  id?: string;
  idMap?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  label: string;
  name?: string;
  onChange?: (data: any) => void;
  onChangeMultiple?: (data: any) => void;
  options: Listing[];
  placeholder?: string;
  tabIndex?: number;
};
const SelectInput = ({
  defaultValue,
  error,
  id,
  idMap,
  isMulti,
  isSearchable,
  label,
  name,
  onChange,
  onChangeMultiple,
  options,
  placeholder,
  tabIndex,
}: Props) => {
  const styling = {
    control: (provided: {}, state: any) => {
      return {
        ...provided,
        '&:hover': {
          borderColor: '1px solid #bfd4db',
        },
        border: state.isFocused
          ? '1px solid var(--color-blue-800)'
          : `1px solid ${error ? '#fe6868' : '#BFD4DB'}`,
        borderRadius: '8px',
        boxShadow: 'none',
      };
    },
    indicatorSeparator: (provided: {}) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    menu: (provided: {}) => {
      return { ...provided, zIndex: 3 };
    },
  };

  const handleChange = (data: any) => {
    if (isMulti) {
      if (idMap) {
        onChangeMultiple!({
          [name as string]: data.map((element: Listing) => {
            return element.id;
          }),
        });
      } else {
        onChangeMultiple!({
          [name as string]: data.map((element: Listing) => {
            return element.value;
          }),
        });
      }
    } else {
      onChange!({ [name!]: data!.value });
    }
  };

  const defaultValueMapped = options.filter(item => {
    return defaultValue!.includes(item.value);
  });

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>

      <Select
        blurInputOnSelect
        captureMenuScroll
        className={styles.select}
        defaultValue={defaultValueMapped}
        id={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={styling}
        tabIndex={tabIndex}
        onChange={data => {
          return handleChange(data);
        }}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default SelectInput;
