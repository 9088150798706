import { useCallback, useEffect, useRef, useState } from 'react';
import { DatesSetArg } from '@fullcalendar/core';
import { firstDateOfWeek, lastDateOfWeek } from '../../../utils/helpers/date';
import axiosInstance from '../../../utils/helpers/http';

export interface AvailableTimeslotsResponse {
  remaining_new_students_weekly_capacity: number;
  remaining_weekly_capacity: number;
  timeslots: Timeslot[];
}

export interface Timeslot {
  coach_id: string;
  coach_new_students_capacity: number;
  coach_weekly_capacity: number;
  created_at: string;
  day: string;
  end_time: string;
  id: string;
  modified_at: string;
  new_students_count: string;
  remaining_new_students_weekly_capacity: string;
  remaining_weekly_capacity: string;
  start_time: string;
  status: string;
}

export interface UseCoachTimeslotsArgs {
  coachId: string;
}

export const useCoachTimeslots = (args: UseCoachTimeslotsArgs) => {
  const { coachId } = args;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [timeslotsData, setTimeslotsData] =
    useState<AvailableTimeslotsResponse>(null);
  const capacityExceededApproved = useRef(false);
  const [capacityExceededModalVisible, setCapacityExceededModalVisible] =
    useState(false);
  const [from, setFrom] = useState<string>(null);
  const [to, setTo] = useState<string>(null);

  useEffect(() => {
    capacityExceededApproved.current = false;
  }, [timeslotsData?.timeslots]);

  useEffect(() => {
    if (!from || !to) {
      return;
    }

    fetchCoachTimeslots(new Date(from).getTime(), new Date(to).getTime());
  }, [from, to]);

  const fetchCoachTimeslots = useCallback(async (from: number, to: number) => {
    setIsLoading(true);

    try {
      const data = await axiosInstance
        .get<AvailableTimeslotsResponse>(
          `coaches/${coachId}/timeslots?from=${
            from || firstDateOfWeek().getTime()
          }&to=${to || lastDateOfWeek().getTime()}`,
        )
        .then(res => {return res.data});

      setTimeslotsData(data);

      return data;
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onTimeslotSelected = useCallback(() => {
    if (
      !capacityExceededApproved.current &&
      (timeslotsData.remaining_weekly_capacity <= 0 ||
        timeslotsData.remaining_new_students_weekly_capacity <= 0)
    ) {
      setCapacityExceededModalVisible(true);
    }
  }, [
    timeslotsData?.remaining_weekly_capacity,
    timeslotsData?.remaining_new_students_weekly_capacity,
  ]);

  const setCapacityExceededApproved = useCallback((value: boolean) => {
    capacityExceededApproved.current = value;
  }, []);

  const handleDatesSet = ({ end, start }: DatesSetArg) => {
    setFrom(start.toISOString());
    setTo(end.toISOString());
  };

  return {
    capacityExceededModalVisible,
    fetchCoachTimeslots,
    isError,
    isLoading,
    onDatesSet: handleDatesSet,
    onTimeslotSelected,
    setCapacityExceededApproved,
    setCapacityExceededModalVisible,
    timeslotsData,
  };
};
