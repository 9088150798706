/** See `RowStatusValue` type for possible values. */
export const dataAttrRowStatus = 'data-row-status' as const;

export const dataAttrRowIsClickable = 'data-row-is-clickable' as const;

export const dataAttrRowHighlightOnHover =
  'data-row-highlight-on-hover' as const;

/** Applying this data attribute marks a row as active, applying a new background color. */
export const dataAttrActiveRow = 'data-active-row' as const;

/** Prefix added to table cell data attrs (like cell ID). */
export const dataAttrCellPrefix = 'data-cell' as const;

/** Applied when a table cell should be clickable. */
export const dataAttrCellIsClickable = 'data-cell-is-clickable' as const;

/** Applied when a sticky styled element is observed to have become sticky via `markStickyElements`. */
export const dataAttrIsSticky = 'data-is-sticky' as const;
