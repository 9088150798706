import React, { FC, useState } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Form,
  Icon,
  Typography,
} from '../../../../../../common';
import Tabs from '../../../../../general/components/tabs/tabs';
import { DetailsForm } from '../details-form';
import { DetailsProps } from './details.types';
import Avatar from '../../../../../general/components/avatar/avatar';
import { StudentSessions } from '../student-sessions';
import { EscalationForm } from '../escalation-form';
import { studentStatusNameMapping } from '../../students.constants';
import { extractStatusColor } from '../../students.adapters';
import { ribbonColors } from '../../../../../../common/table/table.constants';

export const Details: FC<DetailsProps> = props => {
  const { onHideDrawer, onRefresh, student } = props;
  const [activeTab, setActiveTab] = useState('Details');

  return (
    <Form
      Footer={
        activeTab === 'Student Details' && (
          <Button
            icon={<Icon name="actions:pencil" />}
            iconPosition="right"
            text="Edit Student Details"
          />
        )
      }
      Header={
        <React.Fragment>
          <Flex alignItems="center" gap="3x" marginBottom="4x">
            <Avatar height={80} width={80} />

            <div>
              <Typography text={student.name} variant="title3" />
              <Typography
                marginTop=".5x"
                style={{
                  color: ribbonColors[extractStatusColor(student.status)],
                }}
                text={studentStatusNameMapping[student.status]}
                variant="caption"
              />
            </div>
          </Flex>

          <Tabs
            active={activeTab}
            tab1="Details"
            tab2="Sessions"
            tab3={
              student.has_unresolved_escalations ||
              student.has_resolved_escalations
                ? 'Escalation'
                : null
            }
            onTabChange={setActiveTab}
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Conditional condition={activeTab === 'Details'}>
        <DetailsForm student={student} />
      </Conditional>

      <Conditional condition={activeTab === 'Sessions'}>
        <StudentSessions student={student} />
      </Conditional>

      <Conditional condition={activeTab === 'Escalation'}>
        <EscalationForm
          student={student}
          onHideDrawer={onHideDrawer}
          onRefresh={onRefresh}
        />
      </Conditional>
    </Form>
  );
};
