import useSWR from 'swr';
import axiosInstance from '../../../utils/helpers/http';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useFetchCoachStudents = () => {
  const { data, error } = useSWR(`coaches/students`, interceptorFetcher, {
    revalidateOnFocus: false,
  });

  return {
    isError: error,
    isLoading: !error && !data,
    studentsData: data,
  };
};

export const fetchStudents = async () => {
  const result = await axiosInstance.get('coaches/students');
  try {
    return result.data;
  } catch (err) {
    console.error(err);
  }
};
