import axiosInstance from '../../../utils/helpers/http';

export const createChangeCoachSession = async (
  studentId: string,
  coachId: string,
  timeslotId: string,
  initialDate: number,
) => {
  return axiosInstance.post(`coaching-session/changed-coach`, {
    available_timeslot_id: timeslotId,
    coach_id: coachId,
    initial_date: initialDate,
    student_id: studentId,
  });
};
