export const LogIn: { [key: string]: string } = {
  CHANGE_PASSWORD: "Change password",
  CREATE_ONE_LINK: "Create one",
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  EMAIL: "Email",
  EMAIL_PLACEHOLDER: "email@domain.com",
  FORGOTTEN_PASSWORD: "Forgotten password",
  LABEL: "Log In",
  PASSWORD: "Password",
  PASSWORD_PLACEHOLDER: "Enter your password",
};
