import useSWR from 'swr';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useSessionsFetch = () => {
  const { data, error } = useSWR(
    `students/session-timeslots`,
    interceptorFetcher,
    { revalidateOnFocus: false },
  );
  return {
    // * indicator to show if no sessions are found
    isEmpty: !!data && data.length <= 0,

    isError: error,

    isLoading: !error && !data,

    sessions: data,
  };
};
