import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Conditional, Typography } from '../../../../common';
import axiosInstance from '../../../../utils/helpers/http';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';

import styles from './waitlist.module.scss';

export const Waitlist = () => {
  const [joined, setJoined] = useState(false);
  const user = localStorageUser();

  const handleJoinWishlist = async () => {
    try {
      await axiosInstance.put('students/set-student-status', {
        id: user.id,
        status: 'waitlist',
      });
      setJoined(true);
    } catch {
      toast.error('An error occurred, please try again later');
    }
  };

  return (
    <div className={styles.container}>
      <Conditional
        condition={joined}
        Fallback={
          <React.Fragment>
            <Typography
              align="center"
              color="white"
              style={{ maxWidth: '446px' }}
              text={
                "We don't have any coach available at this moment. Do you want to join our wishlist?"
              }
              variant="title3"
            />

            <Button
              backgroundColor="white"
              text="Join wishlist"
              onClick={handleJoinWishlist}
            />
          </React.Fragment>
        }
      >
        <Typography
          align="center"
          color="white"
          text="Thank you for joining our wishlist. We will notify you when we have a coach available."
          variant="subtitle2"
        />
      </Conditional>
    </div>
  );
};
