import React from 'react';
import { getThemeColor } from '../../../../utils/theme';
import { IconPresets } from '../../icon.presets';
// This component has been autogenerated, to add a new icon,
// copy a svg file into public/icons/svgs directory.
// @ts-ignore
const SvgAddCalendar = iconProps => {
  const {
    color: colorProp,
    strokeColor: strokeColorProp,
    strokeWidth,
    ...props
  } = Object.assign({}, IconPresets, iconProps);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const color = getThemeColor(colorProp);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const strokeColor = getThemeColor(strokeColorProp);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 17.333v-1.666h3.333V7.333H3.666v3.334H2V4c0-.458.163-.85.489-1.177.327-.326.719-.49 1.177-.49H4.5V.667h1.666v1.666h6.667V.667H14.5v1.666h.833c.458 0 .85.164 1.178.49.326.326.489.719.489 1.177v11.667c0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49H12ZM6.166 19 5 17.833l2.146-2.166H.333V14h6.813L5 11.833l1.166-1.166 4.167 4.166L6.166 19Zm-2.5-13.333h11.667V4H3.666v1.667Z"
        fill={color}
      />
    </svg>
  );
};
export default SvgAddCalendar;
