export const STATUSES = {
  ACTIVE: 'active',
  ACTIVE_NOT_VERIFIED: 'active_not_verified',
  ARCHIVED: 'archived',
  CHANGE_COACH: 'change_coach',
  REGISTER_CHOOSE_YOUR_COACH: 'register_choose_your_coach',
  REGISTER_COACHING_ACKNOWLEDGEMENT: 'register_coaching_acknowledgment',
  REGISTER_MORE_ABOUT_YOU: 'register_more_about_you',
  REGISTER_PARENTAL_CONSENT: 'register_parental_consent',
  REGISTER_PENDING_PARENTAL_CONSENT: 'register_pending_parental_consent',
  REGISTER_PENDING_SCHOOL_ENTRY: 'register_pending_school_entry',
  REGISTER_VERIFY_PHONE_NUMBER: 'register_verify_phone_number',
  WAITLIST: 'waitlist',
};
