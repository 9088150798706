import React, { FC } from 'react';
import { Divider, Flex, Typography } from '../../../../../../common';
import { DetailsRow } from '../../../details-row';
import { Campus } from '../../../../../../types';

export const DetailsForm: FC<{ campus: Campus }> = ({ campus }) => {
  return (
    <div>
      <Typography
        marginBottom="x"
        marginTop="4x"
        text="General"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginTop="4x">
        <DetailsRow label="Campus Name" value={campus?.name} />
        <DetailsRow label="City" value={campus?.city} />
        <DetailsRow label="State" value={campus?.state} />
        <DetailsRow label="Country" value={campus?.country} />
        <DetailsRow label="Mailing Address" value={campus?.mailingAddress} />
      </Flex>

      <Typography
        marginBottom="x"
        marginTop="4x"
        text="Point of Contact"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="x" marginBottom="8x" marginTop="4x">
        <DetailsRow label="Name" value={campus?.contactFullName} />
        <DetailsRow label="Email" value={campus?.contactEmail} />
      </Flex>
    </div>
  );
};
