import React, { FC } from 'react';
import { Outlet } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon, Tooltip, WrapperMenu } from '../../../../common';
import { Menu, MenuItem } from '../../../../common/menu';
import useAuth from '../../../authentication/hooks/UseAuth';

import styles from './admin-dashboard.module.scss';

export const AdminDashboard: FC = () => {
  const location = useLocation();
  const isActiveCampuses = ['/admin', '/admin/campuses'].includes(
    location.pathname,
  );

  const { cognitoUserSignOut } = useAuth();

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <Icon height="26px" name="ui:logo" width="48px" />

        <div className={styles.options}>
          <Tooltip offsetX="10px" position="right" text="Campuses">
            <NavLink
              className={isActiveCampuses ? styles.active : ''}
              to="campuses"
            >
              <Icon name="ui:building" size="24px" />
            </NavLink>
          </Tooltip>

          <Tooltip offsetX="10px" position="right" text="Students">
            <NavLink
              className={({ isActive }) => {
                return isActive ? styles.active : '';
              }}
              to="students"
            >
              <Icon name="ui:people" size="24px" />
            </NavLink>
          </Tooltip>

          <Tooltip offsetX="10px" position="right" text="Coaches">
            <NavLink
              className={({ isActive }) => {
                return isActive ? styles.active : '';
              }}
              to="coaches"
            >
              <Icon name="ui:person" size="24px" />
            </NavLink>
          </Tooltip>

          <Tooltip offsetX="10px" position="right" text="Admins">
            <NavLink
              className={({ isActive }) => {
                return isActive ? styles.active : '';
              }}
              to="admins"
            >
              <Icon name="ui:group" size="24px" />
            </NavLink>
          </Tooltip>

          <Tooltip offsetX="10px" position="right" text="Export">
            <NavLink
              className={({ isActive }) => {
                return isActive ? styles.active : '';
              }}
              to="export"
            >
              <Icon name="ui:diversity" size="24px" />
            </NavLink>
          </Tooltip>

          <Tooltip offsetX="10px" position="right" text="Calendar">
            <NavLink
              className={({ isActive }) => {
                return isActive ? styles.active : '';
              }}
              to="calendar"
            >
              <Icon name="ui:event" size="24px" />
            </NavLink>
          </Tooltip>
        </div>

        <WrapperMenu
          Content={<Icon name="ui:cog" />}
          Menu={
            <Menu headerText="Account Actions">
              <MenuItem text="Logout" onClick={cognitoUserSignOut} />
            </Menu>
          }
          offsetX="10px"
          offsetY="-10px"
        />

        {/* <Avatar size="small" /> */}
      </div>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
