import React, { FC, useState } from 'react';
import useSWR from 'swr';
import toast from 'react-hot-toast';
import {
  Button,
  Conditional,
  Divider,
  Flex,
  Typography,
} from '../../../../../../common';
import { DetailsRow } from '../../../details-row';
import { formatDate } from '../../../../../../utils/helpers/date';
import { EscalationFormProps } from './escalation-form.types';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';
import { Escalation } from '../../../../../../types';
import Textarea from '../../../../../register-coachee/components/ui/Textarea/Textarea';
import axiosInstance from '../../../../../../utils/helpers/http';

export const EscalationForm: FC<EscalationFormProps> = props => {
  const { onHideDrawer, onRefresh, student } = props;
  const [notes, setNotes] = useState('');

  const { data: escalation } = useSWR<Escalation>(
    `admin/student/last-escalation?studentId=${student?.id}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const saveEscalation = async () => {
    try {
      await axiosInstance.put('admin/resolve-escalation', {
        id: escalation.id,
        notes,
      });
      onHideDrawer();
      onRefresh();
      toast.success('Escalation resolved successfully');
    } catch {
      toast.error('An error occurred while creating escalation');
    }
  };

  return (
    <div>
      <Typography
        marginBottom="x"
        text="Details"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Conditional condition={!!escalation?.id}>
        <Flex direction="column" gap="x" marginTop="4x">
          <DetailsRow
            label="Escalation Date"
            value={
              escalation?.created_at
                ? formatDate(escalation.created_at, 'MMM DD, YYYY')
                : ''
            }
          />
          <DetailsRow
            label="Status"
            value={
              escalation?.status === 'resolved' ? (
                <Flex gap=".5x">
                  <Typography color="success1" text="Resolved" />
                  <Typography
                    text={`(${formatDate(
                      new Date(+escalation.escalation_resolved_date),
                      'MMM DD, YYYY',
                    )})`}
                  />
                </Flex>
              ) : (
                <Typography color="alert1" text="Unresolved" />
              )
            }
          />
          <DetailsRow label="Coach note" value={escalation?.coach_notes} />

          <Conditional
            condition={escalation?.status === 'unresolved'}
            Fallback={
              <DetailsRow
                label="Admin note"
                value={escalation?.escalation_resolved_notes}
              />
            }
          >
            <Flex direction="column" marginTop="4x">
              <Typography
                marginBottom="x"
                text="Resolve"
                textStyle="bold"
                variant="subtitle2"
              />
              <Divider color="gray-200" height="1px" width="100%" />

              <Typography
                color="secondary"
                marginBottom="4x"
                marginTop="4x"
                text="Explain what was done to resolve the escalation."
              />

              <Textarea
                defaultValue={notes}
                name="notes"
                rows={6}
                onChangeArea={event => {
                  setNotes(event.notes);
                }}
              />

              <Flex justifyContent="flex-end" marginTop="4x">
                <Button
                  disabled={!notes}
                  text="Resolve"
                  onClick={saveEscalation}
                />
              </Flex>
            </Flex>
          </Conditional>
        </Flex>
      </Conditional>
    </div>
  );
};
