import useSWR from "swr";
import { interceptorFetcher } from "../../../utils/helpers/interceptorFetcher";

export const useSesssionDetailsFetch = (sessionId: string | null) => {
  const { data, error } = useSWR(
    sessionId ? `coaches/session-timeslots/${sessionId}` : null,
    interceptorFetcher,
    { revalidateOnFocus: false }
  );

  return {
    isError: error,
    isLoading: !error && !data,
    session: data,
  };
};
