import { Fragment, useEffect } from 'react';
import {
  ExtendableListing,
  Listing,
} from '../../../../utils/interfaces/RegisterStudent';
import SelectableCard from '../ui/SelectableCard/SelectableCard';
import CustomEntry from '../ui/CustomEntry/CustomEntry';
import { registerStudentSteps } from '../../utils/constants/RegisterStudent';
import {
  handleScreenDataChange,
  removeCustomTopic,
} from '../../store/registerStudentSlice';
import { useAppDispatch } from '../../../../store/hooks';

import styles from './Topics.module.scss';
import modalStyles from '../ui/Modal/Modal.module.scss';

type Props = {
  data: { [key: string]: boolean };
  isRegister?: boolean;
  listing: ExtendableListing;
  onChangeHandler?: (data: any) => void;
  removeCustomEntry?: (data: any) => void;
};

const Topics = ({
  data,
  isRegister,
  listing,
  onChangeHandler,
  removeCustomEntry,
}: Props) => {
  const dispatch = useAppDispatch();
  let selectedTopics: { [key: string]: boolean } = { ...data };
  const handleSelect = (value: string, isSelected: boolean) => {
    if (isSelected) {
      selectedTopics = { ...selectedTopics, [value]: true };
    } else {
      delete selectedTopics[value];
    }
    // Related to the register process
    if (isRegister) {
      dispatch(handleScreenDataChange({ topics: selectedTopics }));
    } else {
      onChangeHandler!({ topics: selectedTopics });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="topics-container">
      {listing.predefined.map((topic: Listing) => {
        return (
          <SelectableCard
            isDisabled={
              (selectedTopics.hasOwnProperty('im_not_sure') &&
                topic.value !== 'im_not_sure') ||
              (Object.keys(selectedTopics).length > 0 &&
                !selectedTopics.hasOwnProperty('im_not_sure') &&
                topic.value === 'im_not_sure')
            }
            key={topic.value}
            label={topic.label}
            selected={selectedTopics[topic.value]}
            value={topic.value}
            onSelect={handleSelect}
          />
        );
      })}
      {listing.custom!.length !== 0 && (
        <Fragment>
          <div className={modalStyles.customEntries}>
            {registerStudentSteps.topics.customLabel}
          </div>
          {listing.custom!.map((topic: Listing, index: number) => {
            return (
              <CustomEntry
                inWizard
                key={topic.label + index}
                label={topic.label}
                removeSelection={() => {
                  return isRegister
                    ? dispatch(removeCustomTopic(topic.value))
                    : removeCustomEntry!({ topics: topic.value });
                }}
              />
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default Topics;
