import React, { FC, useState } from 'react';
import {
  Button,
  Conditional,
  Form,
  Icon,
  Typography,
} from '../../../../../../common';
import Tabs from '../../../../../general/components/tabs/tabs';
import { DetailsForm } from '../details-form';
import { CampusStudents } from '../campus-students';
import { CampusCoaches } from '../campus-coaches';
import { DetailsProps } from './details.types';

export const Details: FC<DetailsProps> = props => {
  const { campus, onEdit, onHideDrawer } = props;
  const [activeTab, setActiveTab] = useState('Campus Details');

  return (
    <Form
      Footer={
        activeTab === 'Campus Details' && (
          <Button
            icon={<Icon name="actions:pencil" />}
            iconPosition="right"
            text="Edit Campus Details"
            variant="secondary"
            onClick={onEdit}
          />
        )
      }
      Header={
        <React.Fragment>
          <Typography text={campus?.name} variant="title3" />
          <Typography
            marginTop=".5x"
            text={`${campus?.studentsCount || 0} students, ${
              campus?.coachesCount || 0
            } coaches`}
            variant="caption"
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
    >
      <Tabs
        active={activeTab}
        tab1="Campus Details"
        tab2="Students"
        tab3="Coaches"
        onTabChange={setActiveTab}
      />

      <Conditional condition={activeTab === 'Campus Details'}>
        <DetailsForm campus={campus} />
      </Conditional>

      <Conditional condition={activeTab === 'Students'}>
        <CampusStudents campusId={campus?.id} />
      </Conditional>

      <Conditional condition={activeTab === 'Coaches'}>
        <CampusCoaches campusId={campus?.id} />
      </Conditional>
    </Form>
  );
};
