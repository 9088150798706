import { useEffect } from 'react';
import Input from '../../../../register-coachee/components/ui/Input/Input';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

import { handleError } from '../../../../register-coachee/store/registerStudentSlice';
import { UseValidation } from '../../../../register-coachee/hooks/UseValidation';

import styles from './ForgotPassword.module.scss';

type Props = {
  data: { [key: string]: string };
  onEmailChangeHandler: (data: {
    [key: string]: { [key: string]: string };
  }) => void;
};

const ForgotPassword = ({ data, onEmailChangeHandler }: Props) => {
  const dispatch = useAppDispatch();
  const storeErrors = useAppSelector(state => {
    return state.registerStudent.errors.forgottenPassword;
  });

  const handleOnChange = (data: any) => {
    const error = UseValidation(data, 'forgottenPassword');
    dispatch(handleError({ currentStep: 'forgottenPassword', error }));
    onEmailChangeHandler({
      forgottenPassword: data,
    } as { [key: string]: { [key: string]: string } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <Input
        defaultValue={data ? data.email : ''}
        errorMessage={storeErrors.email}
        isInvalid={!!storeErrors.email}
        label="Email"
        name="email"
        placeholder="email@domain.com"
        type="email"
        onChangeHandler={handleOnChange}
      />
    </div>
  );
};

export default ForgotPassword;
