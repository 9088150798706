import { Listing } from '../../../../utils/interfaces/RegisterStudent';

import styles from './interest-card.module.scss';

type Props = {
  className?: string;
  interest?: Listing;
  value?: string;
};

const InterestCard = ({ className, interest, value }: Props) => {
  return (
    <div className={`${styles.container} ${styles[className!]}`}>
      {interest ? interest.label : value}
    </div>
  );
};

export default InterestCard;
