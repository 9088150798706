import React, { FC, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import {
  Button,
  Conditional,
  Divider,
  Flex,
  Form,
  Typography,
} from '../../../../../../common';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { SelectV2 } from '../../../../../register-coachee/components/ui/select-v2';
import { states } from '../../../../../coach/utils/constants/USStates';
import { EditFormProps } from './edit-form.types';
import { campusFormSchema } from '../create-form/create-form.schema';
import Tabs from '../../../../../general/components/tabs/tabs';
import { CampusStudents } from '../campus-students';
import { CampusCoaches } from '../campus-coaches';
import axiosInstance from '../../../../../../utils/helpers/http';

export const EditCampusForm: FC<EditFormProps> = props => {
  const { campus, onHideDrawer, onRefresh } = props;
  const [activeTab, setActiveTab] = useState('Campus Details');

  const { control, formState, handleSubmit, register } = useForm({
    defaultValues: campus,
    resolver: yupResolver(campusFormSchema),
  });

  const {
    field: { onChange: onStateChange, value: stateValue, ...restStateField },
  } = useController({ control, name: 'state' });

  const { errors } = formState;

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.put('admin/campuses', {
        ...data,
        contactEmail: data.contactEmail || null,
        contactFullName: data.contactFullName || null,
        contactemail: data?.contactemail || null,
        country: 'United States',
      });
      toast.success('Campus edited successfully');
      onRefresh();
      onHideDrawer();
    } catch {
      toast.error('An error occurred while editing campus');
    }
  };

  return (
    <Form
      Footer={
        <React.Fragment>
          <Button
            iconPosition="right"
            text="Discard"
            variant="action"
            onClick={onHideDrawer}
          />
          <Button iconPosition="right" text="Save Changes" type="submit" />
        </React.Fragment>
      }
      Header={
        <React.Fragment>
          <Typography text={campus?.name} variant="title3" />
          <Typography
            marginBottom="4x"
            marginTop=".5x"
            text={`${campus?.studentsCount || 0} students, ${
              campus?.coachesCount || 0
            } coaches`}
            variant="caption"
          />

          <Tabs
            active={activeTab}
            tab1="Campus Details"
            tab2="Students"
            tab3="Coaches"
            onTabChange={setActiveTab}
          />
        </React.Fragment>
      }
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Conditional condition={activeTab === 'Campus Details'}>
        <Typography
          marginBottom="x"
          text="General"
          textStyle="bold"
          variant="subtitle2"
        />
        <Divider color="gray-200" height="1px" width="100%" />

        <Flex direction="column" gap="2x" marginTop="4x">
          <InputV2
            errorMessage={errors.name?.message}
            label="Campus Name"
            placeholder="Enter name"
            {...register('name')}
          />

          <InputV2
            errorMessage={errors?.city?.message}
            label="City"
            placeholder="Enter city"
            {...register('city')}
          />

          <SelectV2
            errorMessage={errors.state?.message}
            label="State"
            options={states}
            placeholder="Choose state"
            value={
              stateValue
                ? states.find(state => {
                    return state.value === stateValue;
                  })
                : null
            }
            onChange={option => {
              return onStateChange(option ? option.value : option);
            }}
            {...restStateField}
          />

          <InputV2
            errorMessage={errors.mailingAddress?.message}
            label="Mailing Address"
            placeholder="Enter mailing address"
            {...register('mailingAddress')}
          />
        </Flex>

        <Typography
          marginBottom="x"
          marginTop="4x"
          text="Point of Contact"
          textStyle="bold"
          variant="subtitle2"
        />
        <Divider color="gray-200" height="1px" width="100%" />

        <Flex direction="column" gap="2x" marginBottom="8x" marginTop="4x">
          <InputV2
            errorMessage={errors.contactFullName?.message}
            label="Full Name"
            placeholder="Enter full name"
            {...register('contactFullName')}
          />
          <InputV2
            errorMessage={errors.contactEmail?.message}
            label="Email"
            placeholder="email@domain.com"
            {...register('contactEmail')}
          />
        </Flex>
      </Conditional>

      <Conditional condition={activeTab === 'Students'}>
        <CampusStudents campusId={campus?.id} />
      </Conditional>

      <Conditional condition={activeTab === 'Coaches'}>
        <CampusCoaches campusId={campus?.id} />
      </Conditional>
    </Form>
  );
};
