import { Fragment, useEffect, useState } from 'react';
import { CoachMatch } from '../../interfaces/CoachMatch';
import UserCard from '../user-card/user-card';
import UserCarousel from '../user-carousel/user-carousel';

import styles from './choose-coach.module.scss';

type Props = {
  coaches: CoachMatch[];
  handleDataChange: (data: CoachMatch | undefined) => void;
};

const ChooseCoach = ({ coaches, handleDataChange }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showCarousel, setShowCarousel] = useState(false);
  const [coachSelection, setCoachSelection] = useState<CoachMatch>();

  const handleSelect = (coach: CoachMatch, isSelected: boolean) => {
    if (coach === coachSelection && !isSelected) {
      setCoachSelection(undefined);
    }
    if (isSelected && coachSelection !== coach) {
      setCoachSelection(coach);
    } else if (coachSelection === coach && isSelected) {
      setCoachSelection(coach);
    }
  };

  // * Callback for sending the coach selection to the wizard
  useEffect(() => {
    handleDataChange(coachSelection);
  }, [coachSelection]);

  const handleClose = () => {
    setShowCarousel(false);
    setCoachSelection(undefined);
  };

  const handleCarousel = (coach: CoachMatch) => {
    setCoachSelection(coach);
    setShowCarousel(true);
  };

  return (
    <div className={styles.container} data-testid="returned-coaches">
      {!showCarousel ? (
        <Fragment>
          {coaches &&
            coaches.length > 0 &&
            coaches.map(coach => {
              return (
                <UserCard
                  coach={coach}
                  key={coach.name}
                  selected={coach === coachSelection}
                  onLearnMore={handleCarousel}
                  onSelect={handleSelect}
                />
              );
            })}
        </Fragment>
      ) : (
        <UserCarousel
          coaches={coaches}
          currentCoach={coachSelection!}
          handleClose={handleClose}
          onChange={handleSelect}
        />
      )}
    </div>
  );
};

export default ChooseCoach;
