import React, { forwardRef } from "react";
import { IProps } from "../../../../../utils/interfaces/RegisterStudent";

import styles from "./Input.module.scss";

const Input = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      defaultValue,
      disabled,
      errorMessage,
      isInvalid,
      isReadOnly,
      label,
      name,
      onChangeHandler,
      optional,
      placeholder,
      readOnly,
      tabIndex,
      type,
      value,
    },
    ref
  ) => {
    const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandler({ [event.target.name]: event.target.value });
    };
    return (
      <div
        className={`${styles.container} ${styles[className!]}`}
        data-testid="container-client-id"
      >
        <div className={styles.labelContainer}>
          <label htmlFor={name}>{label}</label>
          {optional ? (
            <span className={styles.optional} data-testid="span-client-id">
              {" "}
              &nbsp;(optional)
            </span>
          ) : (
            ""
          )}
        </div>
        <input
          className={`${styles.input} ${isInvalid ? styles.invalid : ""} ${
            isReadOnly ? styles.readOnly : ""
          }`}
          defaultValue={defaultValue}
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          tabIndex={tabIndex}
          type={type}
          value={value}
          onChange={(e) => {return handleDataChange(e)}}
        />
        <div
          className={`${isInvalid ? styles.showError : styles.hideError}`}
          data-testid="error-client-id"
        >
          {errorMessage}
        </div>
      </div>
    );
  }
);

export default Input;
