import { ReactNode } from 'react';

import styles from './card.module.scss';

type Props = {
  action?: ReactNode;
  children: ReactNode;
  standalone?: boolean;
  subtitle?: string;
  title: string;
};

const Card = ({ action, children, standalone, subtitle, title }: Props) => {
  return (
    <div className={`${styles.container} ${standalone ? styles.p16 : ''}`}>
      <div className={styles.header}>
        <div className={styles.titlesContainer}>
          <div className={styles.title}>{title}</div>
          {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : ''}
        </div>
        {action ? <div>{action}</div> : ''}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Card;
