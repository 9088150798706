import { ReactNode } from 'react';

import styles from './empty-button.module.scss';

type Props = {
  children: ReactNode;
  fullWidth?: boolean;
};

const EmptyButton = ({ children, fullWidth }: Props) => {
  return (
    <div className={`${styles.emptyButtonContainer}`}>
      <div className={`${styles.emptyButton} ${fullWidth ? styles.w100 : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default EmptyButton;
