import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { IPasswordProps } from '../../../../../utils/interfaces/RegisterStudent';
import { usePasswordValidation } from '../../../../authentication/hooks/UsePasswordValidation';
import { CheckMark } from '../../../../../assets/icons/common/Common';
import { Icon } from '../../../../../common';

import styles from './Password.module.scss';

const Password = forwardRef<HTMLInputElement, IPasswordProps>(
  (
    {
      errorMessage,
      label,
      onChangePasswordHandler,
      placeholder,
      repeatLabel,
      repeatPlaceholder,
      tabIndex,
      tabIndexConfirm,
    },
    ref,
  ) => {
    const [data, setData] = useState({
      password: '',
      repeatPassword: '',
    });
    const [hidePassword, setHidePassword] = useState(true);
    const [isInvalid, setIsInvalid] = useState(true);
    const [isMatch, setIsMatch] = useState(true);
    const [progress, setProgress] = useState('');
    const [color, setColor] = useState('');

    const [
      isValidLength,
      hasNumber,
      hasUpperCase,
      hasLowerCase,
      hasSpecialChar,
    ] = usePasswordValidation({
      password: data.password,
      repeatPassword: data.repeatPassword,
    });

    useEffect(() => {
      const strengthChecks = {
        hasLowerCase,
        hasNumber,
        hasSpecialChar,
        hasUpperCase,
        isValidLength,
      };

      const verifiedList = Object.values(strengthChecks).filter(value => {
        return value;
      });

      const strength =
        verifiedList.length === 5
          ? 'Strong'
          : verifiedList.length >= 2
          ? 'Medium'
          : 'Weak';

      setIsInvalid(verifiedList.length !== 5);
      setProgress(`${(verifiedList.length / 5) * 100}%`);
      setColor(strength);
    }, [data.password]);

    useEffect(() => {
      onChangePasswordHandler({
        data,
        isInvalid,
        isMatch,
      });
    }, [data, isInvalid, isMatch]);

    useEffect(() => {
      if (data.password !== '' && data.repeatPassword !== '') {
        setIsMatch(data.password === data.repeatPassword);
      }
    }, [data]);

    // Methods

    const handlePasswordChange = (passwordValue: string) => {
      setData({ ...data, password: passwordValue });
    };

    const handleRepeatPassword = (repeatPasswordValue: string) => {
      setData({ ...data, repeatPassword: repeatPasswordValue });
    };

    const getActiveColor = (type: string) => {
      if (type === 'Strong') {
        return '#8BC926';
      }
      if (type === 'Medium') {
        return '#FEBD01';
      }
      return '#FF0054';
    };

    // Renders
    const passwordVisibilityIcon = (
      <div
        className={styles.toogleBtn}
        onClick={() => {
          setHidePassword(!hidePassword);
        }}
      >
        <Icon name={hidePassword ? 'ui:eye' : 'ui:eye-off'} size="16px" />
      </div>
    );

    const progressBar = (
      <div className={styles.progressDiv}>
        <div
          className={styles.progress}
          style={{
            backgroundColor: getActiveColor(color),
            width: progress,
          }}
        />
      </div>
    );

    const passwordInput = (
      <input
        className={`${styles.password}`}
        data-testid="password"
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={hidePassword ? 'password' : 'text'}
        value={data.password}
        onChange={({ target }) => {
          handlePasswordChange(target.value);
        }}
      />
    );

    const repeatPasswordInput = (
      <input
        className={`${styles.password} ${!isMatch ? styles.invalid : ''}`}
        data-testid="repeat-password"
        placeholder={repeatPlaceholder}
        tabIndex={tabIndexConfirm}
        type={hidePassword ? 'password' : 'text'}
        value={data.repeatPassword}
        onChange={({ target }) => {
          handleRepeatPassword(target.value);
        }}
      />
    );

    const passwordValidations = (
      <Fragment>
        <p className={styles.errorInfo}>Minimum of 6 characters and:</p>

        <div>
          <ul>
            <li className={styles.listItem}>
              {hasLowerCase ? (
                <CheckMark fill="#13AD06" />
              ) : (
                <CheckMark fill="#c2c2c2" />
              )}{' '}
              <span className={`${hasLowerCase ? styles.valid : ''}`}>
                one lower case letter
              </span>
            </li>
            <li className={styles.listItem}>
              {hasUpperCase ? (
                <CheckMark fill="#13AD06" />
              ) : (
                <CheckMark fill="#c2c2c2" />
              )}{' '}
              <span className={`${hasUpperCase ? styles.valid : ''}`}>
                one uppercase letter
              </span>
            </li>
            <li className={styles.listItem}>
              {hasNumber ? (
                <CheckMark fill="#13AD06" />
              ) : (
                <CheckMark fill="#c2c2c2" />
              )}{' '}
              <span className={`${hasNumber ? styles.valid : ''}`}>
                one number
              </span>
            </li>
            <li className={styles.listItem}>
              {hasSpecialChar ? (
                <CheckMark fill="#13AD06" />
              ) : (
                <CheckMark fill="#c2c2c2" />
              )}{' '}
              <span className={`${hasSpecialChar ? styles.valid : ''}`}>
                one special character
              </span>
            </li>
          </ul>
        </div>
      </Fragment>
    );

    const error = (
      <div className={`${!isMatch ? styles.showError : styles.hideError}`}>
        {errorMessage}
      </div>
    );

    return (
      <div className={styles.container}>
        <div className={styles.labelContainer} datatest-id="asd">
          <label datatest-id="asd" htmlFor="password">
            {label}
          </label>
        </div>
        <div style={{ position: 'relative' }}>
          {passwordInput}
          {passwordVisibilityIcon}
        </div>
        {progressBar}
        {passwordValidations}
        <div className={styles.labelContainer}>
          <label htmlFor="repeatPassword">{repeatLabel}</label>
        </div>
        {repeatPasswordInput}
        {error}
      </div>
    );
  },
);

export default Password;
