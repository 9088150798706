import React, { FC, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import useSWR from 'swr';
import {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core';
import { v4 as uuidV4 } from 'uuid';
import toast from 'react-hot-toast';
import { renderEventContent } from '../../../../../../helpers/full-calendar';
import { CalendarWrapper } from '../../../../../../common';
import { interceptorFetcher } from '../../../../../../utils/helpers/interceptorFetcher';
import { CoachSessionsProps } from './coach-sessions.types';
import { CoachAvailableTimeslotCard } from '../../../../../coach/interfaces/CoachSessionTimeslotCard';
import {
  add30Minutes,
  addMinutes,
  convertTimeInMillisecondsToLocalDate,
  firstDateOfWeek,
  lastDateOfWeek,
} from '../../../../../../utils/helpers/date';
import { updateAvailableTimeslot } from '../../../../../coach/api/updateAvailableTimeslots';
import { convertEventsToTimeslotsInUtc } from '../../../../../coach/components/coaching-timeslots/coaching-timeslots-days-list/coaching-timeslots-day.helpers';

export const CoachSessions: FC<CoachSessionsProps> = props => {
  const { coach } = props;
  const [from, setFrom] = useState<string>(null);
  const [to, setTo] = useState<string>(null);
  const [events, setEvents] = useState<EventInput[]>([]);

  const handleDatesSet = ({ end, start }: DatesSetArg) => {
    setFrom(start.toISOString());
    setTo(end.toISOString());
  };

  const { data } = useSWR(
    `admin/coach/session-timeslots?coachId=${coach?.id}&from=${
      from ? new Date(from).getTime() : firstDateOfWeek().getTime()
    }&to=${to ? new Date(to).getTime() : lastDateOfWeek().getTime()}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (data) {
      setEvents(
        data?.map((session: CoachAvailableTimeslotCard) => {
          const localDate = convertTimeInMillisecondsToLocalDate(
            session.start_time,
          );

          const color = session.session_timeslot_id ? '#ec6359' : '#73be76';

          return {
            backgroundColor: color,
            borderColor: color,
            display: 'auto',
            end: addMinutes(localDate, 30),
            extendedProps: session,
            id: session.id,
            start: localDate,
            title: session.session_timeslot_id
              ? session.session_timeslot_student_name
              : 'Available',
          } as EventInput;
        }),
      );

      return;
    }

    setEvents([]);
  }, [data]);

  const saveSelection = async (updatedEvents: EventInput[]) => {
    try {
      await updateAvailableTimeslot(
        coach.id,
        from,
        to,
        convertEventsToTimeslotsInUtc(updatedEvents),
      );

      setEvents(updatedEvents => {
        return updatedEvents.map(event => {
          return {
            ...event,
            pending: false,
          };
        }) as EventInput[];
      });

      toast.success('Timeslots updated successfully');
    } catch {
      toast.error('Error updating timeslots, please try again');
    }
  };

  const handleSelect = async (arg: DateSelectArg) => {
    const startDate = new Date(arg.start);

    if (startDate < new Date()) {
      toast.error('You cannot add available timeslot in the past');
      return;
    }

    const updatedEvents = [...events];

    while (startDate < arg.end) {
      let endDate = add30Minutes(startDate.getTime());

      if (endDate > arg.end.getTime()) {
        endDate = arg.end.getTime();
      }

      const newEvent: EventInput = {
        editable: false,
        end: new Date(endDate),
        groupId: uuidV4(),
        id: uuidV4(),
        pending: true,
        start: new Date(startDate),
        title: 'Available',
      };

      updatedEvents.push(newEvent);

      // Increment the start time by the duration
      startDate.setMinutes(startDate.getMinutes() + 30);
    }

    setEvents(updatedEvents);

    await saveSelection(updatedEvents);
  };

  async function handleEventClick(arg: EventClickArg) {
    const eventIndex = events.findIndex(event => {
      return event.id === arg.event.id;
    });

    if (eventIndex === -1) {
      return;
    }

    const event = events[eventIndex];

    if (event.start < new Date()) {
      toast.error('You cannot change timeslots in the past');
      return;
    }

    if (!event.extendedProps?.student) {
      const newEvents = [...events];
      newEvents.splice(eventIndex, 1);

      setEvents(newEvents);
      await saveSelection(newEvents);
    }
  }

  return (
    <CalendarWrapper>
      <FullCalendar
        allDaySlot={false}
        businessHours={false}
        datesSet={handleDatesSet}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventDurationEditable={false}
        events={events}
        headerToolbar={{
          center: 'title',
          left: 'prev,next',
          right: 'timeGridWeek,listWeek',
        }}
        height="calc(100vh - 240px)"
        initialView="timeGridWeek"
        noEventsText="No sessions yet"
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
        scrollTime={null}
        scrollTimeReset={false}
        select={handleSelect}
        selectable
        selectOverlap
        unselectAuto={false}
        views={{
          listWeek: { buttonText: 'List', displayEventTime: true },
          timeGridWeek: {
            buttonText: 'Calendar',
            displayEventTime: false,
          },
        }}
      />
    </CalendarWrapper>
  );
};
