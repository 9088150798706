import { CSSProperties, useMemo, useState } from 'react';
import { Overlay } from './overlay';
import { OverlayPresets } from './overlay.presets';
import { UseOverlayParams } from './overlay.types';

export const useOverlay = (params: UseOverlayParams) => {
  const [open, setOpen] = useState(false);
  const [containerStyles, setContainerStyles] = useState<CSSProperties>({
    position: 'fixed',
  });

  const overlayComponent = useMemo(() => {
    return (
      <Overlay
        containerStyles={containerStyles}
        content={params.content}
        open={open}
        position={params.position || OverlayPresets.position}
        onClose={setOpen}
        onOpen={setOpen}
      />
    );
  }, [open, containerStyles]);

  const showRelativeTo = (parent: string | Element) => {
    const parentElement =
      typeof parent === 'string' ? document.querySelector(parent) : parent;

    if (parentElement) {
      const parentOffset = parentElement.getBoundingClientRect();

      setContainerStyles({
        height: parentOffset.height,
        left: parentOffset.left,
        pointerEvents: 'none',
        position: 'fixed',
        top: parentOffset.top,
        width: parentOffset.width,
      });

      setOpen(true);
    } else {
      console.log('Overlay: Parent element not found');
    }
  };

  const closeOverlay = () => {
    setOpen(false);
  };

  return { closeOverlay, overlayComponent, showRelativeTo };
};
