import useSWR from 'swr';
import axiosInstance from '../../../utils/helpers/http';
import { interceptorFetcher } from '../../../utils/helpers/interceptorFetcher';

export const useFetchArchivedStudents = (stopFetch?: boolean) => {
  const { data, error } = useSWR(
    !stopFetch ? `coaches/students/archived` : null,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    isError: error,
    isLoading: !error && !data,
    studentsData: data,
  };
};

export const fetchArchivedStudents = async () => {
  const result = await axiosInstance.get('coaches/students/archived');
  try {
    return result.data;
  } catch (err) {
    console.error(err);
  }
};
