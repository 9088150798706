import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { MenuProps } from './menu.props';
import { MenuPresets } from './menu.presets';
import { Conditional } from '../conditional';
import { Typography } from '../typography';

import styles from './menu.module.scss';

export const Menu: FC<PropsWithChildren<MenuProps>> = props => {
  const { children, className, headerText, minWidth, onClick, width } = {
    ...MenuPresets,
    ...props,
  };

  return (
    <div
      className={cx(styles.container, className)}
      style={{ minWidth, width }}
      onClick={onClick}
    >
      <Conditional condition={!!headerText}>
        <Typography
          className={styles.header}
          color="secondary"
          text={headerText}
          variant="subtitle3"
        />
      </Conditional>

      {children}
    </div>
  );
};

Menu.defaultProps = MenuPresets;
