import { Fragment, useEffect, useMemo, useState } from 'react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { useImmer } from 'use-immer';
import { DateSelectArg, EventDropArg, EventInput } from '@fullcalendar/core';
import { v4 as uuidV4 } from 'uuid';
import { addWeeks } from '@fullcalendar/core/internal';
import InterestCard from '../interest-card/interest-card';
import Modal from '../../../general/components/empty-modal/empty-modal';
import UserCarouselCard from '../user-carousel-card/user-carousel-card';
import { CoachMatch } from '../../interfaces/CoachMatch';
import { Timeslot } from '../../../general/interfaces/Timeslot';
import Avatar from '../../../general/components/avatar/avatar';
import { CalendarWrapper, Typography } from '../../../../common';
import { addToDate, firstDateOfWeek } from '../../../../utils/helpers/date';
import { findOverlapBackgroundEvent } from '../../../coach/components/coaching-timeslots/coaching-timeslots-days-list/coaching-timeslots-day.helpers';
import { useCoachTimeslots } from '../../../coach/api/useCoachTimeslots';

import generalStyles from '../general/general.module.scss';
import styles from './choose-timeslot.module.scss';

type Props = {
  coach: CoachMatch;
  handleDataChange: (data: object) => void;
};

const ChooseTimeslot = ({ coach, handleDataChange }: Props) => {
  const { onDatesSet, timeslotsData } = useCoachTimeslots({
    coachId: coach.id,
  });

  const [events, updateEvents] = useImmer<EventInput[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const selectedEvent = useMemo(() => {
    return events?.find(event => {
      return !event.groupId;
    });
  }, [events]);

  useEffect(() => {
    if (selectedEvent) {
      const timeslot: Timeslot = findOverlapBackgroundEvent(
        selectedEvent,
        timeslotsData.timeslots,
      );

      if (timeslot) {
        handleDataChange({
          ...timeslot,
          start_time: (selectedEvent.start as Date).getTime(),
        });
      }
    }
  }, [selectedEvent]);

  const nameRender = (
    <span className={generalStyles?.name}>
      {`${coach.name} ${
        coach.surname ? coach.surname?.charAt(0)?.toUpperCase() : ''
      }.`}
    </span>
  );

  const percentageRender = (
    <Fragment>
      <span className={generalStyles.percentage}>
        ({coach.match_percentage || 0}% match)
      </span>
    </Fragment>
  );

  const preferencesRender = (
    <span>
      <span>
        {coach.filteredPreferences &&
          coach.filteredPreferences.length > 0 &&
          coach.filteredPreferences.map((item, index) => {
            return (
              <span key={index}>
                {index < coach.preferences.length - 1
                  ? `${item.label}, `
                  : item.label}
              </span>
            );
          })}
      </span>
    </span>
  );

  const learnMoreRender = (
    <div
      className={generalStyles.learnMore}
      onClick={e => {
        e.stopPropagation();
        setShowModal(true);
      }}
    >
      Learn more
    </div>
  );
  const interestsRender = (
    <Fragment>
      <div className={generalStyles.interestsBar}>INTERESTED IN</div>
      <div className={generalStyles.interestsListing}>
        <Fragment>
          {coach.filteredInterests?.map(interest => {
            return (
              <InterestCard
                className="dark"
                interest={interest}
                key={interest.label}
              />
            );
          })}
        </Fragment>
      </div>
    </Fragment>
  );

  const canSelect =
    timeslotsData?.remaining_weekly_capacity > 0 &&
    timeslotsData?.remaining_new_students_weekly_capacity > 0;

  useEffect(() => {
    if (timeslotsData?.timeslots) {
      updateEvents(
        canSelect
          ? timeslotsData?.timeslots?.map(timeslot => {
              return {
                display: 'background',
                end: new Date(+timeslot.end_time),
                groupId: 'available',
                id: timeslot.id,
                start: new Date(+timeslot.start_time),
              } as EventInput;
            })
          : [],
      );
    }
  }, [timeslotsData?.timeslots, canSelect]);

  const handleSelect = async (arg: DateSelectArg) => {
    const newEvent = {
      constraint: 'available',
      durationEditable: false,
      editable: true,
      end: arg.end,
      id: uuidV4(),
      start: arg.start,
      title: 'Available',
    };

    const updatedEvents = [
      ...events.filter(event => {
        return event.groupId;
      }),
      newEvent,
    ];

    updateEvents(updatedEvents);
  };

  const selectAllow = (selectInfo: any) => {
    const { end, start } = selectInfo;

    return events.some(event => {
      return start < event.end && end > event.start;
    });
  };

  const handleEventDrop = async (arg: EventDropArg) => {
    updateEvents(draft => {
      const eventIndex = draft.findIndex(event => {
        return event.id === arg.event.id;
      });

      if (eventIndex === -1) {
        return;
      }

      draft[eventIndex].start = arg.event.start;
      draft[eventIndex].end = arg.event.end;
    });
  };

  const timeslotsRender = (
    <div
      className={styles.timeslotsContainer}
      data-testid="chooseTimeslot-container"
    >
      <Typography
        color="secondary"
        text="Tap on an available timeslot to select one."
        variant="body1"
      />

      <CalendarWrapper>
        <FullCalendar
          allDaySlot={false}
          businessHours={false}
          datesSet={onDatesSet}
          dayHeaderFormat={{ weekday: 'narrow' }}
          displayEventTime={false}
          editable
          eventDrop={handleEventDrop}
          eventDurationEditable={false}
          events={events || []}
          headerToolbar={{
            left: 'title',
            right: 'prev,next',
          }}
          initialView="timeGridWeek"
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          scrollTime={null}
          scrollTimeReset={false}
          select={handleSelect}
          selectable={canSelect}
          selectAllow={selectAllow}
          selectOverlap
          unselectAuto={false}
          validRange={{
            end: addWeeks(firstDateOfWeek(), 3),
            start: addToDate(new Date(), 36, 'hours'),
          }}
        />
      </CalendarWrapper>
    </div>
  );

  const separator = <div className={generalStyles.separator} />;

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={generalStyles.userBasicInfoContainer}>
          <Avatar image={coach.profile_img} name={coach.name} size="medium" />

          <div className={generalStyles.basicInfo}>
            {nameRender}
            {percentageRender}
            {preferencesRender}
            {learnMoreRender}
          </div>
        </div>
        {separator}
        {interestsRender}
        {timeslotsRender}
      </div>

      {showModal && (
        <Modal handleClose={handleModalClose}>
          <UserCarouselCard coach={coach} />
        </Modal>
      )}
    </Fragment>
  );
};

export default ChooseTimeslot;
